const page6Data = {
  id: '438cca85-66eb-4d82-8e8e-91b2ab66f5c3',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Answer the question below to test your knowledge. Click "Complete Module" when you are finished and choose another topic to explore.',
    },
  ],
  afterResponse: [''],
  responseType: [
    {
      id: 'e708954b-0024-44cb-815e-37e0eef0100c',
      name: 'quiz',
      class: 'end-quiz',
      guid: '65a0c3da-1bb7-4d95-892f-c36a0de65360',
      questionText: '',
      questions: [
        {
          question: 'Which is the most accurate description of a ‘small win’?',
          answers: [
            'Actions that support completion of larger goals',
            'Long-term strategic actions',
          ],
          showPrevious: true,
          showFeedback: true,
          correctAnswer: 'Actions that support completion of larger goals',
          feedback: {
            correctFeedback:
              'That’s right - small wins are incremental, everyday actions that can lead you towards a larger goal.',
            incorrectFeedback:
              'Not quite - small wins are incremental, everyday actions that can lead you towards a larger strategic goal.',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page6Data;

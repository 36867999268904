const page4Data = {
  id: 'c719d6f1-fc9b-4dfd-8c47-3b3575a1f748',
  number: '4',
  title: 'Reflect: how you connect',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'It’s important to establish strong ways of working with your team – in particular your manager – from day one to ensure you work with maximum efficiency and ease. In order to understand how to work together, first you need to understand your values – what drives you at work.',
    },
    {
      class: 'content question',
      text: 'Order the values and preferences below in order of importance to you (top of the list = most important; bottom of the list = least important)',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'Our values impact our drive and motivation. Consider what motivates you at work and how this aligns with your values.',
  ],
  responseType: [
    {
      id: '3e7afd2a-57f7-4ec9-a103-07013daf6e53',
      name: 'listSort',
      guid: '29ba4d36-4e0e-45cd-9d1d-b84d74083542',
      questionText: '',
      listItems: {
        list: [
          {
            id: '1',
            name: 'A',
            text: 'Relator – your value connecting with others and creating strong relationships',
          },
          {
            id: '2',
            name: 'B',
            text: 'Challenge – you enjoy solving difficult problems and value original ideas',
          },
          {
            id: '3',
            name: 'C',
            text: 'Security – you seek stability and certainty',
          },
          {
            id: '4',
            name: 'D',
            text: 'Expert – you value being good at something and achieving value',
          },
          {
            id: '5',
            name: 'E',
            text: 'Autonomy – you enjoy working independently with your own rules',
          },
        ],
      },
    },
    {
      id: 'a109f977-0f6a-4572-a074-d827cd67eddd',
      name: 'freeText',
      class: 'missions',
      guid: 'f881e661-ef27-4f5c-9aed-2c73c089cf1b',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: '<div><p>Now take some time to reflect on what your top values mean.</p><p>For you - how do they show up for you at work?</p><p>For your new team – can you identify what their top values may be? How could this impact your working relationship?</p></div>',
        },
      ],
    },
  ],
};

module.exports = page4Data;

export const getQuestionByUniqueId = async ( 
    Brand,
    guid,
    getAccessTokenSilently
    ) => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: `${window.apiAudience}`,
              scope: '',
            },
          });
          const completeQuestionUrl = `${window.apiBaseUrl}/ParticipantQuestionAnswer/GetByQuestionUniqueId/${guid}`;
      
          const getData = await fetch(completeQuestionUrl, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
              'Capp-Tenant-Id': Brand.TENANT_ID,
            }
          });
          const completedQuestion = await getData.json();
          if (completedQuestion.answers.length > 1) {
            return JSON.parse(
              completedQuestion.answers[completedQuestion.answers.length - 1].text
            );
          } else {
            return JSON.parse(completedQuestion.answers[0].text);
          }
        } catch (e) {
          return e;
        }
      };

      export default getQuestionByUniqueId;
const page2Data = {
  id: '1c8e3d09-d90a-4fba-90e9-6ae6095b2eb3',
  number: '2',
  title: 'Uncover: strong networks',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'When you start a new role it’s important to get to know your team, understand who does what and the different roles and responsibilities. This means you can easily identify who to go to when you need something.',
    },
    {
      class: 'content',
      text: "Your team are likely to be the first people in your network at the company - these are the people you work with on a daily basis and go to for immediate support. There are several common categories of people who are 'good to know'.",
    },
    {
      class: 'content question',
      text: 'Drag and drop the definitions to match with the role.',
    },
  ],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      id: '2',
      name: 'bucketSort',
      guid: 'e1c34c4b-11a5-4791-aafd-b0ce2daa814a',
      questionText: '',
      categories: [
        'Knowledge expert',
        'Resource controller',
        'Serial networker',
        'The buddy',
        'Technology guru',
        'Fellow new starter',
      ],
      feedback: {
        correctFeedback:
          'You’ve got it! You’ll need a range of different people in your networks with different skills and specialisms. Identifying who fills these roles early on, will help you diversify your network from the start.',
        incorrectFeedback:
          'Not quite – have another go or move on to the next section to see the answers.',
        defaultFeedback: 'Move on to the next question',
      },
      listItems: [
        {
          id: '1',
          name: 'A',
          text: 'This person has been at the organisation for a long time and holds lots of key information',
          correctAnswer: 'Knowledge expert',
        },
        {
          id: '2',
          name: 'B',
          text: 'This person delegates or manages time, resources and potentially holds accountability for finances',
          correctAnswer: 'Resource controller',
        },
        {
          id: '3',
          name: 'C',
          text: 'This person knows a wider range of people at the company and can connect you with the people you need',
          correctAnswer: 'Serial networker',
        },
        {
          id: '4',
          name: 'D',
          text: "This person is your 'safe go-to' for any questions, issues or concerns",
          correctAnswer: 'The buddy',
        },
        {
          id: '5',
          name: 'E',
          text: 'This person is an expert on legacy and current systems at the company and can support with technical issues',
          correctAnswer: 'Technology guru',
        },
        {
          id: '6',
          name: 'F',
          text: 'This person is going through a similar process to you and is a great contact for sharing challenges and experiences',
          correctAnswer: 'Fellow new starter',
        },
      ],
    },
  ],
};

module.exports = page2Data;

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
// import brandHero from "../images/brand-hero.png"
// import { useAnimation, motion } from "framer-motion"
// import { useInView } from "react-intersection-observer"
// import Hero from "../components/Hero"
// import Brand from "../components/Brand";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"

// import { useAuth0 } from "@auth0/auth0-react";


export function LogIn(props) { 
    // const variants = {
    //     visible: { opacity: 1, y: 0 },
    //     hidden: { opacity: 0, y: 20 },
    //   }
    //   const controls = useAnimation()
    //   const [ref, inView] = useInView()
    //   useEffect(() => {
    //     if (inView) {
    //       controls.start("visible")
    //     }
    //   }, [controls, inView])
    // const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

   
    return <>

      <Container className={props.class} style={{zIndex: "2"}}>
        <Row>
          <Col xs={12} lg={6} className="text-container">
          <h3>{props.welcomeTitle}</h3>
          <h1>{props.title} VEE</h1>
          <h2>{props.subtitle}</h2>
          <p dangerouslySetInnerHTML={{ __html: props.text}}></p>
            <div className="button-container">
            {/* {isAuthenticated && user ?  
            <Button onClick={window.location = "/dashboard"} className="auth-button">Return to dashboard</Button> : 
            <>
            <Button onClick={()=>loginWithRedirect({backgroundImage: "HSBCVEE/hsbc-login.png", logo: "HSBCVEE/brand-logo.png", buttonColor: "DB0D16", buttonHoverColor: "ef1922", buttonTextColor: "ffffff", headerColor: "DB0D16"})} className="auth-button">Login</Button>
            <Button varient="secondary" onClick={()=>loginWithRedirect({accountConfirmationType:"open-signup", backgroundImage: "HSBCVEE/hsbc-login.png", logo: "HSBCVEE/brand-logo.png", buttonColor: "DB0D16", buttonHoverColor: "ef1922", buttonTextColor: "ffffff", headerColor: "DB0D16"})} className="auth-button">Sign up</Button>
            </>} */}
          
            </div>
          </Col>
          <Col xs={12} lg={6} className="jumbo-right-image">
          <img src={props.image} alt={props.alt} /> 

          </Col>
        </Row>
      </Container>
        </>;
    };

export default LogIn
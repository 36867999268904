const page4Data = {
  id: '62839d96-ec92-4196-ab79-fd357a3fb5c1',
  number: '4',
  title: 'Reflect: the ‘big rocks’ of life',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'One way to refocus in times of overwhelm or burnout is to use the Big Rocks of Life lesson from Stephen Covey, ‘First things first’.',
    },
    {
      class: 'content',
      text: 'He says life is made up of metaphorical rocks (truly important, overarching things), pebbles (important but not critical) and sand (small, less important). If we focus too much on one, we lose our balance and can become overwhelmed. ',
    },

    {
      class: 'content',
      text: 'Reflect on the priorities in your life from the options below and balance these aspects in the buckets provided.',
    },

    {
      class: 'content info',
      text: 'You can only add three items to each category – you will need to prioritise and make some tricky decisions about what is most important to you right now. This is not an exhaustive list, so as you go reflect on what else you would add to the different categories if you could.',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'Make sure your habit is easy to fit into your day and you feel motivated to do it. Don’t be tempted to overcommit otherwise it could have the opposite effect on your wellbeing!',
  ],

  responseType: [
    {
      id: '2',
      name: 'bucketSort',
      guid: '13d62a6e-77de-4730-90aa-be404f66094a',
      questionText:
        'Only 3 items allowed per bucket category, it doesn’t matter which ones!',
      categories: [
        'Rocks (truly important, overarching things)',
        'Pebbles (important but not critical)',
        'Sand (small, less important)',
      ],
      feedback: {
        correctFeedback: '',
        incorrectFeedback: '',
        defaultFeedback:
          'How you respond is unique to your situation and priorities at the time. Over time, your ‘rocks’ will change and shift, but the message is still the same – we can’t have too many rocks and need to prioritise our time to ensure balanced wellbeing.',
      },
      listItems: [
        {
          id: '1',
          name: 'A',
          text: 'Family',
        },
        {
          id: '2',
          name: 'B',
          text: 'Friends',
        },
        {
          id: '3',
          name: 'C',
          text: 'Work',
        },
        {
          id: '4',
          name: 'D',
          text: 'Holidays',
        },
        {
          id: '5',
          name: 'E',
          text: 'Socialising',
        },
        {
          id: '6',
          name: 'F',
          text: 'Hobbies',
        },  {
          id: '7',
          name: 'G',
          text: 'Music',
        },
      ],
    },
    {
      type: 'content',
      text: 'Learning to manage your wellbeing priorities and build healthy habits to support this, will not only support you in your personal life but your professional progress as well. ',
    },

    {
      id: '8d270865-3a4b-4beb-b5d9-985ae5239b4c',
      name: 'freeText',
      class: 'missions',
      guid: '998824fc-4101-4954-b841-cb2a5c4fbf36',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: '<div><strong>What one habit could you build in the next 30 days to support your wellbeing?</strong> For example - mini pauses during the day, start your day with self-development, take the stairs instead of the lift, schedule time for social activities, coffee catch ups, breaks throughout the day and to stay at your best.</div>',
        },
      ],
    },
  ],
};

module.exports = page4Data;

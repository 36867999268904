import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import {motion} from "framer-motion"
// import { useAnimation, motion } from "framer-motion"
// import { useInView } from "react-intersection-observer"
// import Brand from "../components/Brand"

import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"

export function JumboTwoColsRight(props) { 
  // const variants = {
  //   visible: { opacity: 1, y: 0 },
  //   hidden: { opacity: 0, y: 20 },
  // }
  // const controls = useAnimation()
  // const [ref, inView] = useInView()
  // useEffect(() => {
  //   if (inView) {
  //     controls.start("visible")
  //   }
  // }, [controls, inView])
  return (
    <>
    <a id={props.name} />
      <Container className={props.class} id={props.id}>
        <Row>
          <Col xs={12} lg={7} className="jumbo-left-image">
           <img src={props.image} alt={props.alt}/>
          </Col>
          <Col xs={12} lg={5} className="text-container">
            <a name={props.name}></a>
            <div className="number-title">
              <div className={props.numberCheck}>{props.number}</div>
              <h2>
                {props.title}
              </h2>
            </div>
            <p>
              {props.text}
            </p>
            <div>
            <motion.button
             className="btn btn-primary"
            variant="primary"
             whileHover={{ scale: 1.05 }}
             whileTap={{ scale: 0.9 }}
              onClick={() => (window.location.href = props.link)} >
                {props.buttonText}
                </motion.button>
             
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default JumboTwoColsRight

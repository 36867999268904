const module1Data = require('./modules/1/module1');
const module2Data = require('./modules/2/module2');
const module3Data = require('./modules/3/module3');
const module4Data = require('./modules/4/module4');
const module5Data = require('./modules/5/module5');
const phase2Data = {
    id: 2,
    number: "2",
    name: "Develop",
    image: "https://apifiles.cappassessments.com/90DTK/phase2.png",
    description: "In this phase you will develop your brand, develop strong goals and develop your broader connections.",
    expandedDescription: {
        1: "Welcome to phase 2 of the 90-day toolkit ‘Develop’. In this phase you will:",
        2: ["Develop your brand", "Develop strong goals", "Develop your broader connections"],
        3: "When you’re ready, select a topic to get started."
    },
    outcomeType: "freeText",
    modules: [module1Data,module2Data,module3Data,module4Data, module5Data]
};

module.exports = phase2Data;
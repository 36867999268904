const item1Data = require('./items/item1');
const module4Data = {
    "id": 4,
    "number": "4",
    "name": "Develop your network map",
    "description": "In this section you will start to develop your network quality and composition, uncovering ways to fill any gaps and diversify your network. ",
    "time": "",
    items: [item1Data]
};

module.exports = module4Data;

import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.div`
  color: red;
`;

export function Title({ name }) {
  return (
    <StyledTitle>
      <h1>This is my shared title: {name}</h1>
    </StyledTitle>
  );
}
export default Title;

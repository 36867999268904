const page1Data = {
  id: '2c0e6f05-f783-44de-8692-eb1d3b70717f',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: 'fedd9500-4aec-4a54-82d8-6cfacf6b68de',
      name: 'image',
      imageUrl: 'https://apifiles.cappassessments.com/90DTK/phase2-module2.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will develop strong goals to help you maintain a continuous learning mindset during the first 90-days and beyond. ',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [
    {
      type: 'content',
      text: 'Click "Next" to get started.',
    },
  ],
};

module.exports = page1Data;

import React, { useState, useEffect } from 'react';  // <-- Added hooks
import { useDrop } from 'react-dnd';
import DraggableItem, { ITEM, BUCKET_ITEM } from './draggableItem';
import { motion } from 'framer-motion';
import { Col } from "react-bootstrap"

const Bucket = ({ bucket, bucketId, category, moveItemToBucket, setBuckets }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [ITEM, BUCKET_ITEM],
    drop: (item) => {
        moveItemToBucket(item.id, item.origin, bucketId);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleMoveItem = (dragItemId, hoverItemId) => {
    const dragIndex = bucket.items.findIndex(item => item.id === dragItemId);
    const hoverIndex = bucket.items.findIndex(item => item.id === hoverItemId);

    const newBucketItems = [...bucket.items];
    const [removed] = newBucketItems.splice(dragIndex, 1);
    newBucketItems.splice(hoverIndex, 0, removed);

    setBuckets((prevBuckets) => prevBuckets.map(b => {
      if (b.id === bucketId) {
        return { ...b, items: newBucketItems };
      }
      return b;
    }));
  };

  const baseHeight = 150;
  const [bucketHeight, setBucketHeight] = useState(baseHeight); 

  useEffect(() => {
    const additionalHeightPerItem = 80;
    const hoverAdditionalHeight = 80 + (bucket.items.length * additionalHeightPerItem);
    
    if (isOver) {
      setBucketHeight(baseHeight + hoverAdditionalHeight);
    } else {
      setBucketHeight(baseHeight + bucket.items.length * additionalHeightPerItem);
    }
}, [isOver, bucket.items.length]);


  const itemSpacing = 5; 

  return (
    <Col xs={12} lg={4} style={{ padding: "0", marginBottom: '10px' }}>
      <motion.div 
        ref={drop}
        style={{  backgroundColor: isOver ? "#ddd" : "#fff", paddingBottom: '20px', transformOrigin: 'unset' }}
        initial={{ height: baseHeight }}
        animate={{ height: bucketHeight }}  // Updated to use bucketHeight
        transition={{ duration: 0.2 }}
        className='bucket-sort-bucket'
      >
        <h3>{category}</h3>
        <motion.div style={{ transformOrigin: 'unset' }}>
        {bucket.items.map((item) => (
          <div key={item.id} style={{ marginBottom: itemSpacing, transformOrigin: 'unset' }}>
            <DraggableItem item={item} origin={bucketId}  onMoveItem={handleMoveItem}  />
          </div>
        ))}
        </motion.div>
      </motion.div>
    </Col>
  );
};

export default Bucket;

import React, { useEffect, useState } from "react";
import {Button} from "react-bootstrap"
import { useAuth0 } from "@auth0/auth0-react";
import {motion} from "framer-motion"
import { getUserMetadata } from "../auth0/getUserMetadata";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';



export function Report(props) {

    const styles = StyleSheet.create({
        page: {
          flexDirection: 'row',
          backgroundColor: '#E4E4E4'
        },
        section: {
          margin: 10,
          padding: 10,
          flexGrow: 1
        }
      });

    const {
        isAuthenticated,
        loginWithRedirect,
        logout,
        user,
        getAccessTokenSilently,
        } = useAuth0();
        const [userMetadata, setUserMetadata] = useState(null);
        useEffect(() => {
        getUserMetadata(user, getAccessTokenSilently, setUserMetadata);
        }, [getAccessTokenSilently, user]);

    return (
        <>
        <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
            <Text>{props.fname}</Text>
            </View>
            <View style={styles.section}>
            <Text>{props.lname}</Text>
            <Text>{props.text}</Text>
            </View>
        </Page>
        </Document>
        </>
    );
  }
  
  export default Report;
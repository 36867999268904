const module1Data = require('./modules/1/module1');
const module2Data = require('./modules/2/module2');
const module3Data = require('./modules/3/module3');
const module4Data = require('./modules/4/module4');
const module5Data = require('./modules/5/module5');
const phase3Data = {
    id: 3,
    number: "3",
    name: "Stretch",
    image: "https://apifiles.cappassessments.com/90DTK/phase3.png",
    description: "In this phase, you’ll focus on the future and uncover tactics to stretch and succeed in your role long-term.",
    expandedDescription: {
        1: "Welcome to phase 3 of the 90-day toolkit ‘Stretch’. In this phase you will:",
        2: ["Explore ways to stretch in your role through feedback", "Understand wellbeing and resilience", "Find stretch opportunities to help continue your development"],
        3: "Now select a topic to get started."
    },
    outcomeType: "freeText",
    modules: [module1Data,module2Data,module3Data,module4Data,module5Data]
};

module.exports = phase3Data;
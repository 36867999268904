const page6Data = {
  id: '518ef7bf-e887-4afe-98ba-04342bc992b8',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Answer the question below to test your knowledge. Click Complete Module when you are finished and choose another topic to explore.',
    },
    {
      class: 'content question',
      text: 'Why are first impressions so critical at work?',
    },
  ],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      id: '7bb0e20d-fa82-41da-987a-db1295710584',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: '647accee-eecd-4426-a26f-9afaa07f4305',
      questionText: '',
      questions: [
        {
          questions: {
            1: 'Select all that apply',
          },
          responses: {
            1: [
              'First impressions stick',
              'Sets us up for success, long term',
              'They aren’t that important',
            ],
          },
          incorrectAnswers: ['They aren’t that important'],
          feedback: {
            correctFeedback:
              'Nice one! first impressions are really important – they stick with us and can set us up for long term success',
            incorrectFeedback:
              'Not quite – first impressions are really important – they stick with us and can set us up for long term success',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page6Data;

const page6Data = {
  id: 'c576efdf-ae04-43b5-9f9e-d4b9f85f3408',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Match the ways we can push our comfort zone to the definition.',
    },
  ],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      id: '9',
      name: 'bucketSort',
      guid: 'ec0e0dad-f3f3-4bec-9cf8-d111d3e43260',
      questionText: '',
      categories: ['PEOPLE', 'PROMOTION', 'POSSIBILITIES'],
      feedback: {
        correctFeedback:
          'Correct! There are lots of different ways we can stretch our comfort zone, these are just three to start exploring further.',
        incorrectFeedback:
          'Not quite, there are lots of different ways we can stretch our comfort zone, the three we covered in this section were 1/ PEOPLE – how you engage with others, 2/ PROMOTION – how you show up at work, 3/ POSSIBILITIES – how you gain experience. If you want a recap, revisit page 2 and 3 to find out more.',
        defaultFeedback: 'Move on to the next question',
      },
      listItems: [
        {
          id: '1',
          name: 'A',
          text: 'How you engage with others',
          correctAnswer: 'PEOPLE',
        },
        {
          id: '2',
          name: 'B',
          text: 'How you show up at work',
          correctAnswer: 'PROMOTION',
        },
        {
          id: '3',
          name: 'C',
          text: 'How you gain experience',
          correctAnswer: 'POSSIBILITIES',
        },
      ],
    },
  ],
};

module.exports = page6Data;

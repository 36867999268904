const page1Data = {
  id: '42a93170-901d-4d6a-981c-1040430c5a61',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: '11055f66-175c-41a1-bbc5-80d30d6e10b6',
      name: 'image',
      imageUrl: 'https://apifiles.cappassessments.com/90DTK/phase3-module3-2.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will start to explore the different types of wellbeing, how to balance yours to allow you to stretch at work.',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [{ type: 'content', text: 'Click "Next" to get started.' }],
};

module.exports = page1Data;

import React, {useEffect} from 'react';

import { BsThreeDotsVertical, BsFillPlayFill } from 'react-icons/bs';
import VideoRecorder from 'react-video-recorder';
import { setApiStepComplete } from '@capp.micro/shared/api';

export const VideoCapture = ({ settings, setUserMetadata, guid, brand, user, getAccessTokenSilently }) => {

  useEffect(() => {setApiStepComplete(`${guid} - Complete`, brand, user, getAccessTokenSilently, setUserMetadata)}, [])
  return (
    <div className="video-capture-session">
      <div className="video-capture-header">
        <div>{settings.videoHeader}</div>
      </div>
      <VideoRecorder
        isFlipped={false}
        replayVideoAutoplayAndLoopOff
        showReplayControls
        countdownTime={settings.countdownTime}
        timeLimit={settings.timeLimit}
        onRecordingComplete={(_videoBlob) => {
         
        }}
      />
      <div className="video-capture-footer">
        <div>
          {settings.videoFooter}
          <p />
          Once you have finished recording, click the <BsFillPlayFill /> button
          to replay your video. <p /> If you would like to re-record your video,
          click "Use Another Video", otherwise click the <BsThreeDotsVertical />{' '}
          button to download your video in .webm format.
        </div>
      </div>
    </div>
  );
};

export default { VideoCapture };

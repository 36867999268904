const page3Data = {
  id: '2a9a4940-ea22-46d4-895a-d18bd38b0f36',
  number: '3',
  title: 'Understand: the power of small wins',
  resourceType: [],
  content: [
    { class: 'content bold', text: 'What are small wins?' },
    {
      class: 'content',
      text: 'Research conducted by Harvard Business School showed that people who tracked their small, everyday achievements had higher motivation than those that didn’t.',
    },
    {
      class: 'content',
      text: 'Celebrating the completion of small, incremental ‘wins’ has many benefits including – boosting confidence, motivation and supporting the completion of larger goals.',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/celebrate-firsts.png',
        alt: 'Celebrate firsts',
      },
    },

    {
      class: 'content',
      text: 'These small wins will most likely be tasks completed outside your comfort zone, in your learning zone.',
    },
    {
      class: 'content',
      text: 'When was the last time you tried something new and out of your comfort zone at work – what was it and how did it feel being successful?',
    },
  ],

  afterResponse: [''],
  action: [
    'Make this a habit by scheduling a reminder to reflect on your small wins at the end of each day, week or month.',
  ],
  responseType: [
    {
      id: '58a235a4-03f4-4dbe-a488-f0c74494cab9',
      name: 'freeText',
      class: 'missions',
      guid: '14e47c1f-d1dc-45f9-aa7c-5605afcadfeb',
      questionText:
        '',
      questions: [
        {
          1: 'When was the last time you tried something new and out of your comfort zone at work – what was it and how did it feel being successful?',
        },
      ],
    },
  ],
};

module.exports = page3Data;

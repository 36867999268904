const headingClass = 'video-capture-heading';
const quoteClass = 'video-capture-quote';
const page3Data = {
  id: 'a8dd51b5-04f1-4a34-9413-721668923eb3',
  number: '3',
  title: 'Understand: how we introduce ourselves',
  content: [
    {
      class: 'content',
      text: 'An introduction should be short and succinct, giving your team an insight into who you are, without giving them your life story. This means choosing key information you want to share.',
    },
    { class: headingClass, text: 'Introduce yourself ' },
    {
      class: quoteClass,
      text: '"Hi, my name is Nadiya, and I have joined the team as a Graduate Programme Coordinator to support with customer engagement and project delivery. I’m very curious and love creative writing."',
    },
    {
      class: headingClass,
      text: 'Short summary of your background and what you have done',
    },
    {
      class: quoteClass,
      text: '"I’ve recently completed my degree in Psychology and have experience in communications and customer relations."',
    },
    {
      class: headingClass,
      text: 'Share a future goal, aspiration or interest',
    },
    {
      class: quoteClass,
      text: '"I am passionate about behaviour change and looking forward to finding ways to apply this in my work."',
    },
    { class: headingClass, text: 'Share a call to action' },
    {
      class: quoteClass,
      text: '"I am going to start by understanding how we currently engage with our customers and then consider how we could maximise this further."',
    },
  ],
  afterResponse: [''],
  action: [
    'You could also send this video to a ‘safe’ contact such as a family member, peer or mentor for feedback.',
  ],
  resourceType: [],
  responseType: [
    {
      id: '566c28a4-742b-4e97-834f-455b0c6df265',
      name: 'freeText',
      class: 'missions',
      guid: 'fff7d6ae-3908-45e3-8328-8bbe22b1a125',
      questionText: '',
      questions: [
        {
          1: 'Now have a go at crafting your own introduction using the structure as a guide:',
        },
      ],
    },
    {
type: "content",
      class: "free-text-question",
      text: 'Now try delivering your intro to the team. We recommend you record yourself doing it - either on your phone or laptop - and watch it back.',
    },
  ],
};

module.exports = page3Data;

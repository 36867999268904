import React, {useEffect} from 'react';
import { setApiStepComplete } from '@capp.micro/shared/api';

export const ImageContent = ({ id, title, imageUrl, text, setUserMetadata, brand, user, getAccessTokenSilently }) => {
  useEffect(() => {setApiStepComplete(`${id} - Complete`, brand, user, getAccessTokenSilently, setUserMetadata)}, [])
  
  return (
    <div className='image-content'>
      <img src={imageUrl} alt={title} />
      <p>{text}</p>
    </div>
  );
}

export default ImageContent;
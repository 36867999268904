import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import MultipleChoiceOption from './multipleChoiceOption';
import { setQuestionComplete } from '../../api/setQuestionComplete';
import {
  getQuestionByUniqueId,
  setApiStepComplete,
} from '@capp.micro/shared/api';
import Feedback from '../feedback/feedback';

export const MultipleChoice = ({
  questions,
  setUserMetadata,
  user,
  questionText,
  brand,
  guid,
  getAccessTokenSilently,
}) => {
  const [clicked, setClicked] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [responses, setResponses] = useState('');
  const [answers, setAnswers] = useState([]);
  const [feedbackToShow, setFeedbackToShow] = useState();

  useEffect(() => {
    if (answers.length > 0) {
      const answersWithFeedback = answers;
      answersWithFeedback[0].feedback = feedbackToShow;
      setQuestionComplete(
        questionText,
        brand,
        guid,
        getAccessTokenSilently,
        answersWithFeedback
      );
      setApiStepComplete(
        `${guid} - Complete`,
        brand,
        user,
        getAccessTokenSilently,
        setUserMetadata
      );
    }
  }, [user, questionText, brand, guid, getAccessTokenSilently, answers]);

  const handleClick = (e) => {
    const prevAnswer = responses;
    setResponses(e.target.id);
    if (!clicked.includes(e.target.id)) {
      setClicked([...clicked, e.target.innerText]);
    } else if (questions[0].responses[currentQuestion].includes(prevAnswer)) {
      setClicked((prevClicked) =>
        prevClicked.filter((response) => response !== e.target.innerText)
      );
    }
  };

  const handleNavigation = (e) => {
    if (e.target.innerText === 'Next') {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    } else if (e.target.innerText === 'Back' && currentQuestion > 1) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    } else if (e.target.innerText === 'Submit') {
      handleSubmit();
    }
  };

  const checkAnswers = () => {
    if (questions[0].feedback.individualFeedback) {
      const index = questions[0].responses[currentQuestion].indexOf(clicked[0]);
      setFeedbackToShow(
        questions[0].feedback.individualFeedback[currentQuestion][index]
      );
    } else if (questions[0].incorrectAnswers?.[0]) {
      const correctClicked = clicked.filter(
        (item) => questions[0].incorrectAnswers.includes(item)
      );
      if (correctClicked.length > 0) {
        setFeedbackToShow(questions[0].feedback.incorrectFeedback);
      } else {
        setFeedbackToShow(questions[0].feedback.correctFeedback);
      }
    } else {
      setFeedbackToShow(questions[0].feedback.defaultFeedback);
    }
  };

  const handleSubmit = () => {
    checkAnswers();
    const newArray = Object.values(questions[0].questions).map((question) => ({
      question,
      answers: [],
    }));
    Object.values(questions[0].responses).forEach((array, index) => {
      clicked.forEach((answer) => {
        if (array.includes(answer)) {
          newArray[index].answers.push(answer);
        }
      });
    });
    setAnswers(newArray);
    setFinished(true);
  };

  const [finished, setFinished] = useState(false);
  const [previouslySavedResponse, setPreviouslySavedResponse] = useState(null);

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const response = await getQuestionByUniqueId(
          brand,
          guid,
          getAccessTokenSilently
        );
        if (response[0]) {
          return setPreviouslySavedResponse(response);
        } else {
          return setPreviouslySavedResponse(null);
        }
      } catch (error) {
        return 'Error fetching question: ' + error;
      }
    }
    fetchQuestion();
  }, []);

  const component = previouslySavedResponse ? (
    <div className="feedback-main">
      <div className="feedback-title">You responded:</div>
      <Row>
        {previouslySavedResponse[0].answers.map((answer) => (
          <Col key={answer}>
            <div>
              <div>{answer}</div>
            </div>
          </Col>
        ))}
      </Row>
      <div className="feedback-title">Feedback:</div>
      {previouslySavedResponse[0].feedback}
    </div>
  ) : (
    <div className="quiz-session">
      <div className="multiple-choice-header">
        <div className="multiple-choice-questions">
          Question {currentQuestion.toString()} of{' '}
          {Object.keys(questions[0].questions).length}{' '}
        </div>
        <div className="multiple-choice-individual">
          {questions[0].questions[currentQuestion]}
        </div>
      </div>

      <div className="multiple-choice-answers">
        <div className="multiple-choice-answers-sub-one">
          {Object.values(questions[0].responses[currentQuestion]).map(
            (value) => (
              <MultipleChoiceOption
                key={value} // Assuming 'value' is unique
                value={value}
                onClick={handleClick}
                clicked={clicked}
              />
            )
          )}
        </div>
      </div>
      <div className="multiple-choice-footer">
        <div className="multiple-choice-nav">
          <Button onClick={handleNavigation}>Back</Button>
          <Button onClick={handleNavigation}>
            {currentQuestion === Object.keys(questions[0].questions).length
              ? 'Submit'
              : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <>{finished ? <Feedback feedbackText={feedbackToShow} /> : component}</>
  );
};

export default MultipleChoice;

const page1Data = {
  id: 'cbfa6efe-0bee-47fc-a7d3-117a37cfac2d',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: '3e3b1848-3f93-4bca-81b4-f4e2faf5bfa4',
      name: 'image',
      imageUrl: 'https://apifiles.cappassessments.com/90DTK/phase2-module3.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will start to develop your personal brand and identify your ‘USP’, to have a positive impact on those you meet.',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [
    {
      type: 'content',
      text: 'Click "Next" to get started.',
    },
  ],
};

module.exports = page1Data;

import React, {useState} from 'react';
import ReactCardFlip from 'react-card-flip';
import {setQuestionComplete} from '../../api/setQuestionComplete';
import {setApiStepComplete} from '../../api/setApiStepComplete'


export const FlipCard = ({cardText, setUserMetadata, user, questionText, brand, guid, getAccessTokenSilently}) => {
    const [hasBeenViewed, setHasBeenViewed] = useState(0);
    const [flipped, setFlipped] = useState(false);
    const answers = cardText;
    answers.viewed = true;

    const handleClick = (e) => {
        e.preventDefault();
        if (flipped === true) {
            setFlipped(false);
        } else {
            setFlipped(true);
            if (hasBeenViewed === 0) {
                setQuestionComplete(questionText, brand, guid, getAccessTokenSilently, answers);
                setApiStepComplete(`${guid} - Complete`, brand, user, getAccessTokenSilently, setUserMetadata)
                setHasBeenViewed(prevValue => prevValue + 1)
        }
    }
}

    

    function FrontCard({text, onClick}) {
        return (
            
                <div className='flipcard-main' onClick={onClick}>
                    <h2>{text}</h2>
                    <span>Flip Card</span>
                </div>
                
            
        )
    }

    function BackCard({text, onClick, cardList}) {
        return (   
                <div className='flipcard-main' onClick={onClick}>
                    <div className='backcard-text'>
                        <p>{text}</p>
                        {cardList ? (<ul>{cardList.map(item => (<li key={item}>{item}</li>))}</ul>) : ('')}
                    </div>

                </div>

        )
    }

return (
    <ReactCardFlip isFlipped={flipped} flipDirection="horizontal" flipSpeedBackToFront={0.9} flipSpeedFrontToBack={0.9}>
        <FrontCard text={cardText.frontText} onClick={handleClick}>
        </FrontCard>
        <BackCard text={cardText.backText} circle={cardText.circle} img={cardText.image} onClick={handleClick} cardList={cardText.cardList}>
        </BackCard>
    </ReactCardFlip>
)
}

export default FlipCard;
const page1Data = {
  id: '2a01aeb6-3999-4cbe-843d-2b87191a619d',
  number: '1',
  title: 'Overview',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'In this section you will start to develop your network quality and composition, uncovering ways to fill any gaps and diversify your network.  ',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [
    {
      type: 'content',
      text: 'Click "Next" to get started.',
    },
  ],
};

module.exports = page1Data;

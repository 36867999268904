const page3Data = {
  id: 'a285d9c8-76b2-4efa-868f-ae7786a2c3b4',
  number: '3',
  title: 'Understand: strong networks',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Everyone has a network, but what do strong networks look like?',
    },
    {
      class: 'content bold',
      text: '1. They have an optimal number of connections',
    },
    {
      class: 'content',
      text: 'Research by British anthropologist Robin Dunbar shows there is an ideal size and make up. Explore the image below to find out more.',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/optimal-network.png',
        alt: 'Optimal network',
      },
    },

    {
      class: 'content bold',
      text: '2. They have a varied and diverse composition ',
    },
    {
      class: 'content',
      text: 'We are generally drawn to people who are ‘like us’. To create a strong network, we need to break this trend and find ways to meet a wider variety of people – a range of perspectives, backgrounds, ages, levels, knowledge, skills or strengths. ',
    },
    {
      class: 'content',
      text: 'This diversity is particularly important for innovations, ideas, new discoveries and cross-industry collaboration, as Stephen Johnson describes in his TED talk, ‘where good ideas come from’. ',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'Actively look for new events, information and experiences. Remember, a lot of what we see online is tailored to what we like and who we connect with – this can negatively impact our perspectives and opinions, unless we break out of our social ‘bubble’.',
  ],
  responseType: [
    {
      id: '68e29da5-84b4-4b76-af95-db2399aa10e1',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: 'fc1fa9f8-503b-4ad1-9d5c-c51b3c607b04',
      questionText: '',
      questions: [
        {
          questions: {
            1: 'Look at the list below and select all of those you currently have in your network and reflect on the ones you currently don’t.',
          },
          responses: {
            1: [
              'Someone who has different knowledge or skills',
              'Someone from a different business area',
              'Someone with a different background to you',
              'Someone from a different generation',
              'Someone from a different hierarchical level',
              'Someone with different strengths',
              'Someone with similar strengths and weaknesses',
              'Someone with a similar role',
            ],
          },
          incorrectAnswers: [],
          feedback: {
            correctFeedback: '',
            incorrectFeedback: '',
            defaultFeedback:
              'Great! A diverse network is a strong one, the next exercise will get you thinking about the gaps in your network.',
          },
        },
      ],
    },
  ],
};

module.exports = page3Data;

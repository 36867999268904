import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserMetadata } from '../auth0/getUserMetadata';
import { getApiUserMetadata } from '../api/getApiUserMetadata';
export const AuthNav = (props) => {
  const { isAuthenticated, logout, user, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  useEffect(() => {
    if (props.useApiEndpoints) {
      getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
    } else {
      getUserMetadata(user, getAccessTokenSilently, setUserMetadata);
    }
  }, [getAccessTokenSilently, user, props]);

  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';

  if (isAuthenticated) {
    return (
      <>
        <span className="user-name">
          {isAuthenticated ? fname + ' ' + lname : ''}
        </span>
        <Button
          variant="secondary"
          onClick={() => logout({ returnTo: window.location.origin })}
          className="auth-button btn btn-primary"
        >
          Logout
        </Button>
      </>
    );
  } else {
    return (
      <>
        {/* <Button
            variant="secondary"
            onClick={signup} className="auth-button">Sign up</Button> */}
        {/*            
             <motion.button onClick={() => loginWithRedirect({
                   backgroundImage: Brand.SIGN_UP_BG, logo: Brand.SIGN_UP_LOGO, buttonColor: Brand.SIGN_UP_BUTTON_COLOR, buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER, buttonTextColor: Brand.BUTTON_TEXT_COLOR, headerColor: Brand.SIGN_UP_HEADER_COLOR, welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE, welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO, welcomeTwo: Brand.WELCOME_TEXT, appName: Brand.LOG_IN_TITLE})} className="auth-button btn btn-secondary">Login</motion.button> */}
      </>
    );
  }
};

export default AuthNav;

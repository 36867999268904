const page4Data = {
  id: '589ca2f8-6785-4304-8d9c-b08744ff0c6b',
  number: '4',
  title: 'Reflect: your brand or USP',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'To intentionally start to craft your own brand, think about your ‘USP’ (unique selling points) – the things that make you, you. These are a set of traits, attributes or skills that make us, us.',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/USPs.png',
        alt: 'USPs',
      },
    },

    {
      class: 'content info',
      text: 'Consider, what would you like people to be saying about you this time next year?',
    },
    {
      class: 'content question',
      text: '',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'When thinking about what you want to be known for, it’s ok to be aspirational, but practical. This early in your career key attributes or traits like ‘strategic thinker’ or ‘thought leader’ are great, aspirational goals to have, but ‘curious’, ‘proactive’ or ‘supportive’ may be more practical and valuable as you are learning and developing in your role. ',
  ],

  responseType: [
    {
      id: '234235',
      name: 'freeText',
      class: 'missions',
      guid: 'eb47a300-183a-4642-9e36-ee122af2f6cd',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: 'Choose three descriptive words, attributes or strengths you would like to be known for at work. Then identify three actions you could take to start to build this brand.',
        },
      ],
    },
  ],
};

module.exports = page4Data;

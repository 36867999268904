const page6Data = {
  id: 'c9e45a91-5a2e-4b5f-93af-f2e81b740799',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Answer the question below to test your knowledge. Click complete module when you are finished and choose another topic to explore.',
    },
  ],
  afterResponse: [''],
  responseType: [
    {
      id: '4186c44a-1a4d-4d0c-ab41-056c5a395774',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: 'f77fa32d-6e33-4c58-aae7-7b2527d809e3',
      questionText: '',
      questions: [
        {
          questions: {
            1: 'How often should you be receiving feedback from others?',
          },
          responses: {
            1: ['All the time', 'After a project has finished', 'Once a year'],
          },
          incorrectAnswers: ['After a project has finished', 'Once a year'],
          feedback: {
            correctFeedback:
              'That’s right! It’s good practice to ask for feedback continuously, from a variety of different people and across different projects, to ensure you gather a range of perspectives.',
            incorrectFeedback:
              'Not quite - best practice is to continuously gather feedback, so you can make in the moment changes to support your development. For a recap, revisit page 2 of this section.',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page6Data;

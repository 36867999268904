const contentBold = 'content bold';
const page2Data = {
  id: 'cfa31bc3-d126-4bf9-a0df-b3c3eb7649ab',
  number: '2',
  title: 'Uncover: ways to stretch and grow',
  content: [
    {
      class: 'content',
      text: 'To be successful in any role we need to continually learn and look for new stretch experiences. Once you have discovered what you need to know about the business and your role, your focus should shift to gathering new experiences that stretch your comfort zone.',
    },
    {
      class: 'content',
      text: 'Here is a reminder of the comfort, learning and danger zones…',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/comfort-zone.png',
        alt: 'Comfort Zone',
      },
    },

    { class: contentBold, text: 'The comfort zone' },

    { class: 'content', text: 'Familiar stations that require less effort.' },

    { class: contentBold, text: 'The learning zone' },

    {
      class: 'content',
      text: 'New experiences and experimentation that stretch your abilities in a positive way.',
    },

    { class: contentBold, text: 'The danger zone' },

    {
      class: 'content',
      text: 'Where the stretch goes too far and exceeds our ability at this particular time, leading to stress and overwhelm.',
    },
  ],
  afterResponse: [''],
  action: [
    'Look for new opportunities  - remember the small wins concept, they don’t have to be big, just different.',
  ],
  resourceType: [],
  responseType: [
    {
      id: '3',
      name: 'sliderQuestion',
      class: 'slider-container',
      guid: '9720c56e-24c7-48d2-9f30-d5cc08410fe1',
      questionText: '',
      questions: [
        {
          questions: [
            'How successful do you think you have been at finding new opportunities in the last 90-days – on a scale of 0 (not at all) to 10 (extremely successful).',
          ],
          categories: {},
          max: 10,
          min: 0,
          startingValue: 5,
          increments: 1,
          showPrevious: true,
          showFeedback: true,
          feedback: {
            correctFeedback: '',
            incorrectFeedback: '',
            defaultFeedback:
              'However you answered, there are lots of ways we can push our comfort zone and grasp new opportunities and experiences.',
          },
        },
      ],
    },
  ],
};

module.exports = page2Data;

import React, {useEffect} from 'react';
import { setApiStepComplete } from '@capp.micro/shared/api';

export const TextContent = ({text, id, setUserMetadata, brand, user, getAccessTokenSilently}) => {

  useEffect(() => {setApiStepComplete(`${id} - Complete`, brand, user, getAccessTokenSilently, setUserMetadata)}, [])

  return (
    <div className='page-title-text'>
      <p>{text}</p>
    </div>
  );
}

export default TextContent;
const item1Data = require('./items/item1');
const module4Data = {
    "id": 4,
    "number": "4",
    "name": "Looking forward",
    "description": "In this section you will start to review ways to continue to stretch and grow in your role.",
    "time": "",
    items: [item1Data]
};

module.exports = module4Data;

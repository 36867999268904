import React, { useState, useEffect } from 'react';
import DraggableRankItem from './draggableRankItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { setQuestionComplete } from '../../api/setQuestionComplete';
import { setApiStepComplete } from '../../api/setApiStepComplete';
import getQuestionByUniqueId from '../../api/getQuestionByUniqueId';
import { Row, Col, Button } from 'react-bootstrap';
import Feedback from '../feedback/feedback';

export const RankingList = ({
  listItems,
  setUserMetadata,
  user,
  questionText,
  brand,
  guid,
  getAccessTokenSilently,
}) => {
  const [items, setItems] = useState(listItems.list);
  const [answers, setAnswers] = useState([]);
  const [finished, setFinished] = useState(false);

  const handleMoveItem = (dragItemId, hoverItemId) => {
    const dragIndex = items.findIndex((item) => item.id === dragItemId);
    const hoverIndex = items.findIndex((item) => item.id === hoverItemId);

    const reorderedItems = [...items];
    const [removed] = reorderedItems.splice(dragIndex, 1);
    reorderedItems.splice(hoverIndex, 0, removed);

    setItems(reorderedItems);
  };

  useEffect(() => {
    if (answers.length > 0) {
      setQuestionComplete(
        questionText,
        brand,
        guid,
        getAccessTokenSilently,
        answers
      );
      setApiStepComplete(
        `${guid} - Complete`,
        brand,
        user,
        getAccessTokenSilently,
        setUserMetadata
      );
    }
  }, [user, questionText, brand, guid, getAccessTokenSilently, answers]);

  const handleSubmit = () => {
    if (listItems.feedback) {
      setAnswers([{ answers: items, feedback: listItems.feedback }]);
      setFinished(true);
    } else {
      setAnswers([{ answers: items }]);
    }
  };

  const [previouslySavedResponse, setPreviouslySavedResponse] = useState(null);

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const response = await getQuestionByUniqueId(
          brand,
          guid,
          getAccessTokenSilently
        );
        if(response[0])
       {  return setPreviouslySavedResponse(response)}
          else {return setPreviouslySavedResponse(null);}
      } catch (error) {
        return 'Error fetching question: ' + error;
      }
    }
    fetchQuestion();
  }, []);

  const component = previouslySavedResponse ? (
    <div className="feedback-main">
      <div className="feedback-title">You responded:</div>
      <Row>
        <Col>
          {previouslySavedResponse[0].answers.map((item) => (
            <div key={item.text}>
              <div>{item.text}</div>
            </div>
          ))}
        </Col>
      </Row>
      {previouslySavedResponse[0].feedback ? (<><div className="feedback-title">Feedback:</div>
      {previouslySavedResponse[0].feedback}</>) : ('')}
    </div>
  ) : (
    <div className="ranking-main">
      <DndProvider backend={HTML5Backend}>
        <div className="ranking-list">
          {items.map((item) => (
            <DraggableRankItem
              key={item.id}
              item={item}
              name={item.name}
              origin="ranking"
              onMoveItem={handleMoveItem}
            />
          ))}
        </div>
        <div className="list-sort-submit">
          <Button
            type="button"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </DndProvider>
    </div>
  );

  return (
    <>
      {' '}
      {finished ? <Feedback feedbackText={listItems.feedback} /> : component}
    </>
  );
};

export default RankingList;

const page1Data = {
  id: 'f7c06431-b39e-4be0-9e4c-2b2d29cbc8e7',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: 'fad7dd69-0c2a-47a6-97a3-32b33016d4dc',
      name: 'image',
      imageUrl: 'https://apifiles.cappassessments.com/90DTK/phase2-module1.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will explore a typical change journey and your experiences in your new role so far.',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [
    {
      type: 'content',
      text: 'Click "Next" to get started.',
    },
  ],
};

module.exports = page1Data;

const page1Data = require('./pages/page1');
const page2Data = require('./pages/page2');
const page3Data = require('./pages/page3');
const page4Data = require('./pages/page4');
const page5Data = require('./pages/page5');
const page6Data = require('./pages/page6');
const item1Data = {
    id: "e4e5ac96-2786-482f-b5bc-e39e73fca881",
    title: "Discover your support network",
    pages: [page1Data,page2Data,page3Data,page4Data,page5Data,page6Data]
};

module.exports = item1Data;

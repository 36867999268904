import React from "react"
import { motion } from "framer-motion"
// import { useAnimation, motion } from "framer-motion"
// import { useInView } from "react-intersection-observer"
import { Col, Container, Row, } from "react-bootstrap"

export function JumboSectionCTA(props) {

  return (
    <>
      <Container className={props.class} id={props.id}>
        <Row>
          <Col xs={12} className="text-container">
            <div className="number-title">
              <div className={props.numberCheck}>{props.number}</div>
            </div>
            <h2> {props.title}</h2>
            <p> {props.text}</p>
            <motion.button
              className="btn btn-primary"
              variant="primary"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => (window.location.href = props.link)}>
              {props.buttonText}
            </motion.button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <img src={props.image} alt={props.alt}/>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default JumboSectionCTA

const page1Data = require('./pages/page1');
const page2Data = require('./pages/page2');
const page3Data = require('./pages/page3');
const page4Data = require('./pages/page4');
const page5Data = require('./pages/page5');
const page6Data = require('./pages/page6');
const item1Data = {
    id: "2ee89ce9-577e-4248-a471-9be6b66ae29b",
    title: "Discover how to make a great first impression",
    pages: [page1Data,page2Data,page3Data,page4Data,page5Data,page6Data]
};

module.exports = item1Data;

const page5Data = {
  id: 'a8228ce5-3727-4c55-bc26-eaf53d8144ec',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content quote',
      text: '“Chance favours the connected mind” – Stephen Johnson',
    },
    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],

  afterResponse: [''],
  moreResources: [
    {
      id: 'f58ad09c-bb50-4d93-9a59-f422137bc49e',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: 'caffa17f-778c-4eca-b89b-9adccb7cd546',
          name: 'Read an article on practical ways to build connections.',
          heading: 'Read',
          link: 'https://hbr.org/2005/03/a-practical-guide-to-social-networks',
          linkText: 'Read more',
          class: 'article-resource',
        },
        {
          id: '7837c2ae-ff10-4882-983a-a217b83490fa',
          name: 'Listen to a podcast on breaking out of our social bubbles.',
          heading: 'Listen',
          link: 'https://choice.npr.org/index.html?origin=https://one.npr.org/?sharedMediaId=531921221:531926323',
          linkText: 'Listen now',
          class: 'video-resource',
        },
        {
          id: '7837c2ae-ff10-4882-983a-a217b83490fa',
          name: 'Watch a video on where good ideas come from.',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/steven_johnson_where_good_ideas_come_from?language=en',
          linkText: 'Watch now',
          class: 'video-resource',
        },
      ],
    },
  ],
  responseType: [
    {
      id: '2c2f7079-bc96-458f-9fc3-42e52d1d1f6e',
      name: 'freeText',
      class: 'missions',
      guid: '5e845189-f28b-4de5-b99d-e150d8d606e1',
      questionHeader: 'Mission 1',
      questionText: 'Build your cross-department knowledge',
      questions: [
        {
          1: 'Identify and connect with a subject-matter expert in an area you are interested in. Report on how you did it and the outcome.',
        },
      ],
    },
    {
      id: 'adb79849-8a5c-4bab-83b3-d2d27f3ca62c',
      name: 'freeText',
      class: 'missions',
      guid: 'f2df9ad7-05a2-499d-87c4-91e70bd66106',
      questionHeader: 'Mission 2',
      questionText: 'Get outside your social ‘bubble’',
      questions: [
        {
          1: 'Attend a virtual or in person social or professional event outside your usual area of interest to broaden your perspective. For example, join an ‘employee group’ networking event, attend a webinar or talk on a new topic. Report on what you did and the outcome.',
        },
      ],
    },
    {
      id: 'a045c4a3-c1d3-498d-8abf-dcd4d0bb81ae',
      name: 'freeText',
      class: 'missions',
      guid: 'ebbbe02d-57d6-460d-bbb4-b0f416a68e07',
      questionHeader: 'Mission 3',
      questionText: 'Deepen a professional relationship ',
      questions: [
        {
          1: 'Identify someone in your existing network that you would like to get to know better. Schedule some time to connect with them and report back on what you learn.',
        },
      ],
    },
  ],
};

module.exports = page5Data;

const item1Data = require('./items/item1');
const module3Data = {
    "id": 3,
    "number": "3",
    "name": "Watch your wellbeing",
    "description": "In this section you will start to explore the different types of wellbeing, how to balance yours to allow you to stretch at work. ",
    "time": "This section should take approximately 15 minutes to complete",
    items: [item1Data]
};

module.exports = module3Data;

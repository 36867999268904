import React from 'react';
import { useState } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import {setQuestionComplete} from '../../api/setQuestionComplete';
import { setApiStepComplete } from '@capp.micro/shared/api';
import Feedback from '../feedback/feedback';

export const FreeTextQuiz = ({ questions, setUserMetadata, user, questionText, brand, guid, getAccessTokenSilently }) => {
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [responses, setResponses] = useState([]);
  const [input, setInput] = useState('');
  const [finished, setFinished] = useState(false);
  const feedbackToShow = 'Move on to the next question';
  const feedbackRequired = 'feedback' in questionText;

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleNav = (e) => {
    const addResponse = () => {
      const text = input;
      const response = {
        question: questions[0].questions[currentQuestion],
        answer: text,
      };
      const clearMe = document.querySelector('#free-text-quiz');
      clearMe.value = '';
      return [...responses, response]
    };
    if (e.target.innerHTML === 'Back') {
      if (currentQuestion > 1) {
        setCurrentQuestion((prevQuestion) => prevQuestion - 1);
      }
    } else if (e.target.innerHTML === 'Next') {
      const newResponses = addResponse();
      setResponses(newResponses);
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    } else if (e.target.innerHTML === 'Submit') {
      const answers = addResponse();
      setResponses(answers)
      setQuestionComplete(questionText, brand, guid, getAccessTokenSilently, answers);
      setApiStepComplete(`${guid} - Complete`, brand, user, getAccessTokenSilently, setUserMetadata);
      setFinished(true);
    }
    
  };

  return (
  <>{finished && feedbackRequired ? (
    <Feedback feedbackText={feedbackToShow} />
  ) : (
    <div className="quiz-session">
      <div className="free-text-quiz-header">
        <div className="free-text-quiz-questions">
          Question {currentQuestion.toString()} of{' '}
          {Object.keys(questions[0].questions).length}{' '}
        </div>
      </div>
      <div className="free-text-quiz-question">
          {questions[0].questions[currentQuestion]}
      </div>
      <div className="free-text-quiz-answers">
        <div className="free-text-quiz-input">
          <InputGroup size="sm" className="mb-3">
            <Form.Control
              as="input"
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              id="free-text-quiz"
              onChange={handleInput}
            />
          </InputGroup>
        </div>

        <div className="free-text-quiz-nav">
          <Button onClick={handleNav}>Back</Button>
          <Button onClick={handleNav}>
            {currentQuestion === Object.keys(questions[0].questions).length
              ? 'Submit'
              : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  )}</>
        )
}

export default FreeTextQuiz;
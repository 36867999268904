import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const reactPlugin = new ReactPlugin();
let appInsights;

if (typeof window !== 'undefined' && window.appInsightsInstrumentationKey) {
  const history = createBrowserHistory();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: window.appInsightsInstrumentationKey,
      isCookieUseDisabled: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: history }
      }
    }
  });
  appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };

const page4Data = {
  id: '65ac1d1e-e955-49a1-af83-6a829436549c',
  number: '4',
  title: 'Reflect: your network map',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'A good way to start broadening your network is to reflect on where you have gaps and how you could start to fill them.',
    },
    {
      class: 'content',
      text: 'To help identify these gaps, plot who is currently in your network in the box below. Here is a reminder of the categories from the previous exercise to support –',
    },

    {
      list: [
        'Different knowledge or skills',
        'Different business area',
        'Different background to you',
        'Different generation',
        'Different hierarchical level',
        'Different strengths',
        'Similar strengths and weaknesses',
        'Similar role',
      ],
    },
  ],

  afterResponse: [''],
  action: [
    'Use your extended network, i.e. your current connections, to expand your own and fill any gaps.',
  ],

  responseType: [
    {
      id: 'b1b7ab17-fdc7-4d90-9fd7-592636b04fda',
      name: 'freeText',
      class: 'missions',
      guid: '38002e00-f00f-4a8e-9360-ea6ff9f2fe4b',
      questionHeader: '',
      questionText: ' ',
      questions: [
        {
          1: '',
        },
      ],
    },
    {
      id: 'd5bab131-2dc9-4bbe-905d-a676eac3a2e8',
      name: 'freeText',
      class: 'missions',
      guid: '624e4f52-41e0-443e-aed0-b872a1c45693',
      questionHeader: '',
      questionText: ' ',
      questions: [
        {
          1: 'What actions you could take to fill any gaps in your network?',
        },
      ],
    },
  ],
};

module.exports = page4Data;

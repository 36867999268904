const page1Data = {
  id: '11541b2a-1b6a-4d92-bc56-c9a3042463bd',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: '047fe821-3134-4ff4-9fe2-ea6ab2911c00',
      name: 'image',
      imageUrl:
        'https://apifiles.cappassessments.com/90DTK/phase1-module1-22.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will discover your strengths – what they are, how to spot them in yourself and others. You will also identify how your top strengths can support you during the first 90 days and beyond.',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [
    {
      type: 'content',
      text: 'Click "Next" to get started.',
    },
  ],
};

module.exports = page1Data;

const page6Data = {
  id: '9ef8a75d-e04b-4cb0-9699-055342bfd06c',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Answer the question below to test your knowledge. Click complete module when you are finished and choose another topic to explore.',
    },
  ],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      id: '16',
      name: 'quiz',
      class: 'end-quiz',
      guid: '5f8f20fa-0453-40e6-8759-a9c4363206f4',
      questionText: '',
      questions: [
        {
          question: 'Which is true?',
          answers: [
            'There is a set time limit for moving through a change.',
            'You cannot be on more than one change journey at once',
            'Everyone moves through change differently',
          ],
          showPrevious: true,
          showFeedback: true,
          correctAnswer: 'Everyone moves through change differently',
          feedback: {
            correctFeedback:
              'You’ve got it! Everyone moves through change differently, there is no set time limit and we can be on more than one journey at once.',
            incorrectFeedback:
              'Not quite – everyone moves through change differently, there is no set time limit and we can be on more than one journey at once.',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page6Data;

const page3Data = {
  id: '0f5d8fe4-db62-4a3f-9629-0eec03bd3a3a',
  number: '3',
  title: 'Understand: why networks are important',
  content: [
    {
      class: 'content',
      text: 'We need a range of connections in our network, especially in the first 90-days, to ask questions, build our knowledge and find out more about the organisation and our role. ',
    },
    {
      class: 'content',
      text: 'Here is a reminder of the categories and definitions:',
    },
    {
      image: {
        src: 'https://apifiles.cappassessments.com/90daytoolkit/support-network.png',
        alt: 'Support network',
      },
    },
    {
      class: 'content',
      text: '',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'Support networks are made up of people both inside and outside our workplace. Go broader and consider other connections you may have.',
  ],
  resourceType: [],

  responseType: [
    {
      id: '4c63efe0-6fda-438d-8b93-4a10299bfffb',
      name: 'freeText',
      class: 'missions',
      guid: 'b5130d5e-e4df-4469-ad3c-4667c40835a7',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: 'Reflect on who you have currently in your network and which category they fall into.',
        },
      ],
    },
  ],
};

module.exports = page3Data;

const page3Data = {
  id: 'ef866d16-3e47-404f-b302-de93c1734298',
  number: '3',
  title: 'Deepen your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: "Cappfinity's definition of a strength is based on three components - performance, energy and use.",
    },
    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/PEU-Model.png',
        alt: 'PEU model',
      },
    },
    {
      class: 'content question',
      text: 'Look at the cards below to understand more about strengths and weaknesses.',
    },
  ],
  time: '',
  afterResponse: [
    'Using these indicators will help you to identify your own strengths and weaknesses, and use this information to aid your future development and success. We all have different strengths and weaknesses – this unique make up is what makes us who we are.',
  ],
  action: [
    'Try observing your own strengths and weaknesses when you perform different tasks at work.',
  ],
  responseType: [
    {
      id: '637f1c48-8b77-43c9-b20f-88051744a4e8',
      name: 'flipCard',
      class: 'flipcard-container',
      guid: '3f2e184f-df12-4fad-b349-e7d198e6872a',
      questionText: '',
      cardText: {
        frontText: 'A strength is…',
        backText:
          '...something you do well, use often and that gives you energy. When using a strength you are likely to feel more energised, motivated and focused, and talk about it with more detail and animation.',
        circle: './images/circle.svg',
      },
    },
    {
      id: '9d55e980-f151-4657-a554-8f4b5881dcff',
      name: 'flipCard',
      class: 'flipcard-container yellow',
      guid: '23a89e1d-846e-4566-98aa-d18329e76f5c',
      questionText: '',
      cardText: {
        frontText: 'A weakness is…',
        backText:
          "...something you don't do often or do as well and doesn't give you energy. In fact, it can do the opposite. You may feel drained, demotivated or anxious, meaning performance and efficiency are likely to be lower.",
        circle:
          'https://apifiles.cappassessments.com/90DTK/colour-circle-bg.png',
      },
    },
  ],
};

module.exports = page3Data;

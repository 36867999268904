import React from "react"
import { motion } from "framer-motion"
// import { useAnimation, motion } from "framer-motion"
// import { useInView } from "react-intersection-observer"
import { Col, Container, Row, } from "react-bootstrap"


export function FloatingPanel(props) {
  // const variants = {
  //   visible: { opacity: 1, y: 0 },
  //   hidden: { opacity: 0, y: 20 },
  // }
  // const controls = useAnimation()
  // const [ref, inView] = useInView()
  // useEffect(() => {
  //   if (inView) {
  //     controls.start("visible")
  //   }
  // }, [controls, inView])
  return (
    <>
      <Container className={props.class} id={props.id}>
        <Row className="text-container">
          <Col xs={12} lg={10}>
            <a name={props.name}></a>
            <div className="number-title">
              <div className={props.numberCheck}>{props.number}</div>
              <h2>
                {props.title}
              </h2>
            </div>

            <p>
              {props.text}
            </p>
          </Col>
          <Col xs={12} lg={2}>
            <div>

              <motion.button
                className="btn btn-primary"
                variant="primary"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => (window.location.href = props.link)}>
                {props.buttonText}
              </motion.button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default FloatingPanel

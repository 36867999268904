const page4Data = {
  id: '4438a16e-3853-419d-9617-2ce1a017caab',
  number: '4',
  title: 'Reflect: asking for feedback',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Another important aspect of learning is to sense check how you think you are doing, against what your manager and team think. Request feedback from others on your strengths and weaknesses, values, skills and development areas. Look at the image for question inspiration.',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/feedback-types.png',
        alt: 'Feedback types',
      },
    },
  ],
  afterResponse: [''],
  action: [
    'Schedule regular ‘retrospectives’ to evaluate your own performance in projects and support your growth and development.',
  ],
  responseType: [
    {
      id: 'fabb4e79-4b5e-4344-87ea-514e53a4737d',
      name: 'freeText',
      class: 'missions',
      guid: '4788c589-4b96-4ab8-8252-236c946d0ae7',
      questionHeader: '',
      questionText:
        '',
      questions: [
        {
          1: 'Identify one thing you would like feedback on (this could be performance, a behaviour, project or action).',
        },
      ],
    },
    {
      id: 'e41807de-14c9-485a-8f5a-cb92c836ab87',
      name: 'freeText',
      class: 'missions',
      guid: '1bc0e611-67f9-4afc-b116-4e72a4e1adb0',
      questionHeader: '',
      questionText:
        '',
      questions: [
        {
          1: 'Internal reflection – reflect on how you think you are doing.',
        },
      ],
    },
    {
      id: '4a1a7837-0752-4331-87be-90c7588ed728',
      name: 'freeText',
      class: 'missions',
      guid: '6524d2e5-1151-482c-84e5-b13aa853b187',
      questionHeader: '',
      questionText:
        '',
      questions: [
        {
          1: 'Feedback from others – identify 2-3 questions to gather feedback and three people you are going to ask.',
        },
      ],
    },
    {
      type: 'content',
      text: 'Whilst feedback is critical to our ongoing development, it is subjective. When we ask for feedback, we need to be prepared that it may not always be good. In fact, the best feedback is constructive and can help us make real changes to how we work or behave, as long as we receive it with an open mind.',
    },
    {
      id: 'c6f0a8fe-e90e-46bf-8098-1241344540c2',
      name: 'freeText',
      class: 'missions',
      guid: '461a4420-8b56-491d-8383-c4fc3e612121',
      questionHeader: '',
      questionText:
        '',
      questions: [
        {
          1: 'Think about one way we could stay open to whatever feedback we receive.',
        },
      ],
    },
  ],
};

module.exports = page4Data;

const item1Data = require('./items/item1');
const module3Data = {
    "id": 3,
    "number": "3",
    "name": "Discover your support network",
    "description": "Discover who is in your support network",
    "time": "This section should take approximately 15 minutes to complete ",
    items: [item1Data]
};

module.exports = module3Data;

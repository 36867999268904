const item1Data = require('./items/item1');
const module1Data = {
    id: 1,
    number: "1",
    name: "Celebrate your firsts",
    description: "In this section you will focus on your achievements so far, finding new ways to stretch yourself through different experiences and overcoming challenges.",
    items: [item1Data]
};

module.exports = module1Data;

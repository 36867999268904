const item1Data = require('./items/item1');
const module2Data = {
    "id": 2,
    "number": "2",
    "name": "Stretch with feedback",
    "description": "In this section you will explore how to ask for feedback to help you stretch and develop.",
    items: [item1Data]
};

module.exports = module2Data;

const item1Data = require('./items/item1');
const module1Data = {
  id: 1,
  number: '1',
  name: 'Discover your strengths',
  description: 'Explore strengths and how to recognise them',
  items: [item1Data],
};

module.exports = module1Data;

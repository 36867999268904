const page5Data = {
  id: 'a2feb17a-0305-4fcb-a028-99d4ebf370d1',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  afterResponse: [''],
  moreResources: [
    {
      id: '70033560-f87c-49df-9fe9-fd0521f1f411',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: '8fde5b3e-c59b-400d-9550-ade159e35b45',
          name: 'Read an article on the ‘big rocks’ of life.',
          heading: 'Read',
          link: 'https://www.forbes.com/sites/hillennevins/2020/01/21/what-are-your-big-rocks/?sh=281c8d96fae3',
          linkText: 'Read more',
          class: 'article-resource',
        },
        {
          id: 'b6215e68-892e-4c88-b992-744e758fc7a0',
          name: 'Listen to a podcast on finding joy in expected places.',
          heading: 'Listen',
          link: 'https://choice.npr.org/index.html?origin=https://www.npr.org/programs/ted-radio-hour/668359164/where-joy-hides',
          linkText: 'Listen now',
          class: 'video-resource',
        },
        {
          id: '114d7242-54e2-47cd-bd75-e8cd3b107763',
          name: 'Watch a video with exercises on self-compassion.',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/checking_in_with_susan_david_bonus_self_soothing_exercises_with_dr_kristin_neff',
          linkText: 'Watch now',
          class: 'article-resource',
        },
      ],
    },
  ],
  responseType: [
    {
      id: 'c758da70-f850-43b5-abee-9bdf3210e49f',
      name: 'freeText',
      class: 'missions',
      guid: 'e5e39881-cf0e-4d72-ba08-da39a76ed715',
      questionHeader: 'Mission 1',
      questionText: 'Adopt a wellbeing habit',
      questions: [
        {
          1: 'For the next 30 days, schedule regular breaks or wellbeing check ins in your diary and commit to taking them. Report back on your progress and the outcome.',
        },
      ],
    },
    {
      id: 'c2662c3f-b6e2-4733-91eb-91027aae1cae',
      name: 'freeText',
      class: 'missions',
      guid: '89cda790-5c22-4aca-9ca8-b1f13d576748',
      questionHeader: 'Mission 2',
      questionText: 'Gather experiences',
      questions: [
        {
          1: 'Ask your team or peers how they balance their wellbeing at work. Report back on what you find out.',
        },
      ],
    },
    {
      id: '048c5234-3def-469a-a66e-f8a39e032586',
      name: 'freeText',
      class: 'missions',
      guid: 'ae7f0473-3974-423c-a132-c3dcbe108ab0',
      questionHeader: 'Mission 3',
      questionText: 'Commit to 30 days of wellbeing',
      questions: [
        {
          1: '<div>Check out the <a href="https://actionforhappiness.org/calendar" target="_blank" rel="noreferrer">‘Action for Happiness’</a> calendar and commit to taking one small action everyday for 30-days. Report back on how you found the experience.</div>',
        },
      ],
    },
  ],
};

module.exports = page5Data;

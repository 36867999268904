const page2Data = {
  id: '9a6e2c04-979d-4c44-a643-c6b05a3960c2',
  number: '2',
  title: 'Uncover: what change is',
  content: [
    {
      class: 'content',
      text: 'Right now, you are all going through change - you could be starting a new role, at a new company, in a new city. This is a big transition that will involve multiple changes to your routine, way of learning and perspectives, to name a few.',
    },
    {
      class: 'content',
      text: 'But what do we really know about change and how it impacts us?',
    },
  ],
  afterResponse: [''],
  action: [
    'Everyone’s experiences of change will differ. Over the next 30 days, notice how you react in times of change and any triggers you experience.',
  ],
  resourceType: [],
  responseType: [
    {
      id: '2234o8234o871o8745',
      name: 'bucketSort',
      guid: 'b9dc6626-ec01-4691-973e-7af691d3b165',
      questionText:
        'Sort the myths from the truths to uncover more about the change journey we all experience',
      categories: ['Myths', 'Truths'],
      feedback: {
        correctFeedback:
          'Correct! Experiences of change are different for everyone and vary depending on the situation or type of change we are going through. Move on to explore more…',
        incorrectFeedback:
          'Not quite! Experiences of change are different for everyone and vary depending on the situation or type of change we are going through. Move on to explore more…',
        defaultFeedback: 'Move on to the next question',
      },
      listItems: [
        {
          id: '1',
          name: 'A',
          text: 'There is a set time limit for moving through a change.',
          correctAnswer: 'Myths',
        },
        {
          id: '2',
          name: 'B',
          text: 'You cannot be on more than one change journey at once',
          correctAnswer: 'Myths',
        },
        {
          id: '3',
          name: 'C',
          text: 'How quickly we move through the change curve is an indicator of our future success.',
          correctAnswer: 'Myths',
        },
        {
          id: '4',
          name: 'D',
          text: 'Knowing where we are on the curve is helpful to us and to others',
          correctAnswer: 'Truths',
        },
        {
          id: '5',
          name: 'E',
          text: 'Everyone moves through change differently',
          correctAnswer: 'Truths',
        },
        {
          id: '6',
          name: 'F',
          text: 'Change can be physical, emotional, cognitive or psychological',
          correctAnswer: 'Truths',
        },
      ],
    },
  ],
};

module.exports = page2Data;

const page5Data = {
  id: 'a22b688c-45d5-497b-a263-ef61795cf5b4',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: "Answer the question below to test your knowledge. Click 'Complete Module' when you are finished and choose another topic to explore.",
    },
  ],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      id: '349d1e01-5555-4c98-a1ec-e06394d62366',
      name: 'quiz',
      class: 'end-quiz',
      guid: '7a12019d-64a4-44f1-84fe-78094fc3b1d8',
      questionText: '',
      questions: [
        {
          question: 'Which of these have the most influence over our brand? ',
          answers: ['Performance ', 'Image', 'Exposure'],
          showPrevious: true,
          showFeedback: true,
          correctAnswer: 'Exposure',
          feedback: {
            correctFeedback:
              'You’ve got it. Whilst performance and image have an impact on how we are perceived by others, research shows the amount of exposure we get has the most influence.',
            incorrectFeedback:
              'Not quite! Whilst performance and image have an impact on how we are perceived by others, research shows the amount of exposure we get has the most influence. ',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page5Data;

const page3Data = {
  id: 'e9f2d5e3-24c4-4504-85da-9e68dec33242',
  number: '3',
  title: 'Understand: the change curve',
  resourceType: [
    {
      id: 'b0558de7-b8d0-4a07-8792-e2f6faf40f21',
      name: 'video',
      videoUrl:
        'https://apifiles.cappassessments.com/90dtk/The-Change-Curve-Video-asset-Open-Captions.mp4',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'Watch this video to find out more about the change curve – including the myths and truths from the previous exercise and how you can use the change curve to support your learning and development.',
    },
    {
      class: 'content question',
      text: 'Now take a look at the options below and choose the actions you think we can take to help ourselves move through the change curve.',
    },
  ],
  time: '',
  afterResponse: [
    'Using these indicators will help you better able to identify your own strengths and weaknesses and use this information to aid your future development and success.',
  ],
  responseType: [
    {
      id: '3',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: '5bcc2ee3-e24e-4430-a1a5-89cafd52e08c',
      questionText: '',
      questions: [
        {
          questions: {
            1: 'Now take a look at the options below and choose the actions you think we can take to help ourselves move through the change curve. ',
          },
          responses: {
            1: [
              'Leverage core strengths',
              'Recognise we are in crisis',
              'Draw on existing knowledge and skills',
              'Talk to the people around us',
              'Reflect and learn from past experiences',
            ],
          },
          incorrectAnswers: [],
          feedback: {
            correctFeedback: '',
            incorrectFeedback: '',
            defaultFeedback:
              'Great! Make a note of what you selected – one of your missions will be to explore these actions further.',
          },
        },
      ],
    },
  ],
};

module.exports = page3Data;

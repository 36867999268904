const page1Data = {
  id: 'c591004f-c4cd-4408-a394-08de54fcc135',
  number: '1',
  title: 'Reflect',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Congratulations! You have finished the final phase in your 90-day toolkit. We hope you have found it a valuable experience.',
    },

    {
      class: 'content',
      text: 'Before you can complete this phase, use the checklist below to make sure you have done everything you need to. ',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: 'cabf83d4-9507-43db-a5bb-f5a93e354fe4',
      name: 'SHARE YOUR FEEDBACK',
      items: [
        {
          id: 'be537f55-4b0c-4581-830a-61249ab1c37b',
          name: 'Please take this quick survey to share your thoughts on this phase',
          heading: '',
          link: 'https://survey.alchemer.eu/s3/90601523/90-Day-Toolkit-Phase-3',
          linkText: 'Take survey',
          class: 'article-resource survey',
        },
      ],
    },
  ],
  responseType: [
    {
      id: 'ebf86fb7-bdf5-40df-a33e-8763c57a4961',
      name: 'checklist',
      guid: '52752b16-82cf-48b3-9525-6a21f950c58e',
      questionText: '',
      questions: [{}],
      list: {
        options: {
          1: 'I have tried at least one mission in each section',
          2: 'I have reflected on my most memorable ‘firsts’',
          3: 'I know what small wins are and why they are important',
          4: 'I understand how to ask for feedback',
          5: 'I have started to reflect on how to balance my wellbeing at work',
          6: 'I have identified stretch opportunities to push my comfort zone',
          7: 'I have taken some time to reflect on my learning',
        },
        prompt: '',
        textComplete: 'Nicely done! Continue with your reflections.',
        textIncomplete:
          'Take a look at the areas you have checked off and consider revisiting these sections to ensure you get the most from this toolkit.',
      },
    },
    {
      id: 'e5963f49-7b55-4c64-bdfb-cebd7441b62f',
      name: 'freeText',
      class: 'missions',
      guid: '2f8265db-fd79-48de-aeb1-edc4a04f66c2',
      questionText:
        '',
      questions: [
        {
          1: 'What have you learned about yourself over the last 90-days?',
        },
      ],
    },
    {
      id: 'd1a30fa3-38e5-46f4-9228-29d25ebb161b',
      name: 'freeText',
      class: 'missions',
      guid: '91e5d333-71c8-4dae-ad08-45ea26ae5444',
      questionText:
        '',
      questions: [
        {
          1: 'Looking forward, how will you continue to stay focused on your learning and development?',
        },
      ],
    },
  ],
};

module.exports = page1Data;

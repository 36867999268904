const page4Data = {
  id: '77f3e94d-be89-42cb-b5e7-64bb0ee5cf81',
  number: '4',
  title: 'Reflect: more than words',
  content: [
    {
      class: 'content',
      text: 'It’s not just what you say, but how you say it…',
    },
    {
      class: 'content',
      text: 'This is true with first impressions – research shows that tone of voice and body language have a much greater influence on how others see us, than just the words we choose to use. So, be aware of the other subtle signs and signals you may be unwittingly giving off.',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/words-tone-body-90dtk.png',
        alt: 'More than words',
      },
    }
  ],
  time: '',
  afterResponse: [''],
  action: [
    "Find someone who is highly respected at work, observe how they act and try to 'mirror' any positive behaviours.",
  ],
  resourceType: [],
  responseType: [
    {
      id: 'fdfbfb56-cbd9-479d-90a0-43c34cb57409',
      name: 'freeText',
      class: 'missions',
      guid: 'e7a71d38-ab50-48e4-b19b-77bd72f96a30',
      questionText:
        '',
      questions: [
        {
          1: 'Identify three things that could get in the way of you making a great first impression. What messages do you want to get across in your non-verbal communication e.g., body language and tone?',
        },
      ],
    },
  ],
};

module.exports = page4Data;

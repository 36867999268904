const page1Data = {
  id: '42e1149b-57c8-4921-965c-abcc42e1d210',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: 'cb3cfc4e-5fb4-4e58-8f1c-d0670dbeb867',
      name: 'image',
      imageUrl: 'https://apifiles.cappassessments.com/90DTK/phase3-module4-2.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will start to review ways to continue to stretch and grow in your role.',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [{ type: 'content', text: 'Click "Next" to get started.' }],
};

module.exports = page1Data;

import React from "react"
import { motion } from "framer-motion"
// import { useAnimation, motion } from "framer-motion"
// import { useInView } from "react-intersection-observer"
import { Col, Container, Row, } from "react-bootstrap"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export function PrepModal(props) {
  return (
       <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static" 
    >
      <Modal.Header closeButton>
          <Modal.Title>{props.videoTitle}</Modal.Title>
        </Modal.Header>
      <Modal.Body>
      <video
          controls
          controlsList="nodownload noremoteplayback"
          poster={props.videoThumbnail}
          style={{width: "100%"}}
          autoPlay
          
        >
          <source src={props.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal.Body>
    </Modal>
  )
}

export default PrepModal

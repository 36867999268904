const page3Data = {
  id: '1c8e3d09-d90a-4fba-90e9-6ae6095b2eb3',
  number: '3',
  title: 'Understand: what is culture?',
  resourceType: [],
  content: [
    {
      class: 'content quote',
      text: '“Organisational culture is the collection of <strong>values, expectations and practices</strong> that guide and inform the actions of all team members and employees”',
    },
    {
      class: 'content',
      text: 'In all organisations there will be multiple cultures – the culture of the business, teams and individual departments. These are usually influenced by individual leaders, managers or team needs. To help us understand what contributes to culture in an organisation, we can use a tool called the culture pyramid. Explore the image below to find out what each level means.',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/culture-pyramid.png',
        alt: 'Culture pyramid',
      },
    },
    {
      class: 'content',
      text: '',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [],
  responseType: [
    {
      id: '0910938091',
      name: 'freeText',
      class: 'missions',
      guid: '1063da2f-3ed4-494f-9a00-cc3b3852021d',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: 'Take time to reflect on what you have observed so far about the culture of the business, and the team you are working with.',
        },
      ],
    },
  ],
};

module.exports = page3Data;

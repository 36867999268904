const sharingAWeakness = 'A (Sharing a weakness)';
const sharingAStrength = 'B (Sharing a strength)';
const page2Data = {
  id: '711cf75b-b3e5-40fa-8333-28708decc19d',
  number: '2',
  title: 'Uncover: what are strengths?',
  content: [
    {
      class: 'content',
      text: 'We all have our own unique make up of strengths, preferences, likes, dislikes, but how can you spot them in yourself and others?',
    },
  ],
  afterResponse: [''],
  action: ['Check in with your energy levels when you try something new.'],
  resourceType: [
    {
      id: '70a236ef-6e4d-48ce-8bbd-bc95242d864a',
      name: 'video',
      videoUrl:
        'https://apifiles.cappassessments.com/90dtk/What-do-you-find-draining-Sub.mp4',
    },
    {
      id: '6d76aa6a-c9ae-44cb-a5bb-15b37dfd5673',
      name: 'video',
      videoUrl:
        'https://apifiles.cappassessments.com/90dtk/What-do-you-find-energising-Sub.mp4',
    },
  ],

  responseType: [
    {
      id: '2',
      name: 'bucketSort',
      guid: 'e6c50262-5568-4201-92d0-0230a338f14a',
      questionText:
        "Watch these two short video clips and sort the words that best describe the clips into the 'A' and 'B' boxes",
      categories: [sharingAWeakness, sharingAStrength],
      feedback: {
        correctFeedback:
          'You’ve got it! When someone is describing a strength, they may seem more energised, enthusiastic, motivated with higher energy. However, when someone is describing a weakness, they may come across demotivated, with lower energy and may feel withdrawn or inauthentic. Go to the next page to find out more.',
        incorrectFeedback:
          'Hmm, not quite – when someone is describing a strength, they may seem more energised, enthusiastic, motivated with higher energy. However, when someone is describing a weakness, they may come across as demotivated, with lower energy, and may feel withdrawn or inauthentic. Go to the next page to find out more.',
        defaultFeedback: 'Move on to the next question',
      },
      listItems: [
        {
          id: '1',
          name: 'A',
          text: 'Inauthentic',
          correctAnswer: sharingAWeakness,
        },
        {
          id: '2',
          name: 'B',
          text: 'Low energy',
          correctAnswer: sharingAWeakness,
        },
        {
          id: '3',
          name: 'C',
          text: 'Demotivated',
          correctAnswer: sharingAWeakness,
        },
        {
          id: '4',
          name: 'D',
          text: 'Withdrawn',
          correctAnswer: sharingAWeakness,
        },
        {
          id: '5',
          name: 'E',
          text: 'Energised',
          correctAnswer: sharingAStrength,
        },
        {
          id: '6',
          name: 'F',
          text: 'Enthusiastic',
          correctAnswer: sharingAStrength,
        },
        {
          id: '7',
          name: 'G',
          text: 'Higher energy',
          correctAnswer: sharingAStrength,
        },
        {
          id: '8',
          name: 'H',
          text: 'Motivated',
          correctAnswer: sharingAStrength,
        },
      ],
    },
  ],
};

module.exports = page2Data;

const page5Data = {
  id: 'efcbbb41-a3ab-49d7-9caf-87d02fe42867',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: '23498ccb4506e-fab6-4fd2-847f-79a78ab42bc623983',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: '6accb678-859b-4fb1-99b5-c2bf391ea85f',
          name: 'Read an article on asking for what you need at work',
          heading: 'Read',
          link: 'https://hbr.org/2022/08/ask-for-what-you-need-at-work',
          linkText: 'Read more',
          class: 'article-resource',
        },
        {
          id: '9167fe85-67df-40b3-8cb6-8350b1af104d',
          name: 'Watch a TED talk for support with conversations',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/celeste_headlee_10_ways_to_have_a_better_conversation?referrer=playlist-talks_for_when_you_re_starting_a_new_job_aug_2017&autoplay=true',
          linkText: 'Watch now',
          class: 'video-resource',
        },
        {
          id: 'ca755ee6-d861-4a96-bd97-79e56307c8c8',
          name: 'Listen to a podcast on building relationships',
          heading: 'Listen',
          link: 'https://podcasts.apple.com/cy/podcast/building-relationships-working-together-means-winning/id1416206538?i=1000440024691',
          linkText: 'Listen now',
          class: 'article-resource',
        },
      ],
    },
  ],
  responseType: [
    {
      id: 'c9d67b99-d013-449f-b3c4-1a235ea55fc4',
      name: 'freeText',
      class: 'missions',
      guid: '266381e8-6387-4a18-90a2-fd1125d4c557',
      questionHeader: 'Mission 1',
      questionText: 'Grow your network',
      questions: [
        {
          1: 'Think about one person you need in your support network – use your existing connections to find people who could fill that gap. Report back what you tried, what worked and what you would do differently next time. ',
        },
      ],
    },
    {
      id: '71a45f78-a704-4378-9c1b-a4bd044d27d1',
      name: 'freeText',
      class: 'missions',
      guid: '96606c32-7d28-4de4-b695-e9c98496388d',
      questionHeader: 'Mission 2',
      questionText: 'Uncover your values',
      questions: [
        {
          1: 'In your next meeting see how your values show up at work – how do they drive or influence how you behave and your ways of working? Report back your observations below.',
        },
      ],
    },
    {
      id: '5ad52395-daac-4888-8425-fd76781c7ddf',
      name: 'freeText',
      class: 'missions',
      guid: '7ef1269c-9a1f-436d-91c5-7c791d9393ac',
      questionHeader: 'Mission 3',
      questionText: 'Find out something new',
      questions: [
        {
          1: 'Knowledge experts are a vital resource during your first 90 days and beyond. Think about a project you are already (or will be) working on and connect with a knowledge expert to discover more about it. Report back what you learn. ',
        },
      ],
    },
  ],
};

module.exports = page5Data;

const page1Data = require('./pages/page1');
const page2Data = require('./pages/page2');
const page3Data = require('./pages/page3');
const page4Data = require('./pages/page4');
const page5Data = require('./pages/page5');
const page6Data = require('./pages/page6');
const item1Data = {
    "id": "dceb87c8-24a5-41e3-9717-21d338327032",
    "title": "Explore the change curve",
    "description": "In this section you will explore a typical change journey and your experiences in your new role so far.",
    pages: [page1Data,page2Data,page3Data,page4Data,page5Data,page6Data]
};

module.exports = item1Data;

import React from 'react';
import { slide as Menu } from 'react-burger-menu';

export function BurgerMenu({Brand }) {


  return (
    <div className='header header-scrolled navbar navbar-expand-lg navbar-light sticky-top'>
      <Menu>
        <a id='dashboard' className='menu-item' href='/dashboard'>Practice Zone</a>
        <a id='functionalities' className='menu-item' href='/functionalities'>Functionalities</a>
        <a id='practice-questions' className='menu-item' href='/practice-questions'>Practice Questions</a>
        <a id='immersive-assessment' className='menu-item' href='/immersive-assessment'>Immersive Assessment</a>
        <a id='faqs' className='menu-item' href='/faqs'>FAQs</a>
        <a id='new-dashboard' className='menu-item' href='/new-dashboard'>New Dashboard</a>
        <a id='phase-page' className='menu-item' href='/phase-page'>Phase Page</a>
        <img src={Brand.POWERED_BY_CAPP_LOGO} alt='Powered by Cappfinity'></img>
      </Menu>
    </div>
  );
}

export default BurgerMenu;

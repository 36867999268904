const item1Data = require('./items/item1');
const module4Data = {
    "id": 4,
    "number": "4",
    "name": "Discover how to make a great first impression",
    "description": "Practice crafting a strong introduction.",
    "time": "",
    items: [item1Data]
};

module.exports = module4Data;

export const setApiStepComplete = async (
  key,
  brand,
  user,
  getAccessTokenSilently,
  setUserMetadata
) => {
  try {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: `${window.apiAudience}`,
        scope: '',
      },
    });

    const completeStepUrl = `${window.apiBaseUrl}/steps/completestep`;

    const updateBody = {
      clientName: brand.FLAG_NAME,
      stepName: key,
    };

    console.log('Calling ' + completeStepUrl);

    const updateMetadataResponse = await fetch(completeStepUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'Capp-Tenant-Id': brand.TENANT_ID,
      },
      body: JSON.stringify(updateBody),
    });

    const userMetadata = await updateMetadataResponse.json();
    localStorage.setItem(
      `userMetadata-${user.sub}`,
      JSON.stringify(userMetadata)
    );
    setUserMetadata(userMetadata);
  } catch (e) {
    console.error(e);
  }

  return null;
};

export default setApiStepComplete;

import React from 'react';
// import {isAuthenticated} from "../pages/dashboard"
import { Col, Container, Row } from 'react-bootstrap';

export function SidebarCardVideo(props) {
  return (
    <Container className={props.class}>
      <div className="card-video">
        <video
          controls
          controlsList="nodownload noremoteplayback"
          poster={props.videoThumbnail}
        >
          <source src={props.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <Row>
        <Col xs={12} className="card-text">
          <h2>{props.heading}</h2>
          <p>{props.text}</p>
        </Col>
      </Row>
    </Container>
  );
}

export default SidebarCardVideo;

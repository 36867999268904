const page6Data = {
  id: '45337b21-4621-47b4-a5e2-c6c2639f5a35',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Answer the question below to test your knowledge. Click Complete Module when you are finished and choose another topic to explore.',
    },
    {
      class: 'content question',
      text: 'Which of the categories below make up a strong support network? Select all that apply.',
    },
  ],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      id: '7af1b49e-bc29-4800-b1f0-17a951c193b1',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: '35e122fa-7fe1-4e59-99c2-93d783087a2e',
      questionText: '',
      questions: [
        {
          questions: {
            1: '',
          },
          responses: {
            1: [
              'Knowledge expert',
              'Resource controller',
              'Social connection',
              'The buddy',
            ],
          },
          incorrectAnswers: ['Social connection'],
          feedback: {
            correctFeedback:
              'In this section, we looked at a few different categories that make up a strong initial support network – these include ‘knowledge expert’, ‘resource controller’ and ‘the buddy’. Did you get all three? Well done if you did!',
            incorrectFeedback:
              'Not quite – ‘social connection’ isn’t correct. In this section we looked at a few different categories that make up a strong initial support network – these include ‘knowledge expert’, ‘resource controller’ and ‘the buddy’.',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page6Data;

const contentClass = 'content bold';
const page2Data = {
  id: '9753cbc3-ecac-4165-a27c-532d4b7c9aaf',
  number: '2',
  title: 'Uncover: stretch or stress',
  content: [
    {
      class: 'content',
      text: 'Wellbeing has become more prominent in work conversations in recent years, with many individuals and companies recognising its importance in our lives and work. We all have a responsibility for managing our own wellbeing, stress and anxiety in order to be able to perform at our best and balance our priorities. ',
    },
    {
      class: 'content',
      text: 'The <strong>optimal anxiety curve</strong> demonstrates this balance and provides a good model for reflection to recognise the tipping point – where we move from comfort, to stretch, to stress. Explore the image to find out more.',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/optimal-anxiety-curve.png',
        alt: 'Optimal anxiety curve',
      },
    },

    { class: contentClass, text: 'Low performance, low stretch, low stress' },

    {
      class: 'content',
      text: 'Too little stress can lead to too much comfort, lower motivation, boredom and potentially limited progression.',
    },

    { class: contentClass, text: 'High performance, high stretch, mid stress' },

    {
      class: 'content',
      text: 'Optimal performance. Being in this state often means we perform better, outside our comfort zone, but not too stressed or too stretched.',
    },

    {
      class: contentClass,
      text: 'Low performance, high stretch, high stress ',
    },

    {
      class: 'content',
      text: 'Too much stress can lead to heightened emotions, negative self-talk, imposter phenomenon and limited perspective. ',
    },
  ],
  time: '',
  afterResponse: [''],
  resourceType: [],
  responseType: [
    {
      id: '2',
      name: 'sliderQuestion',
      class: 'slider-container',
      guid: 'a1839fe1-c1f4-4d47-9ae0-ab73299f1b1d',
      questionText: '',
      questions: [
        {
          questions: [
            'Where are you on the scale between comfort, stretch, stress right now? 0-10',
          ],
          categories: {
            0: 'In my comfort zone, I don’t feel stretched or stressed',
            1: '',
            2: '',
            3: '',
            4: '',
            5: 'I’m doing great! I have a good balance between comfort, stretch and stress',
            6: '',
            7: '',
            8: '',
            9: '',
            10: 'This is too much. I feel stretched too far, stressed and overwhelmed.'
          },
          max: 10,
          min: 0,
          startingValue: 5,
          increments: 1,
          showPrevious: true,
          showFeedback: true,
          feedback: {
            correctFeedback: '',
            incorrectFeedback: '',
            defaultFeedback:
              'You will inevitably move in and out of optimal performance – learn to notice the signs of when things are becoming too much.',
          },
        },
      ],
    },
  ],
};

module.exports = page2Data;

export const getUserMetadata = async (
  user,
  getAccessTokenSilently,
  setUserMetadata
) => {
  try {
    if (!user){
      return;
    }

    const cachedUserMetadata = localStorage.getItem( 
      `userMetadata-${user.sub}`
    );

    if (cachedUserMetadata) {
      setUserMetadata(JSON.parse(cachedUserMetadata));
      return;
    } 
    
    const accessToken = await getAccessTokenSilently({
      audience: `https://${window.auth0Domain}/api/v2/`,
      scope: 'read:current_user update:current_user_metadata',
    });

    const userDetailsByIdUrl = `https://${window.auth0Domain}/api/v2/users/${user.sub}`;

    const metadataResponse = await fetch(userDetailsByIdUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    //Don't save user metadata as we've been rate limited... exit so we try again later/handle simultaneous requests
    if (metadataResponse.status === 429) {
      return;
    }

    const { user_metadata } = await metadataResponse.json();

    if (user_metadata){
      localStorage.setItem(
        `userMetadata-${user.sub}`,
        JSON.stringify(user_metadata)
      );
      setUserMetadata(user_metadata);

      return;
    }

    localStorage.setItem(
      `userMetadata-${user.sub}`,
      '{}'
    );
  } catch (e) {
    console.error(e);
  }

};

export default getUserMetadata;

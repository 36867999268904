const page5Data = {
  id: '232cc661-6e58-47b2-a20d-adfeeff79e5a',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content quote',
      text: '“Culture is simply a shared way of doing something with a passion” ',
    },
    {
      class: 'content',
      text: 'Immersing ourselves in the culture of an organisation is a really important aspect of the 90-day onboarding journey, explore the missions and resources below to continue your discovery. ',
    },
    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: 'c87b7537-cace-48e6-8863-5624d467653b',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: 'c2fb9e0e-f7a1-434b-b51e-bd2d257b314a',
          name: 'Read an article on culture',
          heading: 'Read',
          link: 'https://hbr.org/2018/03/when-you-start-a-new-job-pay-attention-to-these-5-aspects-of-company-culture',
          linkText: 'Read more',
          class: 'article-resource',
        },
        {
          id: 'df53074e-be58-47c3-8a41-ea8d6ca2c3fb',
          name: 'Read an article about Intrapreneurship',
          heading: 'Read',
          link: 'https://mitsloan.mit.edu/ideas-made-to-matter/intrapreneurship-explained#:~:text=What%20is%20intrapreneurship,or%20even%20a%20subsidiary%20spinoff.',
          linkText: 'Read more',
          class: 'video-resource',
        },
        {
          id: 'fc66c04d-51b1-4d6a-95d5-7c65635cf4c4',
          name: 'Watch a video on a successful culture',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/reed_hastings_3_secrets_to_netflix_s_success',
          linkText: 'Watch now',
          class: 'article-resource',
        },
      ],
    },
  ],
  responseType: [
    {
      id: 'a18cd600-c6c9-4acd-85d9-73a63fa4edde',
      name: 'freeText',
      class: 'missions',
      guid: '50aefd33-f856-4015-af23-7a7de06f4368',
      questionHeader: 'Mission 1',
      questionText: 'Fill your knowledge gaps.',
      questions: [
        {
          1: 'Think about the gaps in your knowledge you identified and try at least two different ways to fill these gaps. This could be through research, connecting with others, asking questions etc. Record what you tried and the outcome.',
        },
      ],
    },
    {
      id: 'afd3cd51-3d2a-413c-a8da-17b0e6e4553a',
      name: 'freeText',
      class: 'missions',
      guid: '9f093212-4a2b-4fdf-b582-65632070a2de',
      questionHeader: 'Mission 2',
      questionText: 'Notice (culture) norms.',
      questions: [
        {
          1: 'In your next team meeting, look out for any commonly held beliefs, opinions or behaviours that may be apparent. Record your observations below. ',
        },
      ],
    },
    {
      id: 'c8a98f08-e964-49cc-9063-b24ea14d433c',
      name: 'freeText',
      class: 'missions',
      guid: '49a0dcd6-f9dc-48b6-9c8c-e5c9218ea236',
      questionHeader: 'Mission 3',
      questionText: 'Build your culture understanding. ',
      questions: [
        {
          1: 'Find a company-wide webinar, event or group to join to gain more exposure to different aspects of the company culture. This could be an employee resource group or network, a webinar on a successful project or business area or a networking event. Record what you observe. ',
        },
      ],
    },
  ],
};

module.exports = page5Data;

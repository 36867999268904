const headingClass = 'video-capture-heading';
const quoteClass = 'video-capture-quote';
const page3Data = {
  id: '60f9036b-c6cb-4413-83c5-8dae6355ba0e',
  number: '3',
  title: 'Understand: what influences our brand?',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Great performance on its own doesn’t make a strong brand.',
    },
    {
      class: 'content',
      text: 'Research suggests that image and exposure have a greater influence over our personal brand than we realise. A strong performance that no one sees will have little influence on our brand.',
    },
    {
      class: 'content bold',
      text: 'Explore the image showing three things to be aware of that influence your brand – performance, image and exposure',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/what-makes-a-strong-brand_PIE.png',
        alt: 'What makes a strong brand',
      },
    },

    { class: headingClass, text: 'Performance (10%)' },
    {
      class: quoteClass,
      text: 'whilst seen as less important, it is still a basic fundamental required for all tasks.',
    },
    { class: headingClass, text: 'Image (30%)' },
    {
      class: quoteClass,
      text: 'how others see us and the brand we are choosing to show to others.',
    },

    { class: headingClass, text: 'Exposure (60%)' },
    {
      class: quoteClass,
      text: 'has the most influence on our brand and refers to the reputation we have.',
    },

    {
      class: 'content',
      text: 'You cannot create a brand that everyone will love or align with, but you can ensure that you are acting authentically and intentionally, with integrity. ',
    },

    {
      class: 'content',
      text: 'Others can sense if you are not being authentic – for example, if you are naturally more introverted being a serial networker and connecting with others regularly, might not be an authentic brand for you.',
    },

    {
      class: 'content question',
      text: 'To explore what actions you could take to authentically build your own brand, sort the example actions into the relevant categories for you.',
    },
  ],

  afterResponse: [''],
  action: [
    'Ask your team for their perspective on what other actions you could take to gain exposure and build your personal brand.',
  ],
  responseType: [
    {
      id: '2',
      name: 'bucketSort',
      guid: '64514e59-419f-4cb2-819a-49b4744ceb34',
      questionText: '',
      categories: ['Actions I could take', 'It’s just not me'],
      feedback: {
        correctFeedback: '',
        incorrectFeedback: '',
        defaultFeedback:
          'Great! There is no right or wrong answer here, just an opportunity to explore a few ideas for what you could do to build your brand. Although some of these ideas will be key deliverables for your role, it doesn’t define your personal brand.',
      },
      listItems: [
        {
          id: 'b888474d-6675-4f95-a3ef-2830434ea56a',
          name: 'A',
          text: 'Sharing articles or posting blogs',
        },
        {
          id: '5fbce6e3-3b2f-4640-a838-ecd4b56309a4',
          name: 'B',
          text: 'Engaging with online content',
        },
        {
          id: '40920970-81a4-4c33-8b95-55369ba5ea66',
          name: 'C',
          text: 'Attending events – virtually or in person',
        },
        {
          id: '32a00132-f8d4-4d9a-bee3-e16be2fab118',
          name: 'D',
          text: 'Turning on your webcam',
        },
        {
          id: 'c10322ec-3db0-4b50-91bd-635bdfa5feaa',
          name: 'E',
          text: 'Speaking on team calls',
        },
        {
          id: '6f64045d-d6a3-4637-86a5-c990491338ee',
          name: 'F',
          text: 'Following up with questions when appropriate',
        },
        {
          id: '54cc567a-e5e0-47dc-b3b2-411b6643f094',
          name: 'G',
          text: 'Joining employee groups or clubs',
        },
        {
          id: '21028335-480f-4ace-989f-ad3dee8d1dc4',
          name: 'H',
          text: 'Connecting people with others',
        },
        {
          id: '37d354f8-4e48-409b-9b35-53a103e1ea6a',
          name: 'I',
          text: 'Sharing your learning',
        },
        {
          id: '51d9e7c3-2535-4502-a39e-3b16ab94d807',
          name: 'J',
          text: 'Setting up opportunities to connect',
        },
      ],
    },
  ],
};

module.exports = page3Data;

import React from 'react';

export const MultipleChoiceOption = ({value, onClick, clicked}) => {
    
    const isSelected = clicked.includes(value);
    if (clicked.includes(value)) {
       
        return (

            <div  className={`multiple-choice-answers-individual ${isSelected ? 'selected' : ''}`} style={{border: "2px blue solid"}} onClick={onClick} id={value}>{value}</div>
        )
    } else {
        return (

            <div className='multiple-choice-answers-individual' onClick={onClick} id={value}>{value}</div>
        )
    }
}

export default MultipleChoiceOption;



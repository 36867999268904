const page2Data = {
  id: '00e3f844-0b21-4fa3-afb7-60d91f6f91ca',
  number: '2',
  title: 'Uncover: why first impressions are important',
  resourceType: [],
  content: [
    {
      class: 'content quote',
      text: '“You never get a second chance to make a first impression”',
    },
    {
      class: 'content',
      text: 'Whilst old, this saying still holds true. Research shows we tend to make a judgement about someone within the first 7 seconds of meeting them. To succeed in our first 90 days, we need to make a great first impression. To do this, we can prepare…',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'Reflect on what strengths, experiences or motivations make you unique and stand out from the crowd.',
  ],
  responseType: [
    {
      id: '93565d10-58ac-426b-afbc-45767acf6fd5',
      name: 'freeText',
      class: 'missions',
      guid: '1055ae36-be1c-41c4-ab6e-d11559ecaeac',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: '<div class="question-list">Make a list of what you would like people to know about you in the categories below<br/><br /><ul><li>Strengths and values</li><li>Preferences</li><li>Experience and background</li></ul></div>',
        },
      ],
    },
  ],
};

module.exports = page2Data;

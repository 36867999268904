const page1Data = {
  id: '9f00a933-10f4-4368-9be8-7c1fbfe4fe8b',
  number: '1',
  title: 'Reflect',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Before you can complete this phase, use the checklist below to make sure you have done everything you need to',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: 'd388e6d6-937f-4dd9-8fc0-d05e341b329e',
      name: 'SHARE YOUR FEEDBACK',
      items: [
        {
          id: 'a9274b09-a371-45de-a34c-c0b623f58c99',
          name: 'Please take this quick survey to share your thoughts on this phase',
          heading: '',
          link: 'https://survey.alchemer.eu/s3/90601521/90-Day-Toolkit-Phase-2',
          linkText: 'Take survey',
          class: 'article-resource survey',
        },
      ],
    },
  ],
  responseType: [
    {
      id: 'a7f4cba0-2b84-431a-97e9-f3f232d70532',
      name: 'checklist',
      guid: '695b4a76-9e94-4ed9-848c-eeb313b906fe',
      questionText: '',
      questions: [{}],
      list: {
        options: {
          1: 'I have tried at least one mission',
          2: 'I understand the change journey I am on',
          3: 'I know how to craft strong learning goals',
          4: 'I have started to consciously craft my brand',
          5: 'I understand how to develop my network',
          6: 'I have connected with someone new',
          7: 'I have taken some time to reflect on my learning',
        },
        prompt: '',
        textComplete: 'Nicely done! Continue with your reflections.',
        textIncomplete:
          'Take a look at the areas you have checked off and consider revisiting these sections to ensure you get the most from this toolkit. ',
      },
    },
    {
      id: '336f437d-e0e1-4bb1-bff8-b41176ee3db5',
      name: 'freeText',
      class: 'missions',
      guid: 'ac4730be-01fe-4c20-882d-02097d404175',
      questionText:
        '',
      questions: [
        {
          1: 'Who was the most interesting or useful person you met during the last 30-days and why?',
        },
      ],
    },
    {
      id: 'c2f22869-1fe8-4de1-976e-af30a6e7bd9d',
      name: 'freeText',
      class: 'missions',
      guid: 'e79f5647-50d0-4df5-819d-80fd204a60fd',
      questionText:
        '',
      questions: [
        {
          1: 'What’s been your biggest takeaway or learning from your second month in your new role?',
        },
      ],
    },
  ],
};

module.exports = page1Data;

const page1Data = {
  id: '58239beb-7f9e-4cc2-90d5-088253c165dd',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: 'eab4270b-96ee-489e-ba33-f351108405a6',
      name: 'image',
      imageUrl: 'https://apifiles.cappassessments.com/90DTK/phase1-module2-2.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will discover your environment – exploring the aspects of organisational culture and norms.',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],

  responseType: [
    {
      type: 'content',
      text: 'Click "Next" to get started.',
    },
  ],
};

module.exports = page1Data;

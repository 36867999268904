const page5Data = {
  id: '74ff4197-e1b8-49ae-85ea-3ad10a76092b',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: 'a7454fb4-6cbc-4a0e-9b14-610a0a9964ee',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: '97d5864d-7482-43a9-b4af-338459aefa9b',
          name: 'Read an article about ways to introduce yourself',
          heading: 'Learn',
          link: 'https://hbr.org/2022/08/a-simple-way-to-introduce-yourself',
          linkText: 'Read more',
          class: 'article-resource',
        },
        {
          id: 'b77118db-85f6-472c-a55c-6b70a07efcb3',
          name: 'Listen to a podcast on making a great first impression',
          heading: 'Watch',
          link: 'https://howtoacademy.com/podcasts/malcolm-gladwell-how-to-make-a-good-first-impression/',
          linkText: 'Listen now',
          class: 'video-resource',
        },
        {
          id: '24bd26ba-5cc8-4431-aa31-dada0f65b9a2',
          name: 'Watch a video on the 5 second rule of first impressions',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/quita_christison_the_new_5_second_rule_redefining_the_first_impression',
          linkText: 'Watch now',
          class: 'article-resource',
        },
      ],
    },
  ],
  responseType: [
    {
      id: '0e99cac1-91b9-44a3-80d4-5811ada8b1a4',
      name: 'freeText',
      class: 'missions',
      guid: '458a3e6e-4bc2-440d-93c8-f36e2abcf5d9',
      questionHeader: 'Mission 1',
      questionText: 'Ask for feedback on your intro',
      questions: [
        {
          1: 'Send your downloaded introduction to three different people for feedback on what you say and how you come across. Record the feedback below and any changes you plan to make. ',
        },
      ],
    },
    {
      id: '67091f7d-bcd4-4796-a64b-056b547fa19b',
      name: 'freeText',
      class: 'missions',
      guid: 'c2da75e7-2a6c-4d1b-94b7-fffa8d273dd3',
      questionHeader: 'Mission 2',
      questionText: 'Watch your (body) language',
      questions: [
        {
          1: 'In your next meeting, notice your own body language and any habits you may have. For example, do you cross your arms, stare out the window, maintain eye contact etc. Record what you notice and how this could impact others impression of you.',
        },
      ],
    },
    {
      id: 'c6c37129-4e97-47f5-b59e-9386c58ff6ce',
      name: 'freeText',
      class: 'missions',
      guid: 'ae3d0306-6911-4cae-9dd8-7fdb73236e7a',
      questionHeader: 'Mission 3',
      questionText: 'Flex your introduction',
      questions: [
        {
          1: 'Introduce yourself in different ways when you meet new people – highlighting a different strength, experience or skill each time. Record the reactions or questions you get.',
        },
      ],
    },
  ],
};

module.exports = page5Data;

const page2Data = {
  id: 'cdbfc018-8472-4f1c-9d98-fbb6657e9916',
  number: '2',
  title: 'Uncover: what is networking?',
  content: [
    {
      class: 'content',
      text: 'Networking is a process of connecting with and building professional relationships with others – both inside and outside your workplace or related field. It is something you do naturally day to day, and more frequently virtually. ',
    },
    {
      class: 'content',
      text: 'It can also be a source of anxiety for many people, whilst for others it comes naturally. As forming connections is an important part of any career, we need to observe and find ways to make it work for us. ',
    },
  ],

  afterResponse: [''],
  action: [
    'If you find networking or meeting new people difficult, try arranging to show up to a meeting or event with a colleague, rather than on your own.',
  ],
  resourceType: [],
  responseType: [
    {
      id: 'fc1fd93a-099c-4d0b-8671-a464e574ee7a',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: '92199c90-319f-4a8f-8ff4-600428f4816f',
      questionText: '',
      questions: [
        {
          questions: {
            1: 'Which statement best describes how you feel about networking?',
          },
          responses: {
            1: [
              'Building deep connections with people you work with regularly.',
              'Interacting with people across different industries.',
              'Something extroverts and salespeople do.',
            ],
          },
          feedback: {
            individualFeedback: {
              1: [
                'Great - you may have a strength of relationship deepener and prefer meeting people in smaller groups or on an individual basis.',
                'Great - you may be a strong serial networker and love meeting loads of new people.',
                'This is a commonly held belief, but regardless of if we are an extrovert or introvert, we can all find authentic ways of creating connections at work in line with our strengths.',
              ],
            },
          },
        },
      ],
    },
  ],
};

module.exports = page2Data;

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import React, { useState, useEffect } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import { Button } from 'react-bootstrap';
import { setQuestionComplete } from '../../api/setQuestionComplete';
import {
  setApiStepComplete,
  getQuestionByUniqueId,
} from '@capp.micro/shared/api';
import Feedback from '../feedback/feedback';

const checkAnswers = (questions, responseToCheck) => {
  if (questions[0].feedback) {
    if (questions[0].feedback[1]) {
      if (
        parseInt(responseToCheck.answer) >= questions[0].feedback[1].range[0] &&
        parseInt(responseToCheck.answer) <= questions[0].feedback[1].range[1]
      ) {
        responseToCheck.feedback = questions[0].feedback[1].text;
        return responseToCheck;
      } else {
        responseToCheck.feedback = questions[0].feedback[2].text;
        return responseToCheck;
      }
    } else {
      responseToCheck.feedback = questions[0].feedback.defaultFeedback;
      return responseToCheck;
    }
  } else {
    return responseToCheck;
  }
};

export const SliderQuestion = ({
  questions, setUserMetadata, user, questionText, brand, guid, getAccessTokenSilently,
}) => {
  const [value, setValue] = useState(questions[0].startingValue);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [responses, setResponses] = useState([]);

  const [previouslySavedResponse, setPreviouslySavedResponse] = useState(null);

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const response = await getQuestionByUniqueId(brand, guid, getAccessTokenSilently);
        if (response[0].question) {
          return setPreviouslySavedResponse(response);
        } else {
          return setPreviouslySavedResponse(null);
        }
      } catch (error) {
        return 'Error fetching question: ' + error;
      }
    }
    if (questions[0].showPrevious) {
      fetchQuestion();
    }
  }, []);

  const showNav = () => {
    return currentQuestion === questions[0].questions.length - 1 ? (
      <div>
        <Button onClick={() => handleClick('submit')} id="slider-next">
          {' '}
          Submit
        </Button>
      </div>
    ) : (
      <div>
        <Button onClick={() => handleClick('next')} id="slider-next">
          {' '}
          Next
        </Button>
      </div>
    );
  };

  const showFunction = (displayResponses) => {
    if (questions.length > 0 && questions[0].questions.length > 0) {
      if (currentQuestion < questions[0].questions.length) {
        return showMainFunction();
      } else {
        return displayResponses(responses);
      }
    } else {
      return <div>No questions to display</div>;
    }
  };

  const showMainFunction = () => {
    return (
      <>
        <div className="slider-header">
          <div className="slider-questions">
            Question {currentQuestion + 1} of{' '}
            {Object.keys(questions[0].questions).length}{' '}
          </div>
          <div className="slider-prompt">
            Please drag the slider to any position along the scale. You may drag
            the slider to any position you like, but please make sure that you
            either click on it or move it from its starting position first.
          </div>
          <div className="slider-questions-individual">
            {questions[0].questions[currentQuestion]}
          </div>
        </div>
        <RangeSlider
          value={value}
          onChange={(e) => setValue(e.target.value)}
          step={questions[0].increments}
          tooltip={
            'auto'
          }
          min={questions[0].min}
          max={questions[0].max}
        />
        <div className="slider-footer">
          <div className="slider-categories">
            {Object.keys(questions[0].categories).length !== 0
              ? Object.entries(questions[0].categories).map((category) => category[1].length > 0 ? (
                <div
                  className="slider-categories-individual"
                  key={questions[category]}
                >
                  {category.pop()}
                </div>
              ) : (''))
              : ''}
          </div>
          <div className="slider-nav">
            <div>
              <Button onClick={() => handleClick('back')} id="slider-back">
                Back to previous question
              </Button>
            </div>

            {showNav()}
          </div>
        </div>
      </>
    );
  };

  const showResponses = (responsesToShow) => {
    console.log(questions[0].showFeedback)
    if (questions[0].showFeedback === true){
    const feedbackMapped = responsesToShow.map((response, index) => {
      if (questions[0].feedback[1]) {
        return (
          <div key={response.answer}>
            {Object.keys(questions[0].feedback).map((object) => {
              if (
                parseInt(response.answer) >=
                  questions[0].feedback[object].range[0] &&
                parseInt(response.answer) <=
                  questions[0].feedback[object].range[1]
              ) {
                return (
                  <>
            <div key={response.answer} style={{margin: '20px 0px'}}>
     
              <div>Question {index + 1}: {response.question} <br />You responded: {response.answer}%</div>
            </div>
            {questions[0].feedback[object].text}
          </>
                 
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      } else {
        return (
          <>
            <div key={response.answer} style={{margin: '20px 0px'}}>
     
              <div>Question {index + 1}: {response.question} <br />You responded: {response.answer && response.answer !== response.value ? `${response.answer} (${response.value})` : response.value}</div>
            </div>
            {response.feedback}
          </>
        );
      }
    });
  
    return <Feedback feedbackText={feedbackMapped} />;}
    else {setCurrentQuestion((prevQuestion) => prevQuestion - 1);return showMainFunction()}
  };

  const handleClick = (action) => {
    const addResponse = () => {
      const newResponse = {
        question: questions[0].questions[currentQuestion],
        answer:
          Object.keys(questions[0].categories).length !== 0
            ? questions[0].categories[value]
            : value,
        value: value,
      };
      checkAnswers(questions, newResponse);
      return [...responses, newResponse];
    };

    if (action === 'back' && currentQuestion !== 0) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
      setResponses((prevResponses) => prevResponses.slice(0, -1));
      setValue(questions[0].startingValue);
    } else if (
      action === 'next' &&
      currentQuestion < questions[0].questions.length
    ) {
      const newResponses = addResponse();
      setResponses(newResponses);

      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      setValue(questions[0].startingValue);
    } else if (action === 'submit') {
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      const answers = addResponse();
      setResponses(answers);
      setQuestionComplete(questionText,brand,guid,getAccessTokenSilently,answers
      );
      setApiStepComplete(`${guid} - Complete`,brand,user,getAccessTokenSilently,setUserMetadata
      );
    } else {
      return;
    }
  };

  return (
    <div className="slider-function">
      {previouslySavedResponse ? (
        showResponses(previouslySavedResponse)
      ) : (
        showFunction(showResponses)
      )}
    </div>
  );
};

export default SliderQuestion;

const headingClass = 'video-capture-heading';
const quoteClass = 'video-capture-quote';
const page4Data = {
  id: '31b8ea6d-8e14-4ba5-b116-0668ffbe055f',
  number: '4',
  title: 'Reflect: your goal hierarchy',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Goals don’t always follow a linear path and exist in a hierarchy. As we gather new experiences and try out new things, it’s important to be prepared to divert or modify.',
    },
    {
      class: 'content',
      text: 'According to Angela Duckworth, successfully sticking to and completing a goal requires grit, passion, perseverance and agility. As well as breaking them down into manageable steps – still seeing the big picture, but not trying to jump too far at once or lose momentum. To do this, we can use the hierarchy of goals.',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/goal-hierarchy-example.png',
        alt: 'Gaol hierarchy',
      },
    },

    { class: headingClass, text: 'Top-level goal' },
    {
      class: quoteClass,
      text: 'Your ultimate goal. The main goal you are aligned to and stay focused on achieving.',
    },
    { class: headingClass, text: 'Mid-level goal' },
    {
      class: quoteClass,
      text: 'Potentially more challenging or stretching actions or tasks than those in your low-level goals, but ultimately are still steps towards achieving your ultimate goal.',
    },
    { class: headingClass, text: 'Low-level goal' },
    {
      class: quoteClass,
      text: 'Specific tasks or actions to help you get started on the path to achieving your ultimate goal - if they don’t work, switch them out and try something new.',
    },
    {
      class: 'content',
      text: 'Think about a goal you want to achieve – this could be anything from learning to play the piano, to building a strong network at work. Plot your high-level goal and the low and mid-level actions or tasks you could take to achieve it.',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'Successfully achieving our goals also means developing strong habits to keep moving forward – schedule regular time in your diary to commit moving forward with your goal.',
  ],
  responseType: [
    {
      id: '234235',
      name: 'freeText',
      class: 'missions',
      guid: 'fa38d0e1-ad3c-4684-acb5-c6d581cfc84b',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: 'High level goal',
        },
      ],
    },
    {
      id: '234asda235',
      name: 'freeText',
      class: 'missions',
      guid: 'c8caf644-eb29-4671-8123-535029ceb54f',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: 'Mid-level goals',
        },
      ],
    },
    {
      id: '234asda2355756',
      name: 'freeText',
      class: 'missions',
      guid: 'c3833e7f-37be-4839-ad32-6a214e29dda3',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: 'Low-level goals',
        },
      ],
    },
  ],
};

module.exports = page4Data;

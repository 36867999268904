const page4Data = {
  id: 'e43708ab-723f-423c-b17d-5dda1b8a8e07',
  number: '4',
  title: 'Reflect: your achievements and ‘firsts’',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Take a moment to review your three most memorable new experiences or ‘firsts’. Remember the power of small wins, your ‘firsts’ don’t have to be monumental award-winning achievements.',
    },

    { class: 'content bold', text: 'These could include:' },

    {
      list: [
        'The first challenge you overcame in your new role',
        'The first networking event you attended',
        'Achieving your first professional goal',
        'The first target or milestone hit',
        'The first decision you felt confident making on your own',
        'The first solo presentation you gave',
        'The first question you asked in a team meeting',
      ],
    },

    {
      class: 'content',
      text: 'These ‘firsts’ ensure we continually discover, develop and stretch whatever role we are doing. Distractions, busyness and discomfort are common reasons we stop pushing ourselves to learn and try new things outside our day-to-day responsibilities.',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'Share your mantra with others to stay accountable for your actions.',
  ],
  responseType: [
    {
      id: '511ed9ea-4ab4-4d15-bb08-d53b6d22f4fa',
      name: 'freeText',
      class: 'missions',
      guid: '13b53f38-8e08-4807-abc0-75a9ea307d9c',
      questionText: '#1',
      questions: [
        {
          1: '',
        },
      ],
    },
    {
      id: 'c1292c8d-015b-4bd1-bfaf-f5cc5d6950cf',
      name: 'freeText',
      class: 'missions',
      guid: 'aae1d4d8-22a4-4963-a253-06eae2a93977',
      questionText: '#2',
      questions: [
        {
          1: '',
        },
      ],
    },
    {
      id: 'c42fd5f4-51d5-414e-91fc-5930fcea6491',
      name: 'freeText',
      class: 'missions',
      guid: '0695d391-529e-4013-b54e-2944dd6d847a',
      questionText: '#3',
      questions: [
        {
          1: '',
        },
      ],
    },

    {
      id: 'a2b89a95-494a-4c02-ab90-f48b048bc93e',
      name: 'freeText',
      class: 'missions',
      guid: '4f77c041-d3e1-469c-9f3d-cdebc42bd992',
      questionText:
        'Create your own mantra or quote to help keep you motivated to continue trying new things. For example, ‘I will maintain a growth mindset in everything I do’; ‘There is always room to learn and grow’.',
      questions: [
        {
          1: 'My mantra is… ',
        },
      ],
    },
  ],
};

module.exports = page4Data;

const contentBold = 'content bold';
const goodQuestion = 'Good questions';
const notGoodQuestion = 'Not so good questions';
const page3Data = {
  id: '41917192-c311-4b0d-97a9-d228c1d1a9b6',
  number: '3',
  title: 'Understand: how to get ‘good’ feedback',
  resourceType: [],
  content: [
    { class: contentBold, text: 'Feedback is…' },

    {
      class: 'content',
      text: '…the process of action, reflection and learning',
    },

    { class: 'content', text: '…for both praise and improvement' },

    {
      class: 'content',
      text: '…internal reflection and gathering perspectives from others',
    },

    {
      class: 'content',
      text: '…a mechanism to understand our impact on others',
    },

    { class: 'content', text: '…a tool to help modify our behaviours' },

    {
      class: 'content',
      text: 'Although feedback is often seen as a negative, it is critical for our ongoing growth and development at work. By understanding how others see us, we can better modify our behaviour to get support from others.',
    },

    { class: contentBold, text: 'Feedback process' },

    {
      class: 'content',
      text: 'There is a simple process for feedback you can follow – act, reflect, learn. Explore the image below to find out more.',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/model-feedback.png',
        alt: 'Model feedback',
      },
    },

    { class: contentBold, text: 'Action' },

    { class: 'content', text: 'Complete the task' },

    { class: contentBold, text: 'Reflect' },

    {
      class: 'content',
      text: 'Internal reflection or asking others for feedback',
    },

    { class: contentBold, text: 'Learn' },

    { class: 'content', text: 'Using the insights gathered to improve' },

    { class: contentBold, text: 'Feedback questions' },

    {
      class: 'content',
      text: 'To continue to learn and grow at work, we need to ask for continuous feedback from multiple sources – including our own internal reflection.',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'Start demonstrating a curious growth mindset now - send a feedback request using the ‘good’ questions and see what you get back.',
  ],
  responseType: [
    {
      id: '8543d0fe-fff5-4604-89b7-01ea1b5c0764',
      name: 'bucketSort',
      guid: '0f712a50-01fd-4014-9e9b-7b8c0d1c4a0a',
      questionText:
        'Sort the questions below by whether you think they are good questions or not so good questions.',
      categories: [goodQuestion, notGoodQuestion],
      feedback: {
        correctFeedback:
          'You’ve got it! Asking more open and curious questions ensures you get as much information as possible.',
        incorrectFeedback:
          'Not quite - good feedback questions should be open and curious, rather than closed and specific.',
        defaultFeedback: 'Move on to the next question',
      },
      listItems: [
        {
          id: '1',
          name: 'A',
          text: 'What could I do differently next time?',
          correctAnswer: goodQuestion,
        },
        {
          id: '2',
          name: 'B',
          text: 'What skills do you think I should continue developing?',
          correctAnswer: goodQuestion,
        },
        {
          id: '3',
          name: 'C',
          text: 'In your opinion, where are my areas for improvement?',
          correctAnswer: goodQuestion,
        },
        {
          id: '4',
          name: 'D',
          text: 'What could I start, stop and continue doing?',
          correctAnswer: goodQuestion,
        },
        {
          id: '5',
          name: 'E',
          text: 'Why did you give me that feedback?',
          correctAnswer: notGoodQuestion,
        },
        {
          id: '6',
          name: 'F',
          text: 'What did I do wrong?',
          correctAnswer: notGoodQuestion,
        },
      ],
    },
  ],
};

module.exports = page3Data;

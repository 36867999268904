export const getApiUserMetadata = async (
  user,
  getAccessTokenSilently,
  setUserMetadata
) => {
  try {
    if (user) {
      const cachedUserMetadata = localStorage.getItem(
        `userMetadata-${user.sub}`
      );
      if (cachedUserMetadata) {
        setUserMetadata(JSON.parse(cachedUserMetadata));
      } else {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `${window.apiAudience}`,
            scope: '',
          },
        });
        const userDetailsByIdUrl = `${window.apiBaseUrl}/account/getusermetadata`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`, //TODO: add tenant id header here... not needed for now
          },
        });

        if (metadataResponse.status === 200){
          const userMetadata = await metadataResponse.json();

          localStorage.setItem(
            `userMetadata-${user.sub}`,
            JSON.stringify(userMetadata)
          );
          setUserMetadata(userMetadata);
        }
      }
    }
  } catch (e) {
    console.error(e);
  }

  return null; //TODO: dimensions.current
};

export default getApiUserMetadata;

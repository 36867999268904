const page1Data = {
  id: '19537bed-c30f-4f8a-8c70-aed63059024d',
  number: '1',
  title: 'Reflect',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Before you can complete this phase, use the checklist below to make sure you have done everything you need to',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: 'a7454fb4-6cbc-4a0e-9b14-610a0a9964ee',
      name: 'SHARE YOUR FEEDBACK',
      items: [
        {
          id: '97d5864d-7482-43a9-b4af-338459aefa9b',
          name: 'Please take this quick survey to share your thoughts on this phase',
          heading: '',
          link: 'https://survey.alchemer.eu/s3/90601507/90-Day-Toolkit-Phase-1',
          linkText: 'Take survey',
          class: 'article-resource survey',
        },
      ],
    },
  ],
  responseType: [
    {
      id: '0cc79739-69f8-402d-b07b-b7dca16e5444',
      name: 'checklist',
      guid: 'd4bdaea8-1190-4652-aa56-9d9b449c5c81',
      questionText: '',
      questions: [{}],
      list: {
        options: {
          1: 'I have tried at least one mission',
          2: 'I am now confident in knowing my strengths',
          3: 'I know how to find out more about the culture',
          4: 'I have started building my support networks',
          5: 'I know how to make a good first impression',
          6: 'I have discussed my strengths and support needed with my manager',
          7: 'I have taken some time to reflect on my learning',
        },
        prompt: '',
        textComplete: 'Nicely done! Continue with your reflections.',
        textIncomplete:
          'Take a look at the areas you have checked off and consider revisiting these sections to ensure you get the most from this toolkit.',
      },
    },
    {
      id: '66bfb207-2676-418b-b458-c30ed3f5b242',
      name: 'freeText',
      class: 'missions',
      guid: 'a9cb2f34-e90b-4607-baca-1115666052f0',
      questionText: '',
      questions: [
        {
          1: 'What have you learned about yourself?',
        },
      ],
    },
    {
      id: '5c8bd209-15f0-417e-a496-30b4e22c494d',
      name: 'freeText',
      class: 'missions',
      guid: '2f95fcdf-5d99-4d14-8bf8-404266c9b98b',
      questionText:
        "",
      questions: [
        {
          1: "If you had to choose, what's been your biggest takeaway from your first 30 days?",
        },
      ],
    },
  ],
};

module.exports = page1Data;

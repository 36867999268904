const page4Data = {
  id: 'a079d58b-8577-4eb6-a56c-883d6da0ab57',
  number: '4',
  title: 'Reflect: your change journey(s)',
  resourceType: [
    {
      id: '10ee6ab2-c1d4-44d1-80a0-5e5d5fd4df5f',
      name: 'image',
      imageUrl:
        'https://apifiles.cappassessments.com/90DTK/90DTK-Change-curve.png',
    },
  ],
  content: [{}],
  time: '',
  afterResponse: [''],
  action: [
    'Gather perspectives - ask your colleagues about their first work experience and one piece of advice they would give to navigate change',
  ],
  responseType: [
    {
      id: '5',
      name: 'freeText',
      class: 'missions',
      guid: '0c410d34-28ca-46ef-b2cd-dcc421a675b0',
      questionText:
        '',
      questions: [
        {
          1: 'Explore the image above. Think about your experiences of change over the last 60-days – what journeys have you gone through, or are still going through? Reflect on one journey using the image above. ',
        },
      ],
    },
  ],
};

module.exports = page4Data;

import React, {useEffect} from 'react';
import { setApiStepComplete } from '@capp.micro/shared/api';



export const VideoContent = ({ id, brand, user, setUserMetadata, getAccessTokenSilently, videoUrl, text }) => {

  useEffect(() => {setApiStepComplete(`${id} - Complete`, brand, user, getAccessTokenSilently, setUserMetadata)}, [])

  return (
    <div>
      <video width="320" height="240" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <p>{text}</p>
    </div>
  );
}

export default VideoContent;

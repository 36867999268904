const page2Data = {
  id: 'b2dc0fb3-475a-4c9b-904e-1eca0509d3b0',
  number: '2',
  title: 'Uncover: your mindset',
  content: [
    {
      class: 'content',
      text: 'During the first 90-days in your role, there will be a lot of new things to learn such as processes, people, ways of working and new technical knowledge. Setting goals supports this continuous learning both on the job and outside, and our success is dependent on the mindset we have.',
    },
    {
      class: 'content bold',
      text: 'Look at the scenarios below and consider what you would do…',
    },
    {
      class: 'content',
      text: 'You have one year to train to peak condition for a marathon. Your target is to do 4 hours training a week. This week, an injury meant you missed your weekly training sessions.',
    },
    {
      class: 'content question',
      text: 'Based on this information, use the slider to select how successful you have been with your training (0% - not at all successful to 100% - completely successful).',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'Looking at the bigger picture, rather than focusing on specific instances or events, can help to maintain a growth mindset.',
  ],
  resourceType: [],
  responseType: [
    {
      id: '32237577885',
      name: 'sliderQuestion',
      class: 'slider-container',
      guid: 'b3f4c1b6-7a53-49c0-b650-52148fef47cb',
      questionText: '',
      questions: [
        {
          questions: ['How successful have you been with your training?'],
          categories: {},
          max: 100,
          min: 0,
          startingValue: 50,
          increments: 10,
          showPrevious: true,
          showFeedback: true,
          feedback: {
            1: {
              range: [0, 40],
              text: 'How you answered this gives an interesting insight into your mindset – giving a 0-40% success rate on this scenario may mean you are more inclined towards a fixed mindset in this situation. Continue to explore what this means…',
            },
            2: {
              range: [50, 100],
              text: 'How you answered this gives an interesting insight into your mindset – giving a 50-100% success rate on this scenario may mean you are more inclined towards a growth mindset in this situation. Continue to explore what this means… ',
            },
          },
        },
      ],
    },
  ],
};

module.exports = page2Data;

const page1Data = {
  id: '92124d46-0d43-4b28-bff4-9556f4c350bd',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: '052e071e-35bb-4b14-9727-dc5ddd9a032a',
      name: 'image',
      imageUrl: 'https://apifiles.cappassessments.com/90DTK/phase3-module1-2.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will focus on your achievements so far, finding new ways to stretch yourself through different experiences and overcoming challenges. ',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [
    {
      type: 'content',
      text: 'Click "Next" to get started.',
    },
  ],
};

module.exports = page1Data;

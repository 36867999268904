import React from "react"
import { Col, Container, Row, } from "react-bootstrap"
import {motion} from "framer-motion"

export function SidebarCardAlt(props) {

  return (
    
    <Container className={props.class}>
    
            <Row>
                <Col xs={12} lg={4} style={{padding: "0"}}>
                   <div className="card-image"></div>
                </Col>
                <Col xs={12} lg={8} className="card-text">
                    <h2>{props.heading}</h2>
                    <p>{props.text}</p>
                    <motion.button
                      disabled={props.disabled}
                      className="btn btn-primary"
                      variant="primary"
                      whileHover={props.hover}
                      whileTap={props.tap}
                      onClick={(e) => {
                        window.location.href = props.link;
                        e.target.disabled = "true";
                      }} >
                      {props.buttonText}
                  </motion.button>
                </Col>
            </Row>
        </Container>
  
  )
}


export default SidebarCardAlt

const page6Data = {
  id: '24f3e112-28e4-46cf-8345-e34b58e484b5',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Answer the question below to test your knowledge. Click "Complete Module" when you are finished and choose another topic to explore.',
    },
  ],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      id: 'f9eefac3-1b09-45ed-a751-2c4f2b253db7',
      name: 'quiz',
      class: 'end-quiz',
      guid: 'ab6cd75b-258b-44a8-b344-5ee66d7fc661',
      questionText: '',
      questions: [
        {
          question:
            'According to Robin Dunbar, what is the optimal size of your wider network?',
          answers: ['12-15', '150', '1000'],
          showPrevious: true,
          showFeedback: true,
          correctAnswer: '150',
          feedback: {
            correctFeedback:
              'Correct! According to Robin Dunbar, the optimal network size is around 150 people.',
            incorrectFeedback:
              'Not quite, according to Robin Dunbar, the optimal network size is around 150 people, with 12-15 in our inner circle.',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page6Data;

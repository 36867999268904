import { Link } from "gatsby"
import React from "react"
import { Navigation } from "../navigation/navigation";

export function Header({ siteTitle, props }) {
  return (
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `100%`,
          padding: `1.45rem 0`,
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          ></Link>
        </h1>
        <Navigation/>
      </div>
    </header>
  )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header

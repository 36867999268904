const item1Data = require('./items/item1');
const module3Data = {
    "id": 3,
    "number": "3",
    "name": "Develop your personal brand",
    "description": "In this section you will start to develop your personal brand and identify your ‘USP’, to have a positive impact on those you meet. .",
    "time": "This section should take approximately 15 minutes to complete ",
    items: [item1Data]
};

module.exports = module3Data;

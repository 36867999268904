import React, { useEffect, useState } from 'react';
// import {isAuthenticated} from "../pages/dashboard"
import { Nav, Navbar, Container } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserMetadata } from '../auth0/getUserMetadata';
import { getApiUserMetadata } from '../api/getApiUserMetadata';
import PropTypes from 'prop-types';

export function Sidebar(props) {
  Sidebar.propTypes = {
    image: PropTypes.string,
    mobileClientImage: PropTypes.string,
    poweredByMobile: PropTypes.bool,
    desktopClientImage: PropTypes.string,
    useApiEndpoints: PropTypes.bool
  };
  const pathname =
    typeof window !== 'undefined' ? window.location.pathname : '';
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);
  useEffect(() => {
    if (props.useApiEndpoints) {
      getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
    } else {
      getUserMetadata(user, getAccessTokenSilently, setUserMetadata);
    }
  }, [getAccessTokenSilently, user]);

  const [windowWidth, setWindowWidth] = useState(null);

  const callbackFunction = () => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      callbackFunction();
    }
  }, []);

  const showMobileNavClientLogo = () => {
    return props.mobileClientImage && windowWidth < 991 ? (
      <div className="mobile-client-image">
        <img
          src={props.mobileClientImage}
          alt="Mobile client logo for nav"
        />
      </div>
    ) : (
      ''
    )
  }

  const showMobileNavPoweredByLogo = () => {
    return props.poweredByMobile && windowWidth < 991 ? (
      <div className="powered-by-capp-mobile">
        <img
          src={props.image}
          alt="Powered by Cappfinity Logo - Mobile"
        />
      </div>
    ) : (
      ''
    )
  }

  const showDesktopNavClientLogo = () => {
   return props.desktopClientImage && windowWidth > 991 ? (
      <div className="desktop-client-image">
        <img
          src={props.desktopClientImage}
          alt="Desktop client logo for nav"
        />
      </div>
    ) : (
      ''
    )
  }
  const showDesktopNavPoweredByLogo = () => {
    return windowWidth > 991 ? (
      <div className="powered-by-capp">
        <img src={props.image} alt="Powered by Cappfinity Logo" />
      </div>
    ) : (
      ''
    )
  }

  const [active, setActive] = useState('default');
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div className="sidebar-wrapper">
      <Navbar expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav">Menu</Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto" activeKey={pathname}>
              {/* <motion.button
                      disabled={isAuthenticated && userMetadata && profile ? "true" : ""}
                      className="btn btn-primary sidebar-button"
                      variant="primary"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.9 }}
                      onClick={() => (window.location.href = props.link)} >
                      {isAuthenticated && userMetadata && profile ? "Completed" : "Complete Profile"}
                  </motion.button> */}

              <ul className="sidebar-list">
                <li>
                  <Nav.Link
                    href={props.link1}
                    aria-current={pathname === props.link1 ? 'page' : undefined}
                  >
                    {props.item1}
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link
                    className={props.navClass1}
                    href={props.link2}
                    aria-current={pathname === props.link2 ? 'page' : undefined}
                  >
                    {props.item2}
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link
                    className={props.navClass2}
                    href={props.link3}
                    aria-current={pathname === props.link3 ? 'page' : undefined}
                  >
                    {props.item3}
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link
                    className={props.navClass3}
                    href={props.link4}
                    aria-current={pathname === props.link4 ? 'page' : undefined}
                  >
                    {props.item4}
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href={props.link5}>{props.item5}</Nav.Link>
                </li>
                {props.extraLinks?.map((item) => {
                  return (
                    <li>
                      <Nav.Link href={item.url}>{item.text}</Nav.Link>
                    </li>
                  );
                })}
              </ul>
              <button
                className="btn btn-primary sidebar-button"
                variant="primary"
                onClick={() => window.open(props.buttonLink, '_blank')}
                style={{ display: 'none' }}
              >
                {props.buttonText}
              </button>
              <button
                className="btn btn-secondary sidebar-button-two"
                variant="secondary"
                onClick={() => window.open(props.buttonLink2, '_blank')}
                style={{ display: 'none' }}
              >
                {props.buttonText2}
                </button>
                {showMobileNavClientLogo()}

              {showMobileNavPoweredByLogo()}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {showDesktopNavClientLogo()}

      {showDesktopNavPoweredByLogo()}
    </div>
  )
}

export default Sidebar;

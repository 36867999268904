export const setQuestionComplete = async ( 
    questionText,
    brand,
    guid,
    getAccessTokenSilently,
    answers
    ) => {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: `${window.apiAudience}`,
              scope: '',
            },
          });
          const completeQuestionUrl = `${window.apiBaseUrl}/ParticipantQuestionAnswer/CompleteQuestion`;

      
          const userResponse = {
            "questionUniqueId": guid,
            "questionText": questionText,
            "participantAnswers": [
                {
                    "text": JSON.stringify(answers)
                }
            ]
          };
          const setData = await fetch(completeQuestionUrl, {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
              'Capp-Tenant-Id': brand.TENANT_ID,
            },
            body: JSON.stringify(userResponse),
          });
          return setData.json();
        } catch (e) {
          return e;
        }
      };

      export default setQuestionComplete;
const page6Data = {
  id: 'c9b18766-4992-47bc-971d-a183d103c481',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Answer the question below to test your knowledge. Click complete module when you are finished and choose another topic to explore.',
    },
  ],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      id: '6e4b47ac-164e-4a32-bc3b-5f7aa2d50ebd',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: '01ec8241-8949-412e-aec1-c4252a7ddf5c',
      questionText: '',
      questions: [
        {
          questions: {
            1: 'What critical traits are important for achieving long-term goals? Select all that apply.',
          },
          responses: {
            1: ['Passion', 'Perseverance', 'Tunnel vision', 'Agility'],
          },
          incorrectAnswers: ['Tunnel vision'],
          feedback: {
            correctFeedback:
              'Correct! Aligning our goals to passion and approaching them with agility and perseverance will help us be successful in achieving our long-term goals.',
            incorrectFeedback:
              'Not quite… tunnel vision can prevent us from being agile, potentially spending too long on tasks that don’t help us reach our ultimate concern. Aligning goals to our passion and approaching them with agility and perseverance will help us be successful in achieving our long-term goals.',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page6Data;

import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { motion } from 'framer-motion';

export const RANKING_ITEM = 'rankingItem';

const DraggableRankItem = ({ item, onMoveItem }) => {
  const type = RANKING_ITEM;

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { ...item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: RANKING_ITEM,
    hover: (draggedItem) => {
      if (draggedItem.id !== item.id) {
        onMoveItem(draggedItem.id, item.id);
      }
    }
  });

  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0 : 1 }}>
      <motion.div ref={preview} className='bucket-sort-item' transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }} style={{ transformOrigin: 'unset' }}>
        {item.text}
      </motion.div>
    </div>
  );
};

export default DraggableRankItem;

const page6Data = {
  id: 'd7f06c15-b480-4eca-8ffa-55e13b440a42',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Answer the question below to test your knowledge. Click complete module when you are finished and choose another topic to explore.',
    },
    {
      class: 'content',
      text: 'The word ‘culture’ has multiple meanings and applications. What do you think are some of the components that make up a company culture? Select all that apply from the list below.',
    },
  ],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      id: '12b91fc5-9165-45f7-b7ed-59ff1694a7da',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: '82a0eb07-f0a3-4c46-a2d7-cec153373322',
      questionText: '',
      questions: [
        {
          questions: {
            1: '',
          },
          responses: {
            1: [
              'Mindsets',
              'Values',
              'Behaviours',
              'Personal preferences',
              'Beliefs',
              'Individual ways of working',
              'Strategy',
            ],
          },
          incorrectAnswers: ['Personal preferences', 'Individual ways of working'],
          feedback: {
            correctFeedback:
              'There are lots of elements that make up organisational and team culture including mindsets, values, beliefs, behaviours and strategy. Did you get all of these? These may not always be immediately apparent, so keep observing and building your knowledge.',
            incorrectFeedback:
              'Not quite – some of these are components that make up a company culture including mindsets, values, beliefs, behaviours and strategy. Remember, company culture is about a shared approach, rather than personal.',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page6Data;

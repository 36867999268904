const page1Data = {
  id: 'dc7a0758-5d70-40b9-9d49-623971227951',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: 'c63e53cc-7377-469b-9dad-b83b9e1e7cef',
      name: 'image',
      imageUrl: 'https://apifiles.cappassessments.com/90DTK/phase3-module2-2.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will explore how to ask for feedback to help you stretch and develop.',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [
    {
      type: 'content',
      text: 'Click "Next" to get started.',
    },
  ],
};

module.exports = page1Data;

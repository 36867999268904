const module1Data = require('./modules/1/module1');
const module2Data = require('./modules/2/module2');
const module3Data = require('./modules/3/module3');
const module4Data = require('./modules/4/module4');
const module5Data = require('./modules/5/module5');
const phase1Data = {
    id: 1,
    number: "1",
    name: "Discover",
    image: "https://apifiles.cappassessments.com/90DTK/phase1-2.png",
    description: "In this phase you will discover your strengths, discover the organisational culture, discover who is in your support network and discover how to make a great first impression.",
    expandedDescription: {
        1: "Welcome to phase 1 of the 90-day toolkit ‘Discover’. In this phase you will:",
        2: ["Discover your strengths", "Discover the organisational culture", "Discover who is in your support network", "Discover how to make a great first impression"],
        3: "When you’re ready, select a topic to get started."
    },
    outcomeType: "freeText",
    modules: [module1Data,module2Data,module3Data,module4Data,module5Data]
};

module.exports = phase1Data;
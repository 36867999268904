import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Button} from 'react-bootstrap';
import { setQuestionComplete } from '../../api/setQuestionComplete';
import { setApiStepComplete, getQuestionByUniqueId } from '@capp.micro/shared/api';
import Feedback from '../feedback/feedback';

export const Checklist = ({
  list,
  setUserMetadata,
  user,
  questionText,
  brand,
  guid,
  getAccessTokenSilently,
}) => {
  const [answers, setAnswers] = useState([]);
  const [finished, setFinished] = useState(false);

  const handleSubmit = () => {
    const answersToSubmit = checkAnswers(answers);
    setQuestionComplete(
      questionText,
      brand,
      guid,
      getAccessTokenSilently,
      answersToSubmit
    );
    setApiStepComplete(
      `${guid} - Complete`,
      brand,
      user,
      getAccessTokenSilently,
      setUserMetadata
    );
    setFinished(true);
  };

  const handleItem = (e) => {
    const newItem = {
      item: e.target.labels[0].innerHTML,
      checked: true,
    };
    const itemIndex = answers.findIndex(
      (answer) => answer.item === newItem.item
    );

    if (itemIndex !== -1) {
      const newArray = answers.slice();
      newArray.splice(itemIndex, 1);
      setAnswers(newArray);
    } else {
      setAnswers([...answers, newItem]);
    }
  };
const checkAnswers = (array) => {
  const feedbackToInsert = array.length === Object.keys(list.options).length ? list.textComplete : list.textIncomplete;
 return [{checked: array, feedback: feedbackToInsert}];
}

  const [previouslySavedResponse, setPreviouslySavedResponse] = useState(null);

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const response = await getQuestionByUniqueId(
          brand,
          guid,
          getAccessTokenSilently
        );
        if(response[0])
          {return setPreviouslySavedResponse(response)}
          else {return setPreviouslySavedResponse(null);}
      } catch (error) {
        return 'Error fetching question: ' + error;
      }
    }
    fetchQuestion();
  }, []);

  const component = previouslySavedResponse ? (
    <div className="feedback-main">
      <div>
            <h4>Checklist submitted</h4>
          </div>
      <p />
      {previouslySavedResponse[0].feedback}
    </div>
  ) : (
    <>
      {finished ? (
        <>
          {answers.length === Object.keys(list.options).length ? (
            <Feedback feedbackText={list.textComplete} />
          ) : (
            <Feedback feedbackText={list.textIncomplete} />
          )}
        </>
      ) : (
        <div className="checklist-items">
          <h3>{list.prompt}</h3>
          <Form>
            {Object.keys(list.options).map((item, index) => (
              <div key={`${list.options[index]}`} className="mb-3">
                <Form.Check // prettier-ignore
                  type="checkbox"
                  id={`${index}`}
                  label={`${list.options[item]}`}
                  onChange={(e) => handleItem(e)}
                />
              </div>
            ))}
            <div className="checklist-sort-submit">
              <Button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      )}
    </>
  );

  return <>{component}</>;
};

export default Checklist;

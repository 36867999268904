const page3Data = {
  id: '90569dfa-4ff1-40b5-ae16-dba403b2a136',
  number: '3',
  title: 'Understand: the impact of mindset',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'With mindset being so critical to our success, it’s important to notice our default reaction to new situations like the previous scenario – whether we tend to lead with a fixed, or a growth mindset. Explore the cards below to find out more about what this means.',
    },
  ],
  afterResponse: [''],
  action: [
    'Next time you encounter a tricky situation at work, try to lead with a growth ‘I can’t yet’ mindset and see how it impacts how you feel and the outcome of the situation.',
  ],
  responseType: [
    {
      id: '69358f7a-3412-4892-804b-bfb2d74c4208',
      name: 'flipCard',
      class: 'flipcard-container',
      guid: '4dbaf67b-53da-4dc9-ad6b-3781406e754a',
      questionText: '',
      cardText: {
        frontText: 'Growth Mindset',
        backText: '"I can always learn and improve”.',
        cardList: [
          'Believe abilities and intelligence can be developed with effort, learning and persistence.',
          'Desires continuous learning.',
          'Embraces challenge and isn’t afraid to fail.',
          'Confronts uncertainty.',
        ],
        circle: './images/circle.svg',
      },
    },
    {
      id: '3ccfd2c2-6e15-4972-bc55-8a7dbff10b00',
      name: 'flipCard',
      class: 'flipcard-container',
      guid: '9dd0fe05-8317-4508-9a65-79cd4e2506f0',
      questionText: '',
      cardText: {
        frontText: 'Fixed Mindset',
        backText: '"I’m not good at that, it’s not my thing”.',
        cardList: [
          'Believe basic abilities, intelligence and talents are fixed traits.',
          'Avoids failure and challenges, sticking to what they know.',
          'Takes feedback and criticism personally.',
          'Doesn’t change or improve.',
        ],
        circle: './images/circle.svg',
      },
    },
    {
      id: '5',
      name: 'freeText',
      class: 'missions',
      guid: '0e7de921-3652-479b-8d73-dee7b37b0ba3',
      questionText:
        'Our mindset will change dependent on the situation we are in - we cannot be in a growth mindset all the time but it’s important to recognise the times you fall into a fixed way of thinking. ',
      questions: [
        {
          1: 'Reflect on a recent situation where you needed to learn something new, encountered a challenge or something unfamiliar. Did you respond with a fixed or a growth mindset and what impact did this mindset have on your success?',
        },
      ],
    },
  ],
};

module.exports = page3Data;

import React, { useState, useEffect } from 'react';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';

import { setQuestionComplete } from '../../api/setQuestionComplete';
import {setApiStepComplete} from '../../api/setApiStepComplete';

export const ListSort = ({ listItems, setUserMetadata, user, questionText, brand, guid, getAccessTokenSilently }) => {

  const [items, updateItems] = useState(listItems.list);
  const [answers, setAnswers] = useState([])
  const [finished, setFinished] = useState(false);
  function handleOnDragEnd(result) {
    if (!result.destination) {return};
    const units = Array.from(items);
    const [reorderedItem] = units.splice(result.source.index, 1);
    units.splice(result.destination.index, 0, reorderedItem);
    updateItems(units);
  }

  useEffect(() => {
    if (answers.length > 0) { setQuestionComplete(questionText, brand, guid, getAccessTokenSilently, answers);
      setApiStepComplete(`${guid} - Complete`, brand, user, getAccessTokenSilently, setUserMetadata)
} 
}, [user, questionText, brand, guid, getAccessTokenSilently, answers]);

  const handleSubmit = () => {
    if (listItems.feedback) {
      setFinished(true);
      setAnswers(items);
    } else {
      setAnswers(items);
    }
  }

  return (

    <div className='list-sort'>
      { finished ? (<div className='list-items'><h3>{listItems.feedback}</h3></div>) : (<DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="list-items">
          {(provided) => (
            <ul
              className="list-items"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map(({ id, name, text }, index) => {
                return (
                  <Draggable
                    key={id}
                    draggableId={id}
                    index={index}
                    name={name}
                  >
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="list-individual"
                      >
                        <div>
                          <div>{text}</div>
                        </div>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
        <div className='list-sort-submit'>
    <Button type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</Button>
    </div>
      </DragDropContext>) }
    </div>

  );
}

export default ListSort;

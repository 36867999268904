const contentBold = 'content bold';
const page2Data = {
  id: '7ad3a417-34f2-469e-8d2f-6853b490938b',
  number: '2',
  title: 'Uncover: your learning so far',
  content: [
    {
      class: 'content',
      text: 'You have now been in your role for over 60-days. Undoubtedly you will have had a lot of new experiences, stretched your comfort zone and navigated various challenges. Before you start planning next steps, or jumping into new opportunities, take a moment to review how far you have come.',
    },

    {
      image: {
        src: 'https://apifiles.cappassessments.com/90DTK/comfort-zone.png',
        alt: 'Comfort Zone',
      },
    },
    { class: contentBold, text: 'The comfort zone' },

    { class: 'content', text: 'Familiar stations that require less effort.' },

    { class: contentBold, text: 'The learning zone' },

    {
      class: 'content',
      text: 'New experiences and experimentation that stretch your abilities in a positive way.',
    },

    { class: contentBold, text: 'The danger zone' },

    {
      class: 'content',
      text: 'Where the stretch goes too far and exceeds our ability at this particular time, leading to stress and feeling overwhelmed.',
    },

    {
      class: 'content question',
      text: 'Think about what percentage of your time you have spent in each of these zones, from day one in role to now? Make sure these add up to 100%, for example: Comfort zone 10%, Learning zone 50%, Danger zone 40%',
    },
  ],
  afterResponse: [
    'When you start a new role, more than likely you will be spending the majority of your time in the learning or danger zones. However, as you get more and more familiar with the environment, team and other aspects we covered in phase one, you will hopefully start to spend more time in the learning zone and experience positive professional ‘firsts’.',
  ],
  action: [
    'Make asking for feedback a habit. Feedback is often seen as a negative and people can avoid sharing what they really think. But the more you ask, the more open others will be and the better quality the feedback will be.',
  ],
  resourceType: [],
  responseType: [
    {
      id: '338b7b02-3acc-4cf3-90af-9889797d4d25',
      name: 'sliderQuestion',
      class: 'slider-container',
      guid: 'e244fe8a-ca5b-4bf0-8d80-d88c0f7735e7',
      questionText: 'Day 1',
      questions: [
        {
          questions: ['Comfort', 'Learning', 'Danger'],
          categories: {},
          max: 100,
          min: 0,
          startingValue: 50,
          increments: 10,
          showPrevious: true,
          showFeedback: true,
          feedback: {
            correctFeedback: '',
            incorrectFeedback: '',
            defaultFeedback:
              "Great. Now let's think about where you are now. Move on to the next question.",
          },
        },
      ],
    },
    {
      id: '9807bf8b-ac20-4498-901f-4d331e5f4e16',
      name: 'sliderQuestion',
      class: 'slider-container',
      guid: '1a42cd8b-7bf5-4bc8-9a19-bd001252b128',
      questionText: 'Now',
      questions: [
        {
          questions: ['Comfort', 'Learning', 'Danger'],
          categories: {},
          max: 100,
          min: 0,
          startingValue: 50,
          increments: 10,
          showPrevious: true,
          showFeedback: true,
          feedback: {
            correctFeedback: '',
            incorrectFeedback: '',
            defaultFeedback:
              'Great - do you notice any key differences between day 1 and where you are now?',
          },
        },
      ],
    },
  ],
};

module.exports = page2Data;

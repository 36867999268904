const page2Data = {
  id: 'a3cfd84a-d6ac-4be8-bc26-b68adfad0a7e',
  number: '2',
  title: 'Uncover: feedback benefits',
  content: [
    {
      class: 'content',
      text: 'To continually develop and grow at work we need to understand how we are performing and areas where we could improve. To do this, we should ask for feedback from our manager, project team and other people we interact with.',
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'Feedback is often seen as a negative and people can avoid sharing what they really think. Get into the habit of asking others for feedback – the more you ask, the more open others will be and the better quality the feedback will be.',
  ],
  resourceType: [],
  responseType: [
    {
      id: '6169f7b3-62b8-40e8-b004-d9eaef8cc67d',
      name: 'quiz',
      class: 'end-quiz',
      guid: 'b0036cc4-3177-4434-ba48-546fd4afd357',
      questionText: '',
      questions: [
        {
          question:
            'How often do you think you should be receiving feedback from others?',
          answers: [
            'After a project has finished',
            'You don’t need to ask. People will tell you',
            'Once a year',
            'Never',
            'All the time',
          ],
          showPrevious: true,
          showFeedback: true,
          correctAnswer: 'All the time',
          feedback: {
            correctFeedback:
              'That’s right! It’s good practice to ask for feedback continuously, from a variety of different people and across different projects, to ensure you gather a range of perspectives.',
            incorrectFeedback:
              'Not quite - it’s good practice to ask for feedback continuously, from a variety of different people and across different projects, to ensure you gather a range of perspectives.',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page2Data;

const item1Data = require('./items/item1');
const module2Data = {
    "id": 2,
    "number": "2",
    "name": "Develop strong learning goals",
    "description": "In this section you will develop strong goals to help you maintain a continuous learning mindset during the first 90-days and beyond.",
    items: [item1Data]
};

module.exports = module2Data;

const page1Data = {
  id: '0cdcb5a6-3cf9-490a-b26f-302acffaaadb',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: '7632eed6-4f7f-4524-bd30-a2e7ba7d7eab',
      name: 'image',
      imageUrl: 'https://apifiles.cappassessments.com/90DTK/phase1-module3-2.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will discover your support network – creating new connections and building a support network.',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [
    {
      type: 'content',
      text: 'Click "Next" to get started.',
    },
  ],
};

module.exports = page1Data;

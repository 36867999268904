const articleClass = 'article-resource 4-col';
const page5Data = {
  id: 'aaea0111-d68b-4ee0-a301-796fb4e13ba2',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: '2c3427ca-2bf2-4f1f-9f84-b40a5be4ae0d',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: 'd7f8e2ae-bf27-4b7c-a827-6bc69b2ce15c',
          name: 'Read an article about building new habits',
          heading: 'Read',
          link: 'https://hbr.org/2021/02/what-does-it-really-take-to-build-a-new-habit',
          linkText: 'Read more',
          class: articleClass,
        },
        {
          id: '6ccd6242-6f44-47c1-964a-a527c947ef92',
          name: 'Listen to a podcast on grit',
          heading: 'Listen',
          link: 'https://freakonomics.com/podcast/how-to-get-more-grit-in-your-life/',
          linkText: 'Listen now',
          class: 'video-resource 4-col',
        },
        {
          id: '7eb7388e-c833-462d-a97b-ecd7129d929b',
          name: 'Listen to a podcast on being a better you',
          heading: 'Listen',
          link: 'https://choice.npr.org/index.html?origin=https://www.npr.org/programs/ted-radio-hour/529942522/a-better-you',
          linkText: 'Listen now',
          class: articleClass,
        },
        {
          id: '7eb7388e-c833-462d-a97b-ecd7129d929b',
          name: 'Watch a video about goal hierarchy',
          heading: 'Watch',
          link: 'https://vimeo.com/250654148',
          linkText: 'Watch now',
          class: articleClass,
        },
      ],
    },
  ],
  responseType: [
    {
      id: 'c977ec71-3a1a-4bcf-8c58-8d690e0468ec',
      name: 'freeText',
      class: 'missions',
      guid: '02494cf8-7ca0-4197-85af-7630eb772544',
      questionHeader: 'Mission 1',
      questionText: 'Make a plan',
      questions: [
        {
          1: 'If you haven’t already, decide on a high-level work goal you want to achieve over the next 6-months. Plot mid and low-level actions and tasks to support you in reaching in this goal. Report any observations or commitments below.',
        },
      ],
    },
    {
      id: 'abd35836-6a58-491a-bfa5-fef70571132f',
      name: 'freeText',
      class: 'missions',
      guid: '7d32ce93-5d38-4d2d-8df6-a6b565ca7618',
      questionHeader: 'Mission 2',
      questionText: 'Complete a low-level goal',
      questions: [
        {
          1: 'Choose one of your low-level goals to start progressing towards your ultimate goal. This could be individual research or meeting someone new. Try it and report back on the outcome.',
        },
      ],
    },
    {
      id: '8a1c320f-cffa-4619-8522-cf64bc57ae99',
      name: 'freeText',
      class: 'missions',
      guid: 'd0b12a5d-b1a7-4b2c-b18f-daaf88d04fa3',
      questionHeader: 'Mission 3',
      questionText: 'Try adopting a growth mindset',
      questions: [
        {
          1: 'The next time you are assigned a task or a new challenge, approach it with a growth mindset and see how it impacts your success. Report back on any observations and the outcome.',
        },
      ],
    },
  ],
};

module.exports = page5Data;

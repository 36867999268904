import React from "react"
import AuthNav from "./authNav"
import { Nav, Navbar } from "react-bootstrap";
import PropTypes from 'prop-types'

export function Navigation(props) {

Navigation.propTypes = {
  logo: PropTypes.string,
  useApiEndpoints: PropTypes.bool,
  alt: PropTypes.string
}

  return (

      <Navbar collapseOnSelect expand="lg" variant="light" sticky="top" className='header header-scrolled navbar navbar-expand-lg navbar-light sticky-top'>
        <Navbar.Brand>
         <a href="/"> <img src={props.logo} alt={props.alt}/></a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>

        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{ justifyContent: "flex-start" }}
        >
          
        </Navbar.Collapse>
        <Nav className="button-nav">
          <AuthNav useApiEndpoints={props.useApiEndpoints} />
        </Nav>
      </Navbar>

  )
}


export default Navigation

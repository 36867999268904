const page6Data = {
  id: 'a2f5a601-07e0-4569-8a27-001de39b87de',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Answer the question below to test your knowledge. Click complete module when you are finished and choose another topic to explore.',
    },
  ],
  afterResponse: [''],
  responseType: [
    {
      id: '38a09b57-116e-43c2-83b6-41a15a7d3cdb',
      name: 'quiz',
      class: 'end-quiz',
      guid: '543c8a2a-ddc0-4341-aeb5-7c7f1b31b225',
      questionText: '',
      questions: [
        {
          question: 'What does it mean to be at ‘optimal performance’?',
          answers: [
            'Having a balance of stretch and stress',
            'Working all hours of the day to get the job done',
            'Comfortably completing tasks, within what you know',
          ],
          showPrevious: true,
          showFeedback: true,
          correctAnswer: 'Having a balance of stretch and stress',
          feedback: {
            correctFeedback:
              'You’ve got it. Optimal performance is about finding the balance between stretch and stress, enabling you to perform at your best.',
            incorrectFeedback:
              'Not quite. Optimal performance is about finding the balance between stretch and stress. Too much stretch can lead to overwhelm and lower wellbeing.',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page6Data;

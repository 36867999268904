const item1Data = require('./items/item1');
const module1Data = {
    id: 1,
    number: "1",
    name: "Explore the change curve",
    description: "In this section you will explore a typical change journey and your experiences in your new role so far.",
    items: [item1Data]
};

module.exports = module1Data;

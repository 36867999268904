import React, { useState, useCallback, useEffect } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Bucket from './bucket';
import ItemsArea from './itemsArea';
import { Row, Button, Col } from 'react-bootstrap';
import {
  setQuestionComplete,
  setApiStepComplete,
  getQuestionByUniqueId,
} from '@capp.micro/shared/api';
import Feedback from '../feedback/feedback';

const findItem = (itemId, sourceBucketId, draggableItems, sourceBucket) =>
  sourceBucketId === 'items'
    ? draggableItems.find((i) => i.id === itemId)
    : sourceBucket?.items.find((i) => i.id === itemId);

const findBucket = (buckets, bucketId) =>
  buckets.find((b) => b.id === bucketId);

const canMoveItems = (sourceBucketId, sourceBucket, targetBucket, item) =>
  ((sourceBucketId !== 'items' && sourceBucket) || targetBucket) && item;

const updateSourceBucket = (sourceBucketId, sourceBucket, itemId) =>
  sourceBucketId !== 'items'
    ? {
        ...sourceBucket,
        items: sourceBucket.items.filter((i) => i.id !== itemId),
      }
    : sourceBucket;

const updateTargetBucket = (targetBucket, item) => {
  return { ...targetBucket, items: [...targetBucket.items, item] };
};

const updateBuckets = (
  prevBuckets,
  sourceBucketId,
  newSourceBucket,
  newTargetBucket
) =>
  prevBuckets.map((b) => {
    if (b.id === sourceBucketId && sourceBucketId !== 'items') {
      return newSourceBucket;
    } else if (b.id === newTargetBucket.id) {
      return newTargetBucket;
    } else {
      return b;
    }
  });

const checkAnswers = (bucketsToCheck, feedback) => {
  if (feedback.correctFeedback) {
    const checkedAnswers = bucketsToCheck.reduce((accumulator, bucket) => {
      return accumulator.concat(
        bucket.items.map((item) => item.category === bucket.id)
      );
    }, []);

    return checkedAnswers.includes(false)
      ? feedback.incorrectFeedback
      : feedback.correctFeedback;
  } else {
    return feedback.defaultFeedback; // or whatever value you want to return if feedback.correctFeedback is falsy
  }
};

const BucketSortSection = ({
  draggableItems,
  moveItemBack,
  buckets,
  moveItemToBucket,
  setBuckets,
  categories,
  handleSubmit,
}) => {
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  const reorderedItems = shuffle(draggableItems);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div>
          <ItemsArea items={reorderedItems} moveItemBack={moveItemBack} />

          <Row className="bucket-sort-row">
            {buckets.map((bucket, index) => (
              <Bucket
                key={bucket.id}
                bucket={bucket}
                bucketId={bucket.id}
                moveItemToBucket={moveItemToBucket}
                moveItemBack={moveItemBack}
                setBuckets={setBuckets}
                category={categories[index]}
              />
            ))}
          </Row>
        </div>
      </DndProvider>
      <div className="bucket-sort-submit">
        <Button
          type="button"
          className="btn btn-primary"
          onClick={handleSubmit}
          disabled={draggableItems.length !== 0}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export const BucketSort = ({
  questionText,
  categories,
  feedback,
  listItems,
  setUserMetadata,
  user,
  brand,
  guid,
  getAccessTokenSilently,
}) => {
  const [draggableItems, setDraggableItems] = useState(
    listItems.map((item) =>
      item.correctAnswer
        ? {
            id: item.id,
            name: item.text,
            category: item.correctAnswer,
          }
        : {
            id: item.id,
            name: item.text,
          }
    )
  );

  const initialBuckets = categories.map((index) => {
    const bucketId = index;
    return {
      id: `${bucketId}`,
      items: [],
    };
  });

  const [buckets, setBuckets] = useState(initialBuckets);
  const [feedbackToShow, setFeedbackToShow] = useState(
    feedback.defaultFeedback
  );
  const [finished, setFinished] = useState(false);

  const moveItemToBucket = useCallback(
    (itemId, sourceBucketId, targetBucketId) => {
      if (sourceBucketId === targetBucketId) {
        return;
      }

      setBuckets((prevBuckets) => {
        const sourceBucket = findBucket(prevBuckets, sourceBucketId);
        const targetBucket = findBucket(prevBuckets, targetBucketId);
        const item = findItem(
          itemId,
          sourceBucketId,
          draggableItems,
          sourceBucket
        );

        if (!canMoveItems(sourceBucketId, sourceBucket, targetBucket, item)) {
          return prevBuckets;
        }

        const newSourceBucket = updateSourceBucket(
          sourceBucketId,
          sourceBucket,
          itemId
        );
        const newTargetBucket = updateTargetBucket(targetBucket, item);

        setDraggableItems((prevItems) =>
          prevItems.filter((i) => i.id !== itemId)
        ); // Remove item from draggableItems

        return updateBuckets(
          prevBuckets,
          sourceBucketId,
          newSourceBucket,
          newTargetBucket
        );
      });
    },
    [draggableItems]
  );

  const removeItemFromBucket = useCallback((itemId, sourceBucketId) => {
    setBuckets((prevBuckets) =>
      prevBuckets.map((bucket) =>
        bucket.id === sourceBucketId
          ? { ...bucket, items: bucket.items.filter((i) => i.id !== itemId) }
          : bucket
      )
    );
  }, []);
  const updateDraggableItems = useCallback(
    (itemId, sourceBucketId) => {
      const item = buckets
        .find((bucket) => bucket.id === sourceBucketId)
        ?.items.find((i) => i.id === itemId);

      if (item) {
        setDraggableItems((prevItems) => [...prevItems, item]);
      }
    },
    [buckets]
  );

  const moveItemBack = useCallback(
    (itemId, sourceBucketId) => {
      removeItemFromBucket(itemId, sourceBucketId);
      updateDraggableItems(itemId, sourceBucketId);
    },
    [removeItemFromBucket, updateDraggableItems]
  );

  const upDateFeedback = () => {
    setFeedbackToShow(checkAnswers(buckets, feedback));
  };

  const setComplete = () => {
    const updatedFeedback = checkAnswers(buckets, feedback);
    setQuestionComplete(questionText, brand, guid, getAccessTokenSilently, {
      buckets,
      updatedFeedback,
    });
  };

  const handleSubmit = () => {
    upDateFeedback();
    setComplete();
    setApiStepComplete(
      `${guid} - Complete`,
      brand,
      user,
      getAccessTokenSilently,
      setUserMetadata
    );
    setFinished(true);
  };

  const [previouslySavedResponse, setPreviouslySavedResponse] = useState(null);

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const response = await getQuestionByUniqueId(
          brand,
          guid,
          getAccessTokenSilently
        );
       if (response.buckets)
          {return setPreviouslySavedResponse(response)}
          else {return setPreviouslySavedResponse(null);}
      } catch (error) {
        return 'Error fetching question: ' + error;
      }
    }
    fetchQuestion();
  }, []);

  const component = previouslySavedResponse ? (
    <div className="feedback-main">
      <div className="feedback-title">You responded:</div>
      <Row>
        {previouslySavedResponse.buckets.map((bucket) => (
          <Col key={bucket.id} xs={12} lg={4}>
            <div>
              <h4>{bucket.id}</h4>
              {bucket.items.map((item) => (
                <div key={item.name}>{item.name}</div>
              ))}
            </div>
          </Col>
        ))}
      </Row>
      <div className="feedback-title">Feedback:</div>
      {previouslySavedResponse.updatedFeedback}
    </div>
  ) : (
    <BucketSortSection
      draggableItems={draggableItems}
      moveItemBack={moveItemBack}
      buckets={buckets}
      moveItemToBucket={moveItemToBucket}
      setBuckets={setBuckets}
      categories={categories}
      handleSubmit={handleSubmit}
      getAccessTokenSilently={getAccessTokenSilently}
      guid={guid}
    />
  );

  return (
    <>{finished ? (<Feedback feedbackText={feedbackToShow} />) : (component)}</>
  );
};

export default BucketSort;

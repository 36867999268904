const page1Data = {
  id: 'fe07c491-c6cd-4eb2-8c0b-13641fc84458',
  number: '1',
  title: 'Overview',
  resourceType: [
    {
      id: '67587912-8f3f-4d90-81a1-6c991e0b85c6',
      name: 'image',
      imageUrl: 'https://apifiles.cappassessments.com/90DTK/phase1-module4-2.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'In this section you will discover how to make a great first impression – craft and practice making a strong introduction.',
    },
  ],
  time: 'This section should take approximately 15 minutes to complete',
  afterResponse: [''],
  responseType: [
    {
      type: 'content',
      text: 'Click "Next" to get started.',
    },
  ],
};

module.exports = page1Data;

import React from "react"
import { motion } from "framer-motion"
// import { useAnimation, motion } from "framer-motion"
// import { useInView } from "react-intersection-observer"
import { Col, Container, Row, } from "react-bootstrap"


export function JumboFourCols(props) {
  // const variants = {
  //   visible: { opacity: 1, y: 0 },
  //   hidden: { opacity: 0, y: 20 },
  // }
  // const controls = useAnimation()
  // const [ref, inView] = useInView()
  // useEffect(() => {
  //   if (inView) {
  //     controls.start("visible")
  //   }
  // }, [controls, inView])
  return (
    <>
      <Container className={props.class} id={props.id}>
        <div className="text-container">
          <Row>
            <Col xs={12}>
              <div className="title">
                <h2> {props.mainTitle}</h2>
                <p> {props.mainText}</p></div>

            </Col>
          </Row>
          <Row>
            <Col xs={6} lg={3}>
              <img src={props.image} alt={props.alt}/>
              <div className={props.numberCheck}>{props.number}</div>
              <h2>
                {props.title}
              </h2>
              <p>
                {props.text}
              </p>
              <div>
                <motion.button
                  className="btn btn-primary"
                  variant="primary"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => (window.location.href = props.link)}>
                  {props.buttonText}
                </motion.button>
              </div>
            </Col>
            <Col xs={6} lg={3}>
              <img src={props.image2} alt={props.alt}/>
              <div className={props.numberCheck}>{props.number}</div>
              <h2>
                {props.title2}
              </h2>
              <p>
                {props.text2}
              </p>
              <motion.button
                className="btn btn-primary"
                variant="primary"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => (window.location.href = props.link2)}>
                {props.buttonText2}
              </motion.button>
            </Col>
            <Col xs={6} lg={3}>
              <img src={props.image3} alt={props.alt}/>
              <div className={props.numberCheck}>{props.number}</div>
              <h2>
                {props.title3}
              </h2>
              <p>
                {props.text3}
              </p>
              <div>
                <motion.button
                  className="btn btn-primary"
                  variant="primary"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => (window.location.href = props.link3)}>
                  {props.buttonText3}
                </motion.button>
              </div>
            </Col>
            <Col xs={6} lg={3}>
              <img src={props.image4} alt={props.alt}/>
              <div className={props.numberCheck}>{props.number}</div>
              <h2>
                {props.title4}
              </h2>
              <p>
                {props.text4}
              </p>
              <div>
                <motion.button
                  className="btn btn-primary"
                  variant="primary"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => (window.location.href = props.link4)}>
                  {props.buttonText4}
                </motion.button>
              </div>
            </Col>

          </Row>
        </div>
      </Container>
    </>
  )
}

export default JumboFourCols

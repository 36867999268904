const page6Data = {
  id: '1c69badb-316d-4da4-a8be-734a046a6baa',
  number: '6',
  title: 'Test your knowledge',
  resourceType: [],
  content: [{ class: 'content', text: '' }],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      type: 'content',
      text: "Answer the question below to test your knowledge. Click 'Complete Module' when you are finished and choose another topic to explore.",
    },
    {
      id: '16',
      name: 'quiz',
      class: 'end-quiz',
      guid: 'f85bda95-0834-4a49-ba1f-fe48155409b2',
      questionText: '',
      questions: [
        {
          question: 'What are the three components of a strength? ',
          answers: [
            'Performance, energy, resilience',
            'Energy, quality, use',
            'Performance, energy, use',
          ],
          showPrevious: true,
          showFeedback: true,
          correctAnswer: 'Performance, energy, use',
          feedback: {
            correctFeedback:
              "You've got it! Top tip - look for signs of energy as you are completing a task and observe if this is a strength for you.",
            incorrectFeedback:
              'Not quite – the three components of a strength are performance, energy and use.',
            defaultFeedback: 'Move on to the next question',
          },
        },
      ],
    },
  ],
};

module.exports = page6Data;

import React, {useState, useEffect} from 'react';
import { Form, Button } from 'react-bootstrap';
import {setQuestionComplete} from '../../api/setQuestionComplete';
import { setApiStepComplete, getQuestionByUniqueId } from '@capp.micro/shared/api';
import Feedback from '../feedback/feedback';

export const FreeText = ({ questions, setUserMetadata, user, questionText, brand, guid, getAccessTokenSilently }) => {
  const [responses, setResponses] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [input, setInput] = useState('');
  const [finished, setFinished] = useState(false);

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleNav = (e) => {
    const addResponse = () => {
      const text = input;
      const newResponse = {
        question: questions[0][currentQuestion],
        answer: text,
      };
      const clearMe = document.querySelector('#free-text-form');
      clearMe.value = '';
      return [...responses, newResponse]
    };
    if (e.target.innerHTML === 'Back') {
      if (currentQuestion > 1) {
        setCurrentQuestion((prevQuestion) => prevQuestion - 1);
      }
    } else if (e.target.innerHTML === 'Next') {
      const newAnswers = addResponse();
      setResponses(newAnswers);
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
    } else if (e.target.innerHTML === 'Submit') {
      const answers = addResponse();
      setResponses(answers)
      setQuestionComplete(questionText, brand, guid, getAccessTokenSilently, answers);
      setApiStepComplete(`${guid} - Complete`, brand, user, getAccessTokenSilently, setUserMetadata);
      setFinished(true);
    } else {
      return 'No info'
    }
  return 'Done'};

 const feedbackToShow = (<><div className="feedback-title">
 You responded:
 </div>
 {responses.map((group) => {
   return (
     <div className="feedback-response" key={group.answer.length}>
         {group.answer}
     </div>
   );
 })}</>)

 const [previouslySavedResponse, setPreviouslySavedResponse] = useState(null);

  useEffect(() => {
    async function fetchQuestion() {
      try {
        const response = await getQuestionByUniqueId(
          brand,
          guid,
          getAccessTokenSilently
        );
        if(response[0])
     {     return setPreviouslySavedResponse(response)}
          else {return setPreviouslySavedResponse(null);}
      } catch (error) {
        return 'Error fetching question: ' + error;
      }
    }
    fetchQuestion();
  }, []);

  const component = previouslySavedResponse ? (<div className='feedback-main'><div className="feedback-title">
  You responded:
  </div>
  {previouslySavedResponse[0].answer}</div>) : (<div className="quiz-session">
  <div className="free-text-header">
    <div className="free-text-questions">
      Question {currentQuestion.toString()} of{' '}
      {Object.keys(questions[0]).length}{' '}
    </div>
  </div>
  <div className='free-text-sub'>
  <div 
      className="free-text-question"
      dangerouslySetInnerHTML={{ __html: questions[0][currentQuestion] }}
  >
  </div>
    <div className="free-text-answers">
      <div className="free-text-input">
        <Form id="form-main">
          <Form.Group>
            <Form.Control
              as="textarea"
              placeholder="Enter your response here"
              onChange={handleInput}
              id="free-text-form"
            />
          </Form.Group>
        </Form>
      </div>
      <div className="free-text-nav">
      {Object.keys(questions[0]).length > 1 && (
        <Button onClick={handleNav}>Back</Button>
      )}
        <Button onClick={handleNav} disabled={!input}>
          {currentQuestion === Object.keys(questions[0]).length
            ? 'Submit'
            : 'Next'}
        </Button>
      </div>
    </div>
  </div></div>)


  return (

      <>{finished ? ( <Feedback feedbackText={feedbackToShow} />
      ) : (component)}</>

  );
}

export default FreeText;

import * as React from "react";
import { useRef } from "react";
import { motion, sync, useCycle } from "framer-motion";
import { useDimensions } from "./use-dimensions";
import { MenuToggle } from "./menuToggle";
import { Navigation } from "./navigation";
import "./style.css";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0,
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const overlayVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};



export const FramerMenu = ({menuItems}) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className="framer-menu"
   
    >
      <motion.div className="background" variants={sidebar} />
      <Navigation items={menuItems}/>
      <MenuToggle toggle={() => toggleOpen()} />

      <motion.div 
        className="overlay" 
        variants={overlayVariants} 
        animate={isOpen ? 'open' : 'closed'} 
      />
    </motion.nav>
  );
};

const page5Data = {
  id: 'e09c8fd7-db42-4d8f-a2b3-5c063e2029dd',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    { class: 'content quote', text: '"Practice makes...better"' },
    {
      class: 'content',
      text: "The more we practice noticing strengths in action, the more aware we will become of not just our own personal strengths, but our team members' as well. Complete the tasks on this page to continue broadening and building your understanding of strengths.",
    },
    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: '86c4af39-e385-449f-9978-e1d3315da860',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: '90598c9b-1ca2-4f52-bbd9-8c56a2d071dc',
          name: 'Read an article about strengths',
          heading: 'Learn',
          link: 'https://files.strengthsprofile.com/Deployable/LocalizableResources/en-GB/Resources/Strengths_Profile_The_Benefits_Of_Strengths.pdf?v=20200911.3',
          linkText: 'Learn more',
          class: 'article-resource',
        },
        {
          id: '92af81fa-df05-4e8b-93be-719bf51f2389',
          name: 'Watch a video about self-awareness',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/tasha_eurich_increase_your_self_awareness_with_one_simple_fix',
          linkText: 'Watch now',
          class: 'video-resource',
        }
      ],
    },
  ],
  responseType: [
    {
      id: 'c1f493e9-a1f9-432c-a4f3-130691ebcd64',
      name: 'freeText',
      class: 'missions',
      guid: 'f6648429-6dd7-4c0f-9b3d-1ae4d32e2e33',
      questionHeader: 'Mission 1',
      questionText: 'Practice strengths spotting.',
      questions: [
        {
          1: 'In your next meeting, look out for signs of energy, performance and use. Record what you notice below.',
        },
      ],
    },
    {
      id: 'ed89b888-af46-4c25-aebe-adb800f0ad2a',
      name: 'freeText',
      class: 'missions',
      guid: '16b79cf7-4be3-4788-8b5d-c27b77828168',
      questionHeader: 'Mission 2',
      questionText: 'Find out about your own strengths.',
      questions: [
        {
          1: 'Self-awareness is a critical skill needed to be successful in any role - build your self-awareness and understanding of your own strengths and weaknesses, by taking a free strengths profile – you can find a link to this at the bottom of this page. Share your profile with your manager as well to ask for their input and feedback - record any observations or reflections below.',
        },
      ],
    },
    {
      id: 'f1d0f38c-f7db-4d0c-8b66-886e6033162f',
      name: 'freeText',
      class: 'missions',
      guid: 'c20407a0-2d9f-4c1d-b04f-1e10cc4e8098',
      questionHeader: 'Mission 3',
      questionText: 'Leverage a strength.',
      questions: [
        {
          1: 'Choose one strength you would like to use more, try leveraging this strength at work. Record how it went below - what did you learn, what could you do differently.',
        },
      ],
    },
  ],
};

module.exports = page5Data;

const page4Data = {
  id: 'd0f9385e-a271-40d2-86eb-93b20cc21eb3',
  number: '4',
  title: 'Reflect: your opportunities',
  resourceType: [],
  content: [
    {
      class: 'content ',
      text: 'As we uncovered in the previous module, there are many different ways to push your comfort zone. We’ve explored people and promotion, but what about possibilities?',
    },
    {
      class: 'content ',
      text: 'POSSIBILITIES',
    },
    {
      class: 'content ',
      text: 'We are all responsible for our own development and finding new possibilities or opportunities to try new things. ',
    },

    {
      class: 'content question',
      text: 'Look at the scenario below and think about how you would respond',
    },
  ],

  afterResponse: [''],

  responseType: [
    {
      id: 'c3ad8300-1421-45da-9064-70f3acfcea88',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: '0c0cc70b-284c-45da-a968-d67b2a35f4c2',
      questionText: '',
      questions: [
        {
          questions: {
            1: 'You are working on a project and need to ask a senior stakeholder for more time because you haven’t hit the target required yet. Which of the following are you most likely to do?',
          },
          responses: {
            1: [
              'Pick up the phone and call',
              'Check in with others first',
              'Get someone else to do it',
            ],
          },
          incorrectAnswers: [],
          feedback: {
            correctFeedback: '',
            incorrectFeedback: '',
            defaultFeedback:
              'There isn’t actually a right or wrong answer here, but what you choose says a lot about how you feel about conflict, rejection or making mistakes. Often the main barrier we face in these situations is fear of what could happen, which can get in the way of our progress and success. Actually, research shows that in situations such as this, people often try to help or find alternative ways to say ‘yes’, rather than reject or condemn.',
          },
        },
      ],
    },
    {
      type: 'content',
      text: 'Continuous learning and taking action to find opportunities to stretch and grow are critical to our continued development and progression at work.',
    },
    {
      id: '677c156b-e229-4da1-a12d-f2a5de090352',
      name: 'freeText',
      class: 'missions',
      guid: '0331a336-c2c4-433d-a4d4-8560a5afd9a4',
      questionHeader: '',
      questionText:
        '',
      questions: [
        {
          1: 'Identify one new experience you will commit to trying in the next few weeks, to push yourself out of your comfort zone. For example, “I will commit to reaching out to one new person per week”.',
        },
      ],
    },
  ],
};

module.exports = page4Data;

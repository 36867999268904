const page2Data = {
  id: '803a40d9-ed9c-40a3-b72e-f653cd67dd35',
  number: '2',
  title: 'Uncover: what is brand?',
  content: [
    {
      class: 'content',
      text: 'As we learned in phase one, first impressions count. You have already practiced crafting a simple, yet strong introduction, but to make that great impression stick, you need to develop a strong image and personal brand.',
    },
    {
      class: 'content',
      text: 'Everything has a brand – from organisations, services, products and people. Only in more recent times have we become aware of the importance of our personal brand at work.',
    },
  ],
  afterResponse: [''],
  action: [
    'Look at others for inspiration – for example, think about someone you follow on social media – this could be work or personal – consider what brand they are consciously crafting and how they are demonstrating this. ',
  ],
  resourceType: [],

  responseType: [
    {
      id: '2',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: 'd186a072-cb26-46e3-9d55-6629af9d46db',
      questionText: '',
      questions: [
        {
          questions: {
            1: 'So, what does a strong personal brand do? Select all options that apply from the list below.',
          },
          responses: {
            1: [
              '…is a way to deliver value to others and leave a positive impression.',
              '…provides insight into who you authentically are.',
              '…helps you stand out from the crowd.',
              '…can change as you gain new experiences.',
              '…exists even if it has not been consciously crafted.',
            ],
          },
          incorrectAnswers: [],
          feedback: {
            correctFeedback: '',
            incorrectFeedback: '',
            defaultFeedback:
              'Actually all of these options are correct. Consciously crafting a strong personal brand will support our success in all areas at work.',
          },
        },
      ],
    },
  ],
};

module.exports = page2Data;

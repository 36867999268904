const item1Data = require('./items/item1');
const module2Data = {
  id: 2,
  number: '2',
  name: 'Discover your environment',
  description: 'Discover the organisational culture',
  items: [item1Data],
};

module.exports = module2Data;

const page5Data = {
  id: 'fdf3311b-3fd3-4a24-976a-03257e86cabe',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: '“Life begins at the end of your comfort zone” – Neale Donald Walsch',
    },
    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: '4ae66ae8-3f63-41c2-bf9f-a62c98950355',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: '2342',
          name: 'Read an article on the power of small wins. ',
          heading: 'Read',
          link: 'https://hbr.org/2011/05/the-power-of-small-wins',
          linkText: 'Read more',
          class: 'article-resource',
        },
        {
          id: 'd44bdb56-f13b-42c1-8502-42c3badecf9a',
          name: 'Watch a video on trying something new for 30 days.',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/matt_cutts_try_something_new_for_30_days',
          linkText: 'Watch now',
          class: 'video-resource',
        },
      ],
    },
  ],
  responseType: [
    {
      id: 'd13eeedc-e7f5-4dad-b4dc-4059f187b877',
      name: 'freeText',
      class: 'missions',
      guid: 'cd89e759-e87e-4bd9-9498-f32cad69068a',
      questionHeader: 'Mission 1',
      questionText: 'Share your wins',
      questions: [
        {
          1: 'Share your three ‘firsts’ with your manager, team, family or peers, and ask if they have any other stories or examples to share with you. Report back what you hear.',
        },
      ],
    },
    {
      id: '4e2e65b8-afd8-4490-8dd6-e40ffa95eba5',
      name: 'freeText',
      class: 'missions',
      guid: 'a1e6a799-4d7d-42b3-bdf1-dc35a4530259',
      questionHeader: 'Mission 2',
      questionText: 'Try something new',
      questions: [
        {
          1: 'Choose one new thing you would like to try or skill you would like to build in the next 30-days – take one small action each week to work towards achieving this. Report on your progress.',
        },
      ],
    },
    {
      id: '033db9d1-a7ad-49d0-a258-d58361c5a7b0',
      name: 'freeText',
      class: 'missions',
      guid: 'dacc78ac-d531-42af-9781-6f8d20a56f5a',
      questionHeader: 'Mission 3',
      questionText: 'Do something uncomfortable',
      questions: [
        {
          1: 'What makes you uncomfortable? Depending on your strengths and preferences, this could be anything from networking, to data management. Identify something that makes you feel uncomfortable and make a commitment to try it in the next week. Report back on what you tried, how it made you feel and the outcome.',
        },
      ],
    },
  ],
};

module.exports = page5Data;

const resourceClass = 'video-resource';
const page5Data = {
  id: 'eb60db22-d8a3-4d7e-9df2-39586c05d8f5',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content info',
      text: 'There are three to choose from, all around gathering new experiences. Complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: '6921bf14-ad90-4ced-87f0-dda600fce50c',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: '117b5d69-b207-4ff4-85a9-4aeec17ca286',
          name: 'Read an article on the power of growth mindset.',
          heading: 'Read',
          link: 'https://www.forbes.com/sites/carolinecastrillon/2019/07/09/why-a-growth-mindset-is-essential-for-career-success/?sh=d741e5628b5e',
          linkText: 'Read more',
          class: 'article-resource',
        },
        {
          id: '329714ed-e571-40b7-823b-58bf04ea01bd',
          name: 'Listen to a podcast on tricks to build confidence.',
          heading: 'Listen',
          link: 'https://podcasts.apple.com/gb/podcast/moment-80-bear-grylls-the-trick-i-use-to-find-confidence/id1291423644?i=1000583381702',
          linkText: 'Listen now',
          class: resourceClass,
        },
        {
          id: '11b095aa-1d36-42b4-b64b-87f93c0c9df8',
          name: 'Watch a video on 100 days of rejection.',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/jia_jiang_what_i_learned_from_100_days_of_rejection?language=en',
          linkText: 'Watch now',
          class: resourceClass,
        },
        {
          id: '08937099-149f-40c5-95b8-be80bae39cdf',
          name: 'Watch a video on the power of bounce back.',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/monique_dubose_the_power_of_the_bounce_back',
          linkText: 'Watch now',
          class: resourceClass,
        },
      ],
    },
  ],
  responseType: [
    {
      id: '3f9c7e4c-31c8-4258-9cfb-2ee57d25deaf',
      name: 'freeText',
      class: 'missions',
      guid: '34df8ce5-579f-4ca6-808b-096caa7aa76b',
      questionHeader: 'Mission 1',
      questionText: 'People',
      questions: [
        {
          1: 'Reach out to someone you don’t know well to connect and find out about something new. Report back on the outcome.',
        },
      ],
    },
    {
      id: 'd3044701-e7a0-4ffd-879d-62959788adf9',
      name: 'freeText',
      class: 'missions',
      guid: 'cda3fafd-c207-4482-ad9e-dda4769ff7cf',
      questionHeader: 'Mission 2',
      questionText: 'Promotion',
      questions: [
        {
          1: 'Ask to present something or lead a section of your next meeting to gain more exposure. Report back on the outcome.',
        },
      ],
    },
    {
      id: '13221d1e-8e8f-461c-89da-9ef700eebfa3',
      name: 'freeText',
      class: 'missions',
      guid: '67a987a1-cc89-4f1d-882f-ee93050727f5',
      questionHeader: 'Mission 3',
      questionText: 'Possibilities',
      questions: [
        {
          1: 'Try one new experience that gets your out of your comfort zone this week. Report back on what it was and the outcome.',
        },
      ],
    },
  ],
};

module.exports = page5Data;

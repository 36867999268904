const circle = './images/circle.svg';
const page3Data = {
  id: 'a70eb46f-c5b2-4be6-8a03-117cdc877ef5',
  number: '3',
  title: 'Understand: types of wellbeing',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'There are different types of wellbeing that all contribute to our overall health – explore the cards below to find out more.',
    },
  ],
  afterResponse: [''],
  action: [
    'Make a list of what you do in each of these categories to support your wellbeing right now and reflect on what you could do more of.',
  ],
  responseType: [
    {
      id: 'ddb73fdd-5720-40d3-8d5f-ec96d14728e5',
      name: 'flipCard',
      class: 'flipcard-container',
      guid: '8325863a-e93e-4006-b756-8727c5ce9046',
      questionText: '',
      cardText: {
        frontText: 'Physical: our physical health',
        backText: '',
        cardList: [
          'Lifestyle behaviour choices to ensure health e.g., nutrition, exercise.',
          'Avoid preventable diseases and conditions',
          'Live in a balanced state of body, mind and spirit',
        ],
        circle: circle,
      },
    },
    {
      id: '238fb9a0-7160-4b6d-9fb1-34081ff2e6ee',
      name: 'flipCard',
      class: 'flipcard-container yellow',
      guid: '77d12742-dd60-4ae2-8fef-76a625d3fc96',
      questionText: '',
      cardText: {
        frontText: 'Mental: our mindset',
        backText: '',
        cardList: [
          'Where every individual realises their own potential, nutrition, exercise',
          'Can balance the normal stresses of life',
          'Works productively and fruitfully',
        ],
        circle: circle,
      },
    },
    {
      id: '375ac73c-000d-4444-93ce-0f6066b02f9f',
      name: 'flipCard',
      class: 'flipcard-container yellow',
      guid: '76e378ec-8908-4379-b01a-8639b1911ad4',
      questionText: '',
      cardText: {
        frontText: 'Social: our connection with others',
        backText: '',
        cardList: [
          'Feel a sense of belonging and social inclusion',
          'Supported in society e.g., through ways of living and working together, value systems, traditions and beliefs',
        ],
        circle: circle,
      },
    },
    {
      id: '3d5a68eb-bb40-48bf-8c0a-310046de5939',
      name: 'flipCard',
      class: 'flipcard-container',
      guid: '29a2c3a1-f5d3-463a-9f2b-1661f2e687c9',
      questionText: '',
      cardText: {
        frontText: 'Spiritual: our meaning and purpose',
        backText: '',
        cardList: [
          'Experience and integrate meaning and purpose in life',
          'Connectedness with self, others, art, music, literature, nature etc.',
        ],
        circle: circle,
      },
    },

    {
      id: '32237577885',
      name: 'listSort',
      guid: 'a425fd1d-feb1-49a4-b0d6-c3fa610b8995',
      questionText:
        'Rank the types of wellbeing in the order you find easiest to integrate into your daily life (top = easiest, bottom = most difficult)',
      listItems: {
        list: [
          {
            id: '1',
            name: 'A',
            text: 'SOCIAL',
          },
          {
            id: '2',
            name: 'B',
            text: 'MENTAL',
          },
          {
            id: '3',
            name: 'C',
            text: 'PHYSICAL',
          },
          {
            id: '4',
            name: 'D',
            text: 'SPIRITUAL',
          },
        ],
        feedback:
          'Regardless of our ranking, there are small actions we can take to help balance our wellbeing. For example - mini pauses during the day, start your day with self-development, take the stairs instead of the lift, schedule time for social activities, coffee catch ups, breaks throughout the day and to stay at your best.',
      },
    },
  ],
};

module.exports = page3Data;

const page5Data = {
  id: '5195262e-c615-4480-bdae-63dec6dd434a',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content quote',
      text: '“Feedback from one person is a perspective, feedback from two people is a pattern; but feedback from three or more people is likely to be as close to a fact as you can get.” – Tasha Eurich, Insights 2016.',
    },

    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: 'cb082a01-117b-4fe8-901e-6cd136eb023b',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: '80d63ba4-b75c-4a1b-aab6-1e97cd63b6c0',
          name: 'Read an article on ways to respond to negative feedback.',
          heading: 'Read',
          link: 'https://hbr.org/2018/05/the-right-way-to-respond-to-negative-feedback',
          linkText: 'Read more',
          class: 'article-resource',
        },
        {
          id: '84a1893a-2a8e-4b79-bcf7-5573b9df2861',
          name: 'Read an article on exploring feedback.',
          heading: 'Read',
          link: 'https://medium.com/chapters-interludes/how-to-supercharge-your-career-growth-with-the-right-feedback-89a0e8587175',
          linkText: 'Read more',
          class: 'video-resource',
        },
        {
          id: '94b0f43e-7e25-44e0-8674-43dcd133c8f5',
          name: 'Watch a video on self-awareness.',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/tasha_eurich_increase_your_self_awareness_with_one_simple_fix',
          linkText: 'Watch now',
          class: 'article-resource',
        },
      ],
    },
  ],
  responseType: [
    {
      id: 'ecaa19fe-b1db-4e4b-8e77-44f167b2db6d',
      name: 'freeText',
      class: 'missions',
      guid: 'f38559d1-8cc5-4f95-a37e-4053a7c582b1',
      questionHeader: 'Mission 1',
      questionText: 'Get some feedback',
      questions: [
        {
          1: 'Ask for feedback on your performance in a recent project - send a message to three people you worked with and ask them for feedback. Report back on what they say.',
        },
      ],
    },
    {
      id: '7614e713-b3ed-4b90-9300-c1187c77b071',
      name: 'freeText',
      class: 'missions',
      guid: '06bb1524-f299-4800-8fb7-2de1b5dc97fd',
      questionHeader: 'Mission 2',
      questionText: 'Look for a negative',
      questions: [
        {
          1: 'Think of one behaviour that you know you need to develop and work on. Ask those you work with for feedback on how you could improve. Be prepared for constructive or ‘negative’ feedback. Report back on who and what you asked.',
        },
      ],
    },
    {
      id: '94cc4665-7d97-4f67-9ef7-664bfe17a8c0',
      name: 'freeText',
      class: 'missions',
      guid: '9c6e082c-7af7-46e6-97ca-539a2d75d519',
      questionHeader: 'Mission 3',
      questionText: 'Shift your response',
      questions: [
        {
          1: 'Next time you receive some feedback, notice how you respond to it – do you get defensive or are you open and curious? Report back on the feedback you received and your response.',
        },
      ],
    },
  ],
};

module.exports = page5Data;

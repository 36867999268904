const page5Data = {
  id: 'e6c8e21b-5471-481c-ba30-11cacaa3a494',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: '5fae32b4-51c4-4b16-818e-c7f7fddfed01',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: '6961941a-894e-46c4-9f76-ce421400f682',
          name: 'Read an article on building a powerful brand',
          heading: 'Read',
          link: 'https://www.forbes.com/sites/shelcyvjoseph/2018/04/30/5-ways-to-build-a-powerful-personal-brand/#94a0bc3549ea',
          linkText: 'Read more',
          class: 'article-resource',
        },
        {
          id: '3fb8ab06-1da9-42ea-b940-8dcc6b860037',
          name: 'Read an article on the key elements of an effective personal brand.',
          heading: 'Read',
          link: 'https://www.entrepreneur.com/starting-a-business/the-7-key-elements-of-an-effective-personal-brand/280268',
          linkText: 'Read more',
          class: 'video-resource',
        },
        {
          id: '2394d037-2b71-4995-9a03-e87b4cb28833',
          name: 'Watch this interview with Simon Sinek on the ‘golden circle’.',
          heading: 'Watch',
          link: 'https://www.personalbrandingblog.com/personal-branding-interview-simon-sinek/',
          linkText: 'Watch now',
          class: 'article-resource',
        },
      ],
    },
  ],
  responseType: [
    {
      id: 'a9dd72f5-e422-4977-9d10-e7a9cbdd6cb0',
      name: 'freeText',
      class: 'missions',
      guid: 'f036e427-de22-44ed-81c5-e0e1554f97d7',
      questionHeader: 'Mission 1',
      questionText: 'Find out what others think of you',
      questions: [
        {
          1: 'Send a message to at least three people – this could be family members, friends, peers or colleagues – asking what words they would use to describe you. Record what you receive and reflect on whether they match the image you want to portray. ',
        },
      ],
    },
    {
      id: '36c179f5-4c7d-4eff-bdb7-a87e9c27add1',
      name: 'freeText',
      class: 'missions',
      guid: '36ecc4cc-255e-4229-bede-71ebcb326dcd',
      questionHeader: 'Mission 2',
      questionText: 'Gain exposure',
      questions: [
        {
          1: 'Find an interesting and relevant article, blog or piece of information and share this online or with your team or peers.',
        },
      ],
    },
    {
      id: 'c495f505-6806-4413-993a-e1bb887caec5',
      name: 'freeText',
      class: 'missions',
      guid: 'a856e5ad-7060-4f12-bb92-69c3bde966b0',
      questionHeader: 'Mission 3',
      questionText: 'Build up your brand behaviours',
      questions: [
        {
          1: 'In your next meeting, practice positive brand behaviours such as asking questions, listening attentively, practicing positive body language and demonstrating strengths. Record what you tried and how it was received. ',
        },
      ],
    },
  ],
};

module.exports = page5Data;


import * as React from "react";
import { motion } from "framer-motion";
import { MenuItem } from "./framerMenuItem";

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Navigation = ({ items }) => {
    console.log(items);
    return (
      <motion.ul variants={variants}>
        {Array.isArray(items) && items.map((item, i) => (
          <MenuItem i={i} key={i} item={item} />
        ))}
      </motion.ul>
    );
  }
  

const itemIds = [0, 1, 2, 3, 4];

import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { motion } from 'framer-motion';

export const ITEM = 'item';
export const BUCKET_ITEM = 'bucketItem';

const DraggableItem = ({ item, origin, onMoveItem }) => {
  const type = origin === 'items' ? ITEM : BUCKET_ITEM;

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { ...item, origin },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: BUCKET_ITEM,
    hover: (draggedItem) => {
      if (draggedItem.id !== item.id && origin !== "items") {
        onMoveItem(draggedItem.id, item.id);
      }
    }
  });

  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0 : 1 }}>
      <motion.div ref={preview} className='bucket-sort-item' transition={{ duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }} style={{ transformOrigin: 'unset' }}>
        {item.name}
      </motion.div>
    </div>
  );
};

export default DraggableItem;

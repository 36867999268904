import { setApiStepComplete } from '../api/setApiStepComplete';

/**
 * Reads the query parameters and completes that step, 
 * then optionally redirects to the next page based on the route mapping in the Brand object
 * @param Brand the client's brand object
 * @param user the logged-in user
 * @param getAccessTokenSilently the function to obtain the Auth0 token
 * @param setUserMetadata the function to set the Auth0 metadata
 * @param redirect whether to redirect 
 * @return - Returns a promise<void>
 */
export const completeStep = async (brand, user, getAccessTokenSilently, setUserMetadata, redirect) => {
  if (redirect === true && !brand.ROUTE_MAPPING) {
    throw new Error('Brand ROUTE_MAPPING does not exist');
  }

  const search = window.location.search;
  const params = new URLSearchParams(search);

  params.forEach((value, paramName) => {
    if (brand.ROUTE_MAPPING && Object.hasOwn(brand.ROUTE_MAPPING, paramName) && value === "true") {
      setApiStepComplete(paramName, brand, user, getAccessTokenSilently, setUserMetadata).then(() => {
        if (redirect) {
          window.location.pathname = brand.ROUTE_MAPPING[paramName];
        }
      });
    }
  });
};

export default completeStep;

const circle = './images/circle.svg';
const page3Data = {
  id: '6d549998-98ed-40fc-a88e-60d678645f11',
  number: '3',
  title: 'Understand: what stretch opportunities look like',
  resourceType: [
    {
      id: '4d1ec92d-1c7d-4d4b-954b-40eb687304f4',
      name: 'image',
      imageUrl:
        'https://apifiles.cappassessments.com/90DTK/test-overview-image.png',
    },
  ],
  content: [
    {
      class: 'content',
      text: 'There are many different ways to push your comfort zone and and over the next two modules we will focus on three areas in particular:',
    },
  ],
  time: '',
  afterResponse: [''],
  responseType: [
    {
      id: '45a16981-f96b-48bf-932e-a35a470098b3',
      name: 'flipCard',
      class: 'flipcard-container',
      guid: '9578455a-03f5-4c22-b7f4-8299a8fafeca',
      questionText: '',
      cardText: {
        frontText: 'PEOPLE',
        backText:
          'This is about how you engage with others e.g., networking, understanding how you are seen by others, meeting new people.',
        circle: circle,
      },
    },
    {
      id: '3d89fa21-b302-461a-ac0c-3fce8e0c5679',
      name: 'flipCard',
      class: 'flipcard-container yellow',
      guid: 'abd0dfe6-0f4e-4d81-9c47-0f0175e30867',
      questionText: '',
      cardText: {
        frontText: 'PROMOTION',
        backText:
          'This is about how you show up at work e.g., demonstrating knowledge and skills, building your brand, gaining exposure.',
        circle: circle,
      },
    },
    {
      id: '8069e3e4-17f0-4af0-9712-defad40a6c44',
      name: 'flipCard',
      class: 'flipcard-container',
      guid: '6085335b-a1fc-439a-a40f-e21135a651d8',
      questionText: '',
      cardText: {
        frontText: 'POSSIBILITIES',
        backText:
          'This is about how you gain experience e.g., finding opportunities, learning from mistakes or ‘failure’, embracing uncertainty.',
        circle: circle,
      },
    },
    {
      type: 'content',
      text: 'We’ll dive into people and promotion in this module, and explore possibilities in more detail in the next.  ',
    },
    { type: 'content', class: 'content bold', text: 'PEOPLE' },

    {
      type: 'content',
      class: 'content',
      text: 'We can sometimes get stuck in our professional networks, surrounding ourselves with other people similar to ourselves. This can limit the experiences and opportunities available to us. By pushing our comfort zone with PEOPLE, we could open ourselves up to different perspectives, ways of thinking, knowledge and skills – this will look different for each of us.',
    },

    {
      type: 'content',
      class: 'content',
      text: 'Sort the different experiences into whether they are in your comfort, learning or danger zone.',
    },
    {
      id: '5',
      name: 'bucketSort',
      guid: '45fc79da-3909-43a6-8c13-0f8715081870',
      questionText:
        'Only 3 items allowed per bucket category, doesn’t matter which ones.',
      categories: ['Comfort zone', 'Learning zone', 'Danger zone'],
      feedback: {
        correctFeedback: '',
        incorrectFeedback: '',
        defaultFeedback:
          'Great – how we push our comfort zone will look different for each of us depending on our strengths and preferences.',
      },
      listItems: [
        {
          id: '1',
          name: 'A',
          text: 'Attending networking events',
        },
        {
          id: '2',
          name: 'B',
          text: 'Joining a virtual event',
        },
        {
          id: '3',
          name: 'C',
          text: 'Managing tricky stakeholders',
        },
        {
          id: '4',
          name: 'D',
          text: 'Running a client meeting',
        },
        {
          id: '5',
          name: 'E',
          text: 'Communicating with a client (email or phone)',
        },
        {
          id: '6',
          name: 'F',
          text: 'Contacting people you don’t know',
        },
      ],
    },

    { type: 'content', class: 'content bold', text: 'PROMOTION' },

    {
      type: 'content',
      class: 'content',
      text: 'Self-promotion can feel very uncomfortable for a lot of people, but when it’s done right and in an authentic way, it can support our future success. In phase two, we looked at what influences our brand at work – whilst performance and image are important, research shows that exposure has the most impact.',
    },

    {
      id: '2',
      name: 'multipleChoice',
      class: 'multiple-choice-container',
      guid: '3fe7a02b-c9b1-4817-ad69-3f410574ded2',
      questionText: '',
      questions: [
        {
          questions: {
            1: 'What one thing could you do in the next month to promote yourself at work?',
          },
          responses: {
            1: [
              'Present in a team meeting',
              'Volunteer for a project',
              'Share team successes',
              'Find a mentor to support you',
              'Ask for feedback',
            ],
          },
          incorrectAnswers: [],
          feedback: {
            correctFeedback: '',
            incorrectFeedback: '',
            defaultFeedback:
              'Setting goals helps you stay accountable. Here are some tips for pushing your comfort zone around PROMOTION – 1. Make sure your actions feel authentic, 2. Focus on the strengths you want to be known for and 3/ start with small steps and experiences to build your confidence. ',
          },
        },
      ],
    },
    {type: 'content',
  text: 'We’ve explored PEOPLE and PROMOTION in more detail, click onto the next module to unpack POSSIBLITIES.'}
  ],
};

module.exports = page3Data;

import React from "react"
// import {isAuthenticated} from "../pages/dashboard"
import { Nav, Navbar, } from "react-bootstrap"
import { Col, Container, Row, } from "react-bootstrap"

export function SidebarHeader(props) {

  return (
    <>
    <Container className={props.class}>
        <Row>
            <Col xs={12}>
                <p>{props.subheading}</p>
                <h1>{props.heading}</h1> 
            </Col>
        </Row>
    </Container>
    </>
  )
}


export default SidebarHeader

const page4Data = {
  id: 'a5537684-669c-4bca-a988-ebceba33e806',
  number: '4',
  title: 'Reflect: filling the gaps',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: "Reflect on what you know and what you don't know about the organisation's culture, using the questions from the previous exercise and listed below for ease:",
    },
    {
      list: [
        'Are there any commonly held beliefs or behavioural norms you should model? ',
        'How innovative or traditional is the business? Who are the main drivers of these ways of working? ',
        'Who are good role models and who should you be connecting with? ',
        'What is important to your team - are there any shared beliefs or expectations to be aware of?',
      ],
    },
  ],
  time: '',
  afterResponse: [''],
  action: [
    'You can find out more by asking questions, being proactive by doing your own research, and building a strong support network (which we explore in the next module).',
  ],
  responseType: [
    {
      id: 'e7f190a6-75db-4080-8221-5371960b4d58',
      name: 'freeText',
      class: 'missions',
      guid: '1d8ec7b6-e35a-4e06-8c07-32731fbd566a',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: 'Come up with three ways you could find out more about this and fill in gaps in your knowledge, and add these to the box below.',
        },
      ],
    },
  ],
};

module.exports = page4Data;

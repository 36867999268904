const page2Data = {
  id: '4990965e-390b-46ae-83be-ccaa92a5e281',
  number: '2',
  title: 'Uncover: where you are now',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: 'Starting at a new company can be daunting, as well as exciting. Knowing where you are in a transition helps to map out your needs and communicate them to others. Think about where you are right now by completing the exercises below.',
    },
    {
      class: 'content question',
      text: 'Drag and drop the different options into the appropriate categories – whether it is ‘new’ for you, i.e. a new experience, the ‘same’, i.e. something you have experienced before or if you aren’t sure, i.e. something you will need to find out more about.',
    },
  ],
  afterResponse: [''],
  action: [
    'We will all come in with a different set of experiences, so naturally, will have gaps. Ask questions to understand what’s expected and how your team/manager operates.',
  ],
  responseType: [
    {
      id: 'f9e2a17f-0b3d-4500-a825-c3c7b01677ea',
      name: 'bucketSort',
      guid: '40e183ff-8af3-4ce3-bc3f-878d73717255',
      questionText: '',
      categories: ['This is new', 'This is the same', 'I don’t know yet'],
      feedback: {
        correctFeedback: '',
        incorrectFeedback: '',
        defaultFeedback: 'Move on to the next question',
      },
      listItems: [
        {
          id: '1',
          name: 'A',
          text: 'How to set up meetings',
        },
        {
          id: '2',
          name: 'B',
          text: 'Using an ‘out of office’',
        },
        {
          id: '3',
          name: 'C',
          text: 'Office layout, e.g. assigned seating or hot desking’',
        },
        {
          id: '4',
          name: 'D',
          text: 'Adherence to hierarchy',
        },
        {
          id: '5',
          name: 'E',
          text: 'Hybrid or home working',
        },
        {
          id: '6',
          name: 'F',
          text: 'Use of CC/BCC in email',
        },
        {
          id: '7',
          name: 'G',
          text: 'Office dress code',
        },
        {
          id: '8',
          name: 'H',
          text: 'Monitoring hours',
        },
        {
          id: '9',
          name: 'I',
          text: 'Lunch and break processes',
        },
        {
          id: '10',
          name: 'J',
          text: 'Sharing opinions',
        },
        {
          id: '11',
          name: 'K',
          text: 'Openness to new ideas',
        },
        {
          id: '12',
          name: 'L',
          text: 'Systems and channels used',
        },
      ],
    },
    {
      id: '537114e6-d201-4356-9838-e236a6f1e301',
      name: 'freeText',
      class: 'missions',
      guid: '5dab2d03-77e3-4c8e-bcef-233fe2fc24cd',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: "This is not an exhaustive list - have a think about what else could be 'new' for you in the workplace. What impact could this have on your development over the next 90 days? Write down your thoughts in the box below and share with your manager.",
        },
      ],
    },
  ],
};

module.exports = page2Data;

const page5Data = {
  id: '31b8ea6d-8e14-4ba5-b116-0668ffbe055f',
  number: '5',
  title: 'Broaden: go further',
  resourceType: [],
  content: [
    {
      class: 'content info',
      text: 'There are three to choose from, complete as many as you like and report back on this page in the boxes provided.',
    },
  ],
  time: '',
  afterResponse: [''],
  moreResources: [
    {
      id: '2349823983',
      name: 'EXPLORE FURTHER',
      items: [
        {
          id: '2342',
          name: 'Read an article about becoming more comfortable with change.',
          heading: 'Read',
          link: 'https://hbr.org/2021/11/how-to-become-more-comfortable-with-change',
          linkText: 'Read more',
          class: 'article-resource',
        },
        {
          id: '23412122',
          name: 'Watch a video on managing yourself in a crisis.',
          heading: 'Watch',
          link: 'https://www.ted.com/talks/amy_c_edmondson_how_to_lead_in_a_crisis?language=en',
          linkText: 'Watch now',
          class: 'video-resource',
        },
      ],
    },
  ],
  responseType: [
    {
      id: '234235',
      name: 'freeText',
      class: 'missions',
      guid: '309a7e7f-3a9c-4ab0-b413-04b820ce15b9',
      questionHeader: 'Mission 1',
      questionText: 'Identify where you are',
      questions: [
        {
          1: 'Write down all the change journeys you are currently on and one way you can support yourself during the transition(s).',
        },
      ],
    },
    {
      id: '234asda235',
      name: 'freeText',
      class: 'missions',
      guid: '64f3623b-3b8f-48c7-b913-2266876a40ee',
      questionHeader: 'Mission 2',
      questionText: 'Take an action to grow',
      questions: [
        {
          1: 'Think about the actions you could take to help yourself through the change transition – take one of these actions.',
        },
      ],
    },
    {
      id: '234asda2355756',
      name: 'freeText',
      class: 'missions',
      guid: '51cc7de6-3f4c-4ee2-9207-8afda57ce578',
      questionHeader: 'Mission 3',
      questionText: 'Gather experiences and advice',
      questions: [
        {
          1: 'Ask at least three people about their experiences of starting a new job – this could be your family, friends, new manager or teams – report below any advice or guidance you receive.',
        },
      ],
    },
  ],
};

module.exports = page5Data;

const item1Data = require('./items/item1');
const module5Data = {
    "id": 5,
    "number": "5",
    "name": "Reflect on phase 2 ‘Develop’",
    "description": "Take some time to reflect on what you have learned during this phase.",
    "time": "",
    items: [item1Data]
};

module.exports = module5Data;

import React from 'react';
import { useDrop } from 'react-dnd';
import DraggableItem, { BUCKET_ITEM } from './draggableItem';

const ItemsArea = ({ items, moveItemBack }) => {
  const [{ isOver }, drop] = useDrop({
    accept: BUCKET_ITEM,
    drop: (draggedItem) => {
        moveItemBack(draggedItem.id, draggedItem.origin);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div ref={drop} className='bucket-sort-item-area' style={{ background: isOver ? "lightblue" : "#fff" }}>
      {items.map((item) => (
        <DraggableItem key={item.id} item={item} origin="items" />
      ))}
    </div>
  );
};

export default ItemsArea;


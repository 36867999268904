const page4Data = {
  id: 'f51f525f-8ae1-4161-93cb-9bf7db6bad88',
  number: '4',
  title: 'Reflect: your top strengths',
  resourceType: [],
  content: [
    {
      class: 'content',
      text: "There isn't one universal way to complete a task at work. Instead, each of us will leverage a unique combination of our strengths to be able to complete it.",
    },
    { class: 'content', text: 'Take the example of packing for a trip.' },
    {
      class: 'content',
      text: 'Those with Planner strength will most likely start well in advance, writing lists or organising what they need in piles. However, those with Planner as a weakness may approach this task by quickly packing the night before.',
    },
    {
      class: 'content',
      text: "Learning to leverage our authentic strengths in a situation will make it easier to complete any task with confidence - even if it's new or unfamiliar.",
    },
  ],
  time: '',
  afterResponse: [''],
  action: ['Ask your team what they think your top strengths are.'],
  responseType: [
    {
      id: 'cd893ac5-e4ee-4578-a3d1-2273e7f4fd00',
      name: 'freeText',
      class: 'missions',
      guid: 'd8ca0448-616d-4b5c-9aa6-547e1efabfe2',
      questionHeader: '',
      questionText: '',
      questions: [
        {
          1: 'Reflect on what you think your three top strengths are and how these strengths support you during the first 90 days in your new role.',
        },
      ],
    },
  ],
};

module.exports = page4Data;
